import { makeAutoObservable } from "mobx";
import { ISettingsSelected } from "../components/IntegrationTable/models/ISettingsSelected";
import { ISettingsSelectedGroup } from "../components/IntegrationTable/models/ISettingsSelectedGroup";


export interface IDragParams {
    code:string;
    ordering:number;
}

export interface setCheckedParams {
    code:string;
    group:string;
}

class ContrActorTableStore {
    page:number = 1

    tableItems:any[] = []

    allFilter:string[] = []

    isLoading:boolean = true

    isLoadingSort:boolean = true

    totalCount:number = 0

    initSettingsConfirm:ISettingsSelected[] = []
    initSettingsSelect:ISettingsSelectedGroup[] = []

    selectLists:ISettingsSelectedGroup[] = []
    confirmLists:ISettingsSelected[] = []

    curDragItemParams:IDragParams | null = null

    constructor(){
        makeAutoObservable(this)
    }

    addItem(item:any){
        this.tableItems = [item, ...this.tableItems]
    }

    setLoading(value:boolean) {
        this.isLoading = value
    }

    setTotalCount(count:number) {
        this.totalCount = count
    }

    setPage(page:number) {
        this.page = page
    }

    setAllFilter(array:string[]){
        this.allFilter = array
    }

    setTableItems(items:any[]){
        this.tableItems = items
    }

    setInitSettingsSelect(array:ISettingsSelectedGroup[]){
        this.initSettingsSelect = array
    }

    setInitSettingsConfirm(array:ISettingsSelected[]){
        this.initSettingsConfirm = array
    }

    setSelectedList(array:ISettingsSelectedGroup[]){
        this.selectLists = array
    }

    setConfirmList(array:ISettingsSelected[]) {
        this.confirmLists = array
    }


    setSelectedChecked(params:setCheckedParams){
        const {code, group} = params

        this.selectLists.map(groupArr=>groupArr.group === group ?
            groupArr.fields.map(item=>item.code === code ? item.isSelected = !item.isSelected : item)
            : groupArr)
    }

    addToConfirmList(item:ISettingsSelected){
        const newArr:any[] = [...this.confirmLists, {...item, ordering:this.confirmLists.length + 1}]

        this.confirmLists = newArr
    }

    removeConfirmList(id:string){
        const newArr:any[] = [...this.confirmLists.filter(item=>item.code !== id).map((item, i)=>{
            return {...item, ordering:i+1}
        })]

        this.confirmLists = newArr
    }

    onConfirmDelete(id:string){
        this.selectLists.map(groupArr=>
            groupArr.fields.map(item=>item.code === id ? item.isSelected = !item.isSelected : item)
        )
    }

    // первый айди - айди строки
    updateSimpleField = (id:number, update_id:number, field:string, value:any) => {
        this.tableItems = this.tableItems.map(item=>{
            if(item.id === id){
                const newItem = {
                    ...item,
                    update_id,
                    [field]:value
                }
                return newItem
            } return item
        })
    }

    //перетаскивание фильтров
    onDragStart = (params:IDragParams) => {
        this.curDragItemParams = params
    }

    onDragOver = (e:React.DragEvent) => {
        e.preventDefault()
    }


    onDrop = (e:React.DragEvent, params:IDragParams) => {
        e.preventDefault()

        if(params.code && params.ordering && this.curDragItemParams?.code && this.curDragItemParams?.ordering && params.code !== this.curDragItemParams.code){

            const dragItem = {code:this.curDragItemParams.code, ordering:params.ordering}
            const downItem = {params,code:params.code, ordering:this.curDragItemParams.ordering < params.ordering ? params.ordering-1 : params.ordering+1}

            const afterDragArr:any[] = this.confirmLists.map(item=>{
                if(item.code === dragItem.code) {
                    return {...item, ...dragItem}
                } else if (item.code === downItem.code) {
                    return {...item, ...downItem}
                } else  {
                    return {
                        ...item,
                        ordering:item.ordering >= dragItem.ordering ? item.ordering + 1 : item.ordering
                    }
                }
            })

            this.confirmLists = afterDragArr.sort((a, b)=>a.ordering > b.ordering ? 1 : -1).map((x,i)=>{
                return {...x, ordering:i+1}
            })
        }
    }

    sortTableItem(type:string, sortType:'asc' | 'desc'){
        this.isLoadingSort = true

        setTimeout(()=>{

            sortType === 'desc'
                ? this.tableItems = [].concat(this.tableItems as []).sort((a, b)=>{
                    if (a[type] === null)
                        return 1;


                    if (b[type] === null)
                        return -1;


                    if (a[type] === b[type] ) {
                        if(a['type'] === null ||  a['type']['name'] === null)
                            return 1

                        if(b['type'] === null || b['type']['name'] === null)
                            return -1

                        if(a['type']['name'] === b['type']['name'])
                            return 0
                        else
                            return a['type']['name'] < b['type']['name'] ? 1 : -1;
                    }
                    else return a[type] < b[type] ? 1 : -1;
                })
                :
                this.tableItems = [].concat(this.tableItems as []).sort((a, b)=>{
                    if (a[type] === null)
                        return 1;


                    if (b[type] === null)
                        return -1;


                    if (a[type] === b[type] ) {
                        if(a['type'] === null || a['type']['name'] === null)
                            return 1

                        if(b['type'] === null || b['type']['name'] === null)
                            return -1

                        if(a['type']['name'] === b['type']['name'])
                            return 0
                        else
                            return a['type']['name'] > b['type']['name'] ? 1 : -1;
                    }
                    else return a[type] > b[type] ? 1 : -1;
                })

            setTimeout(()=>{
                this.isLoadingSort = false
            }, 0)

        }, 0)
    }

    delete = (id:number) => {
        this.tableItems = this.tableItems.filter(item=>item.id !== id)
    }
    editContrActor = (id: number, newContractor: any) =>{
        const index =  this.tableItems.findIndex(item => item.id === id)
        if (index !== -1) {

            this.tableItems[index] = { ...this.tableItems[index], ...newContractor };
        }
    }
}

export default new ContrActorTableStore()