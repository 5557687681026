import {useState} from "react";
import ReferencesService from "../../../../../services/references/references.service";
import {Add} from "@mui/icons-material";
import {EditModalField} from "../../Fields/EditModalField/EditModalField";
import { CustomIconButton } from "../../../../Buttons/CustomIconButton/CustomIconButton";

export const PositioningAddButtonDialog = () => {

  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const addPositioning = async (positioningValue: string) => {
    setLoading(true)
    await ReferencesService.createPositioning({name:positioningValue})
    setLoading(false)
    setOpen(false)
  }

  return (
    <>
      <CustomIconButton onClick={() => setOpen(true)} sx={{ml: 1}} size='small' showBackground>
        <Add fontSize='small'/>
      </CustomIconButton>
      <EditModalField 
        loading={isLoading} 
        textCancel={'Отмена'} 
        textSubmit={'Добавить'} 
        placeholder={'Введите значение'} 
        subInputText={'Позиционирование:'} 
        title={'Добавление позиционирования'} 
        onClose={() => {setOpen(false)}} 
        open={open} 
        onSubmit={addPositioning}
      />
    </>
  )
}