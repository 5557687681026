import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Checkbox, CircularProgress, Drawer, FormControlLabel, IconButton, Stack, TextField, Typography, Avatar } from '@mui/material'
import userStore from '../../store/user.store'
import { ROUTES } from '../../shared/routes/routes'
import { observer } from 'mobx-react-lite'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { IReleaseItem } from '../../components/ReleaseList/models/IReleaseItem'
import ChannelService from '../../services/channel/channel.service'
import { IChannelFullResponse } from '../../services/channel/ICnannelResponse'
import { AxiosError } from 'axios'
import IntegrationsService from '../../services/integration/integrations.service'
import { ICreateIntegrationChannelRequest, IInsertPromokodRequest } from '../../services/integration/IntegrationsRequest'
import { AsideCardLayout } from '../../components/AsideCardLayout/AsideCardLayout'
import { ChannelAsideSource } from './ui/ChannelAsideSource/ChannelAsideSource'
import { IGoodItem } from '../../components/Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem'
import { ChannelAsideGoods } from './ui/ChannelAsideGoods/ChannelAsideGoods'
import { checkRequiredSubmit } from './shared/funtions'
import { CreateDateInput } from '../CreateChannelPage/ui/CreateDateInput/CreateDateInput'
import { checkUserPermission } from '../../shared/rules/permission'
import { ALL_RULES } from '../../shared/rules/interface/EnumRights'
import { INTEGRATION_CARD_OPEN } from '../../shared/constants/searchParams'
import { NotificationAlerts } from '../../components/NotificationAlerts/NotificationAlerts'
import channelStore from "../../store/channel.store";
import { CustomButton } from '../../components/Buttons/CustomButton/CustomButton'

export const ChannelAsidePage = observer(() => {
  const {id} = useParams()
  const navigate = useNavigate()

  const [data, setData] = useState<IChannelFullResponse | null>(null)
 
  const [isOpen, setOpen] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const [isSaveLoading, setSaveLoading] = useState(false)
  const [isSaveError, setSaveError] = useState<null | string>(null)

  const [priceValue, setPriceValue] = useState<string | null>(null)

  const [dateValue, setDateValue] = useState('')

  const [promokodValue, setPromokodValue] = useState('')
  const [sourseValue, setSourseValue] = useState('')

  const [releasesArr, setReleasesArr] = useState<IReleaseItem[]>([])

  const [goods, setGoods] = useState<IGoodItem[]>([])

  const [syncWithSource, setSyncWithSource] = useState(true)

  const channelData = (id:string) => {
    setLoading(true)
    setError(null)

    ChannelService.getChannelFull(id)
      .then(res=>{
        // console.log(res, 'res channel full')
        setData(res.data)

        setPriceValue(res.data.price?.toString())
        setError(null)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err channel full')
          setError(`Ошибка ${err.response?.data.statusCode}. ${err.response?.data.message}`)
          setLoading(false)
        }
      })
  }

  const saveIntegration = () => {
    setSaveLoading(true)
    setSaveError(null)

    if(id && userStore.user?.id){
      setPromokodValue(promokodValue?.replace(/[^a-zA-Z0-9]/g, ''))
      const option:ICreateIntegrationChannelRequest = {
        releaseUser:userStore?.user?.id,
        channelId:+id,
        company:userStore.currentCompany,
        integration_date:dateValue,
        prediction_orders:0,
        prediction_traffic:0,
        prediction_views:0,
        releases:releasesArr.map(item=>{
          const newItem:IReleaseItem = {
            comments:item.comments,
            integrationPlace:item.integrationPlace,
            likes:item.likes,
            link:item.link,
            releaseDate:item.releaseDate,
            type:item.type,
            views:item.views
          }
          return newItem
        }),
        source:syncWithSource ? promokodValue?.toLowerCase() : sourseValue?.toLowerCase(),
        price:priceValue ? +priceValue : 0,
        category:'',
        goods,
        promokod:promokodValue?.toUpperCase()
      }
      
      console.log(option)
      IntegrationsService.createIntegrationChannel(option)
        .then(res=>{
          // console.log(res, 'save integration')
          
          const option:IInsertPromokodRequest = {
            id:res.data.id,
            promokod:promokodValue,
            syncWithSource
          }
          IntegrationsService.insertPromokod(option)
              .then(resProm=>{
                // console.log(resProm, 'res insert promokod')
                navigate('/'+userStore.currentCompany+'/'+ROUTES.INTEGRATIONS+'?'+INTEGRATION_CARD_OPEN+'='+res.data.id)
                setSaveLoading(false)
              })
              .catch(err=>{
                if(err instanceof AxiosError){
                  // console.log(err, 'err insert promokod')
                  navigate('/'+userStore.currentCompany+'/'+ROUTES.INTEGRATIONS+'?'+INTEGRATION_CARD_OPEN+'='+res.data.id)
                  setSaveLoading(false)
                }
              }) 
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            // console.log(err, 'err integratin save')
            setSaveLoading(false)
            setSaveError(err?.response?.data?.message)
          }
        })
      }
  }

  const onChangePromokodWithSourse = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSyncWithSource(event.target.checked)
  }

  const closeModal = () => {
    setOpen(false)
    setTimeout(() => navigate('/' + userStore.currentCompany + '/' + ROUTES.CHANNELS), 200)
  }

  const addRelease = (item:IReleaseItem) => {
    setReleasesArr(prev=>[...prev, item])
  }

  const changePromokodValue = (e:React.ChangeEvent<HTMLInputElement>) => {
    if(!syncWithSource){
      setPromokodValue(e.target.value.replace(/[^a-zA-Z0-9]/g, ''))
    } else {
      setSourseValue(e.target.value.replace(/[^a-zA-Z0-9]/g, ''))
      setPromokodValue(e.target.value.replace(/[^a-zA-Z0-9]/g, ''))
    }
  }

  useEffect(()=>{
    if(syncWithSource){
      setSourseValue(promokodValue)
    }
  }, [syncWithSource])

  useEffect(()=>{
    if(id){
      setOpen(true)
      channelData(id)
    }
  }, [id])

  return (
    <>
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={!isSaveLoading ? closeModal : undefined}
        slotProps={{backdrop:{sx:{left:70}}}}
        ModalProps={{sx:{left:70}}}
        PaperProps={{sx:{backgroundColor: "#F2F3F4"}}}
      >
        {isLoading &&
          <Box
            sx={{
              width:{xl:"65vw", lg:'calc(75vw - 70px - 100px)', md:'calc(75vw - 70px - 50px)', sm:'calc(75vw - 70px)', xs:'100vw'},
              p:2,
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              height:'100%',
              background:"#F2F3F4"
            }}
          >
            <CircularProgress sx={{minHeight:200, minWidth:200}}/>
          </Box>
        }
        {!isLoading && 
          <Box
            sx={{
              p:2,
              width:{xl:"65vw", lg:'calc(75vw - 70px - 100px)', md:'calc(75vw - 70px - 50px)', sm:'calc(75vw - 70px)', xs:'100vw'},
              height:'100%',
              background:"#F2F3F4"
            }}
          >
            {!isError &&
              <Stack direction={'row'} alignItems={'center'} sx={{mb:1,mt:-1}} spacing={1}>
                <IconButton onClick={closeModal} sx={{ml:-1}} disabled={isSaveLoading}>
                  <KeyboardArrowLeft fontSize='large'/>
                </IconButton>
                <Typography variant='h5'>Создание интеграции</Typography>
              </Stack>
            }
            {isError && 
              <Box sx={{p:2}}>
                <Typography variant='h4' color={'error'} align='center'>{isError}</Typography>
                <Typography variant='h5' color={'action'} align='center' sx={{mt:1}}>
                  Страница может больше не существовать или быть перемещена на другой веб адрес.
                </Typography>
                <Button fullWidth variant='outlined' onClick={closeModal} sx={{mt:2}}>Вернуться назад</Button>
              </Box>
            }
            {userStore.user && checkUserPermission({rules:[ALL_RULES.ADMIN, ALL_RULES.create_integration], userRules:userStore.user?.rights}) === false && !isLoading &&
              <Typography variant='h4' textAlign={'center'}>У вас недостаточно прав для просмотра данной страницы</Typography>
            }
            {id && data && userStore.user && checkUserPermission({rules:[ALL_RULES.ADMIN, ALL_RULES.create_integration], userRules:userStore.user?.rights}) &&
						 <Box sx={{
               display: 'grid',
               gridTemplateColumns: '1fr',
               gridTemplateRows: 'auto auto',
               gap: 2,
               mb: 2,
               width: {md: '50%'}
             }}>
                <AsideCardLayout headerTitle='Основное'>
                  <Stack direction={'column'} spacing={1} sx={{mt:1}}>
                   <Stack direction={'row'} >
										<Typography noWrap>Имя канала: {data.name}</Typography>
                     {data?.name ?
                       <Avatar
                         src={channelStore?.channels?.[data?.type?.code]?.icon}
                         alt={data?.type?.code}
                         sx={{height:20, width:20, ml:0.5}}
                       />
                       : null
                     }
                   </Stack>
									 <Stack spacing={1} direction={{sm:'row'}} alignItems={{sm:'center'}}>
										<Typography minWidth={{sm:'80px'}}>Стоимость:<Typography component={'span'} color={'error'}>*</Typography></Typography>
										<TextField
										 value={priceValue === '0' ? '' : priceValue}
										 onChange={e=>setPriceValue(e.target.value)}
										 type='number'
										 placeholder={'Введите цену'}
										 size='small'
										/>
									 </Stack>
                    <Stack spacing={1} direction={{sm:'row'}} alignItems={{sm:'center'}}>
                      <Typography minWidth={{sm:'80px'}}>Промокод:<Typography component={'span'} color={'error'}>*</Typography></Typography>
                      <TextField
                        value={promokodValue?.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()}
                        onChange={changePromokodValue}
                        placeholder={'Введите промокод'} 
                        size='small'
                      />
                    </Stack>
                    <Box>
                      <FormControlLabel
                        sx={{m:0}}
                        control={<Checkbox checked={syncWithSource} onChange={onChangePromokodWithSourse}/>}
                        label="Синхронизировать промокод с источником?" 
                        labelPlacement='start'
                      />
                    </Box>
									 <Stack spacing={1} direction={{sm:'row'}}>
										<ChannelAsideSource value={sourseValue?.toLowerCase()} setValue={setSourseValue} readOnly={syncWithSource}/>
									 </Stack>
                    <CreateDateInput
                      title='Запланированная дата:'
                      date={dateValue}
                      onSubmit={setDateValue}
                      required
                      maxWidth={'250px'}
                    />
                  </Stack>
                </AsideCardLayout>
                <Stack spacing={2} sx={{maxHeight:'fit-content'}}>
                  <ChannelAsideGoods integrationId={+id} goods={goods} setGoods={setGoods} forFirstIntegrationWithChannel={data.isNewCnannel}/>
                </Stack>
               {isError === null && userStore.user && checkUserPermission({rules:[ALL_RULES.ADMIN, ALL_RULES.create_integration], userRules:userStore.user?.rights}) &&
								  <Box sx={{mt:'auto', display:'flex', justifyContent:'center',gap:1,}}>
                    <Button variant='outlined' size='large' onClick={closeModal} disabled={isSaveLoading} sx={{backgroundColor:'#fff'}}>Отмена</Button>
                    <CustomButton
                      variant='contained'
                      size='large'
                      onClick={saveIntegration}
                      disabled={isSaveLoading || (priceValue !== null ? !checkRequiredSubmit(promokodValue, sourseValue, priceValue, dateValue, goods) : true)}
                      endIcon={isSaveLoading && <CircularProgress sx={{maxHeight:20, maxWidth:20}} />}
                    >
                      Создать
                    </CustomButton>
                  </Box>
               }
              </Box>
            }
          </Box>
        }
      </Drawer>
      <NotificationAlerts
        error={{onClose:()=>setSaveError(null), open:isSaveError ? true : false, text:isSaveError ? isSaveError : ''}}
      />
    </>
  )
})