import {FC, useState} from 'react'
import { IContragentIntegration } from './IContragentIntegration'
import { FinancesContrAgent } from '../../../../../../../FinancesCreatePage/ui/FinancesContrAgent/FinancesContrAgent'
import { observer } from 'mobx-react-lite'
import { IFinancePaymentEditRequest } from '../../../../../../../../services/payments/IPaymentsRequest'
import PaymentsService from '../../../../../../../../services/payments/payments.service'
import { AxiosError } from 'axios'
import integrationPageStore from '../../../../../../../../store/integrationPage.store'
import { checkNumberBoolean } from '../../../../../../../../shared/functions/functions'
import { NotificationAlerts } from '../../../../../../../../components/NotificationAlerts/NotificationAlerts'

export const ContragentIntegration:FC<IContragentIntegration> = observer((props) => {
  const {readOnly} = props

  const [isEdit, setEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)
  
  const startEdit = () => {
    integrationPageStore.startEditCounterAgent()
    setEdit(true)
  }

  const closeEdit = () => {
    integrationPageStore.closeEditCounterAgent()
    setEdit(false)
  }

  const submit = () => {
    if(integrationPageStore.oplataId && integrationPageStore?.editCounteragent?.id && checkNumberBoolean(integrationPageStore.paymentUpdateId)){
      setLoading(true)
      setError(null)

      const option:IFinancePaymentEditRequest = {
        update_id:integrationPageStore.paymentUpdateId!,
        id:integrationPageStore.oplataId,
        counteragent:{id:integrationPageStore?.editCounteragent?.id}
      }
      PaymentsService.financePaymentEdit(option)
        .then(res=>{
          
          integrationPageStore.setCounteragent(res.data?.counteragent)
          integrationPageStore.setPaymentUpdateId(res.data?.update_id || null)

          closeEdit()
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            setLoading(false)
            setError(err?.response?.data?.message)
          }
        })
    }
  }

  return (
    <>
      <FinancesContrAgent
        isEditMode={isEdit}
        isShowEditIcon
        counteragent={isEdit ? integrationPageStore.editCounteragent : integrationPageStore.counteragent}
        integrationContacts={integrationPageStore.integrationContacts || []}
        setCounteragent={integrationPageStore.setEditCounteragent}
        disabled={isLoading}
        onCloseEditButton={closeEdit}
        onOpenEditButton={startEdit}
        onSubmitEditButton={submit}
        loadingEditButton={false}
        sxContAgentWidth={{width:{xs:'100%', lg:'80%', xl:'350px'}}}
        readOnly={readOnly}
      />
      <NotificationAlerts
        error={{text:isError ? isError : '', onClose:()=>setError(null), open:Boolean(isError), autoHideDuration:5000}}
      />
    </>
  )
})