import { AxiosProgressEvent, AxiosResponse } from "axios"
import $gateway_api from "../../shared/api/gatewayAxios"
import { IContractInsertOrUpdateRequest, IFinancePaymentEditRequest, IFinancePaymentsInsertRequest, IOrdInfoInsertOrUpdateRequest, IPaymentUpdateStatusRequest, IPaymentsCompanyAccountRequest, IPaymentsEditRequest, IPaymentsFilteredRequest, IPaymentsInsertIntegrationsRequest, IPaymentsInsertRequest, IPaysInsertOrUpdateRequest, IordTextInsertRequest } from "./IPaymentsRequest"
import { IIntegrationFullPayment } from "../integration/IIntegrationsResponse"
import { IPaymentsCompanyAccountResponse, IPaymentsFilteredResponse, IPaymentsFullResponse, IPaymentsMinMaxFilterResponse, IordTextInsertResponse } from "./IPaymentsResponse"
import { IFiltersUpadteRequest } from "../integration/IntegrationsRequest"
import { ISettingsSelectedGroup } from "../../components/IntegrationTable/models/ISettingsSelectedGroup"

export default class PaymentsService {
  // almost all legacy

  static paymentDelete(option:{id:string}):Promise<AxiosResponse>{
    return $gateway_api.post('/api/payments/delete', option)
  }

  static paymentEdit(option:IPaymentsEditRequest):Promise<AxiosResponse>{
    return $gateway_api.post('/api/payments/edit', option)
  }

  static paymentInsert(option:IPaymentsInsertRequest):Promise<AxiosResponse<IIntegrationFullPayment>>{
    return $gateway_api.post('/api/payments/insert', option)
  }

  static paymentFiltered(option:IPaymentsFilteredRequest):Promise<AxiosResponse<IPaymentsFilteredResponse>>{
    return $gateway_api.post('/api/payments/filtered', option)
  }

  static getPaymentMinMaxFilter():Promise<AxiosResponse<IPaymentsMinMaxFilterResponse>>{
    return $gateway_api.get('/api/payments/minMaxFilters')
  }

  static getUpdateFilters(option:IFiltersUpadteRequest):Promise<AxiosResponse<ISettingsSelectedGroup[]>>{
    return $gateway_api.post('/api/common/filter-settings-update', option)
  }

  static updateCompanyAccount(option:IPaymentsCompanyAccountRequest):Promise<AxiosResponse<IPaymentsCompanyAccountResponse>>{
    return $gateway_api.post('/api/payments/updateCompanyAccount', option)
  }

  // actuality

  static financePaymentsInsert(option:IFinancePaymentsInsertRequest):Promise<AxiosResponse<IPaymentsFullResponse>>{
    return $gateway_api.post('/api/payments/insert', option)
  }

  static getPaymentsFull(id:string):Promise<AxiosResponse<IPaymentsFullResponse>>{
    return $gateway_api.get('/api/payments'+'/'+id) 
  }

  static paymentsInsertIntegrations(option:IPaymentsInsertIntegrationsRequest):Promise<AxiosResponse<IPaymentsFullResponse>>{
    return $gateway_api.post('/api/payments/insert-integrations', option) 
  }

  static updateStatus(option:IPaymentUpdateStatusRequest):Promise<AxiosResponse<IPaymentsFullResponse>>{
    return $gateway_api.post('/api/payments/updateStatus', option) 
  }

  static financePaymentEdit(option:IFinancePaymentEditRequest):Promise<AxiosResponse<IPaymentsFullResponse>>{
    return $gateway_api.post('/api/payments/edit', option)
  }

  static contractInserOrUpdate(option:IContractInsertOrUpdateRequest):Promise<AxiosResponse<IPaymentsFullResponse>>{
    return $gateway_api.post('/api/payments/contracts/insert-or-update', option)
  }

  static paysInsertOrUpdate(option:IPaysInsertOrUpdateRequest):Promise<AxiosResponse<IPaymentsFullResponse>>{
    return $gateway_api.post('/api/payments/pays/insert-or-update', option)
  }

  static ordInfoInsertOrUpdate(option:IOrdInfoInsertOrUpdateRequest):Promise<AxiosResponse<IPaymentsFullResponse>>{
    return $gateway_api.post('/api/payments/ordInfo/insert-or-update', option)
  }

  static paymentsFilteredExcel(option:IPaymentsFilteredRequest, onDownloadProgress?:((progressEvent: AxiosProgressEvent)=>void)):Promise<AxiosResponse<Blob>>{
    return $gateway_api.post('/api/payments/filtered/excel', option, {
      responseType:'blob',
      onDownloadProgress
    })
  }

  static ordTextInsert(option:IordTextInsertRequest):Promise<AxiosResponse<IordTextInsertResponse>>{
    return $gateway_api.post('/api/payments/ordText/insert', option)
  }

  static eridTextInsert(option:Omit<IordTextInsertRequest, 'goodsCategory'>):Promise<AxiosResponse<Omit<IordTextInsertResponse, 'goodsCategory'>>>{
    return $gateway_api.post('/api/payments/eridText/insert', option)
  }

}
