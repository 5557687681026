import {FC, useState} from 'react'
import { IIntegrationTargetAudience } from './IIntegrationTargetAudience'
import { Box, ClickAwayListener, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { getAudiencePercentColor } from '../../../../../ChannelCardPage/ui/ChannelAudience/shared/functions'
import { ErrorOutline } from '@mui/icons-material'
import { IntegrationAudienceCard } from './ui/IntegrationAudienceCard/IntegrationAudienceCard'

export const IntegrationTargetAudience:FC<IIntegrationTargetAudience> = (props) => {
  const { targetAudience, audienceData } = props

  const [isShowAudience, setShowAudience] = useState(false)

  const handleTooltipClose = () => {
    setShowAudience(false)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <Typography>ЦА:</Typography>
        <Box
          sx={{backgroundColor:getAudiencePercentColor(+targetAudience), padding: '4px 8px', borderRadius:1}}
        >
          <Typography fontWeight={'bold'}>{targetAudience}%</Typography>
        </Box>
        <Tooltip
          enterTouchDelay={0}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={handleTooltipClose}
          open={isShowAudience}
          placement={'right-end'}
          slotProps={{
            tooltip:{
              sx:{background:'#383e4c', minWidth:{xs:230, sm:400}, p:'8px 16px'}
            }
          }}
          PopperProps={{
            disablePortal: true,
            placement:'auto'
          }}
          title={<IntegrationAudienceCard data={audienceData}/>}
        >
          <IconButton size='small' onClick={()=>setShowAudience(prev=>!prev)}>
            <ErrorOutline fontSize='small'/>
          </IconButton>
        </Tooltip>
      </Stack>
    </ClickAwayListener>
  )
}
