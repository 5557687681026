import { Box } from '@mui/material';
import { LoginForm } from '../../components/loginForm/loginForm';

export function LoginPage(): JSX.Element {

  return (
    <Box
      sx={{
        display:'flex', 
        alignItems:'center',
        justifyContent:'center',
        height:'100vh',
        background:'linear-gradient(90deg, rgba(66,58,180,0.21) 0%, rgba(230,191,191,0.5) 25%, rgba(66,58,180,0.21) 100%)'
      }}
    >
      <LoginForm />
    </Box>
  )
}