import {FC} from 'react'
import { ITzSelect } from './ITzSelect'
import {Box, CircularProgress,MenuItem, Select, Stack} from '@mui/material'
import {RemoveItemButton} from "../../../../RemoveItemButton/RemoveItemButton";

export const TzSelect:FC<ITzSelect> = (props) => {
  const {
    initValue,
    value,
    onChange,
    list,
    onOpen,
    loading,
    minWidth = 185,
    disabled,
    showDelete = true,
    fullWidth,
    width
  } = props

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={0.5} width={width}>
      <Select
        fullWidth={fullWidth}
        defaultValue=''
        size='small'
        value={value} 
        onChange={onChange}
        disabled={loading || disabled}
        onOpen={onOpen}
        sx={{minWidth}}
        displayEmpty
        renderValue={(value)=>{
            return <Box sx={{ display: "flex", justifyContent:'space-between', maxWidth:minWidth}}>
                {list ? list?.find(x=>x?.code === value)?.name : initValue?.name}
                {initValue === null && loading && 'Идёт загрузка...'}
              {loading && <CircularProgress sx={{maxHeight:20, maxWidth:20, minHeight:20, minWidth:20}}/>}
            </Box>
        }}
      >
        {(list && !loading) &&
          list?.map(item=><MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>)
        }
      </Select>
      {value && showDelete &&
        <RemoveItemButton isDisableColor={loading} size='small' disabled={loading} onClick={()=>onChange(null)}/>
      }
    </Stack>
  )
}