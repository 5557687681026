import { FC } from 'react'
import { IFilterCheckbox } from './IFilterCheckbox'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

export const FilterCheckbox:FC<IFilterCheckbox> = (props) => {
  const {
    label,
    searchParamName,
    labelPlacement,
    FormControlSx
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const checked:boolean | null = searchParams.get(searchParamName) === 'true' ? true : false

  const changeCheckboxUrl = (value:boolean) => {

    if (value === false){
      searchParams.delete(searchParamName)
      setSearchParams(searchParams)
    } else {
      searchParams.set(searchParamName, `${value}`)
      setSearchParams(searchParams)
    }

  }

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(checked)}
            onChange={(e)=>changeCheckboxUrl(e.target?.checked)}
          />
        } 
        label={label}
        labelPlacement={labelPlacement}
        sx={FormControlSx}
      />
    </Box>
  )
}
