import {FC, memo} from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { IGoodsTable } from './IGoodsTable'
import { GoodsTableItem } from '../GoodsTableItem/GoodsTableItem'

export const GoodsTable:FC<IGoodsTable> = memo((props) => {
  const {list} = props
  
  return (
    <TableContainer
      component={Paper} 
      elevation={0} 
      sx={{
        border:'1px solid #e0e0e0',
        borderBottom:'none', 
        borderRadius:0,
        borderLeft:'none',
        height:'min-content',
        maxHeight:'calc(100% - 32px)',
      }}
    >
      <Table
        stickyHeader
        sx={{
          "& .MuiTableCell-root": {borderLeft: '1px solid #e0e0e0'},
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{p:1, width:'50%', whiteSpace:'nowrap', background:' #f2f3f5'}}>
              <Typography variant="body1" fontWeight={'bold'}>Категория</Typography>
            </TableCell>
            <TableCell sx={{p:1, width:'50%', whiteSpace:'nowrap', background:' #f2f3f5'}}>
              <Typography variant="body1" fontWeight={'bold'}>Порог ROI (%)</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(item=><GoodsTableItem key={item.goodsCategoryCode} {...item} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
})