import {FC, useEffect, useState} from 'react'
import {IPlanInputItem} from './IPlanInputItem'
import { Stack, Typography} from '@mui/material'
import {InputEditField} from '../../../../../../../../components/Table/ui/Fields/InputEditField/InputEditField'
import ReferencesService from '../../../../../../../../services/references/references.service'
import {IReferencesPlanSetRequest} from '../../../../../../../../services/references/IReferencesRequest'
import dashboardStore from '../../../../../../../../store/dashboard.store'
import {AxiosError} from 'axios'
import {observer} from "mobx-react-lite";
import { EditIconButton } from '../../../../../../../../components/Buttons/EditIconButton/EditIconButton'

export const PlanInputItem:FC<IPlanInputItem> = observer((props) => {
  const {
    value,
    field,
    userId,
    year,
    month,
    planType,
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const [isEdit, setEdit] = useState(false)
  const [oldValue, setOldValue] = useState(0)
  const submit = (value:string) => {
    const option:IReferencesPlanSetRequest = {
      month,
      plan:+value,
      planType,
      year,
      userId
    }

    ReferencesService.planSetToUser(option)
    .then(res=>{
      // console.log(res, 'res set user budget', field)
      dashboardStore.setPlanAll(res.data as any)

      setEdit(false)
      setLoading(false)
    })
    .catch(err=>{
      if(err instanceof AxiosError){
        // console.log(err, 'err set user budget', field)
        setError(err.response?.data?.message)
        setLoading(false)
      }
    })
  }
  useEffect(() => {
    setOldValue(value)
  }, [value]);
  return (
    <>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          <Typography>{(value)}</Typography>
          <EditIconButton size='small' onClick={()=>setEdit(true)}/>
        </Stack>
      }
      {isEdit &&
        <InputEditField
          initValue={`${value ? value : 0}`}
          inputType='number'
          placeholder='Введите план'
          onClose={()=>setEdit(false)}
          onSubmit={submit}
          error={isError}
          loading={isLoading}
          fullWidth
        />
      }
    </>
  )
})
