import { makeAutoObservable } from "mobx";
import { IIntegrationsFullResponse } from "../services/integration/IIntegrationsResponse";
import categoriesGoodsStore from "./categoriesGoods.store";

class RepeatIntegrationStore {

  inegrations: null | IIntegrationsFullResponse[] = null

  isLoadingSort:boolean = false

  constructor(){
    makeAutoObservable(this)
  }

  setIntegrations = (inegrations:null | IIntegrationsFullResponse[]) => {
    this.inegrations = inegrations
  }

  removeIntegrations = (id:number) => {
    if(this.inegrations){
      this.inegrations = this.inegrations.filter(item=>item.id !== id)
    }
  }

  sortRepeatItem(type:string, sortType:'asc' | 'desc'){
    this.isLoadingSort = true
    
    if(this.inegrations !== null){

      setTimeout(()=>{
        
        sortType === 'desc'
          ? this.inegrations = [...this.inegrations as IIntegrationsFullResponse[]].sort((a, b)=>{

            if (a[type] === null) {
              return 1;
            }
          
            if (b[type] === null) {
              return -1;
            }
          
            if (a[type] === b[type]) {
              return 0;
            }
          
            if(type === 'integration_date'){
              return Number(new Date(a[type])) < Number(new Date(b[type])) ? 1 : -1;
            }

            if(type === 'goodsCategoryCode'){
              const categoriesRuA = categoriesGoodsStore.categories?.find(x=>x?.code === a[type])?.name
              const categoriesRuB = categoriesGoodsStore.categories?.find(x=>x?.code === b[type])?.name

              return categoriesRuA! < categoriesRuB! ? 1 : -1;
            } else return 0
              
          })
          : this.inegrations = [...this.inegrations as IIntegrationsFullResponse[]].sort((a, b)=>{

            if (a[type] === null) {
              return 1;
            }
          
            if (b[type] === null) {
              return -1;
            }
          
            if (a[type] === b[type]) {
              return 0;
            }
          
            if(type === 'integration_date'){
              return Number(new Date(a[type])) > Number(new Date(b[type])) ? 1 : -1;
            }

            if(type === 'goodsCategoryCode'){
              const categoriesRuA = categoriesGoodsStore.categories?.find(x=>x?.code === a[type])?.name
              const categoriesRuB = categoriesGoodsStore.categories?.find(x=>x?.code === b[type])?.name

              return categoriesRuA! > categoriesRuB! ? 1 : -1;
            } else return 0

          })

        setTimeout(()=>{
          this.isLoadingSort = false
        },0)

      },0)

    }

  }
}

export default new RepeatIntegrationStore()