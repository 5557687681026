import {FC, useState} from 'react'
import { ITzTextField } from './ITzTextField'
import { CircularProgress, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material'
import TzService from '../../../../../services/tz/tz.service'
import { ITzUpdateSimpleFieldRequest } from '../../../../../services/tz/ITzRequest'
import { observer } from 'mobx-react-lite'
import { AxiosError } from 'axios'
import tzTableStore from '../../../../../store/tzTable.store'
import { Check, Close } from '@mui/icons-material'
import { CustomIconButton } from '../../../../Buttons/CustomIconButton/CustomIconButton'
import { EditIconButton } from '../../../../Buttons/EditIconButton/EditIconButton'
import { CopyIconButton } from '../../../../Buttons/CopyIconButton/CopyIconButton'

export const TzTextField:FC<ITzTextField> = observer((props) => {
  const {
    id,
    field,
    update_id,
    initValue,
    isEdit = false,
    isLink = false,
    isCopy = false
  } = props


  const [value, setValue] = useState(initValue ? initValue : '')

  const [isLoading, setLoading] = useState(false)

  const [isShowEdit, setShowEdit] = useState(false)

  const copyText = (text:string) => {
    navigator.clipboard.writeText(text)
  }

  const closeEdit = () => {
    setValue(initValue ? initValue : '')
    setShowEdit(false)
  }

  const changeField = () => {
    setLoading(true)

    const option:ITzUpdateSimpleFieldRequest = {
      id,
      field,
      update_id,
      new_value:value
    }
    TzService.updateSimpleField(option)
      .then(res=>{
        tzTableStore.updateSimpleField(id, field, res.data[field], res.data.update_id)

        setShowEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoading(false)
        }
      })
  }

  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        {initValue && !isShowEdit ?
          !isLink
            ? <Typography noWrap sx={{maxWidth:200}}>{initValue}</Typography>
            : <Link
                color={'secondary'} 
                href={initValue} 
                target='_blank' 
                sx={{
                  whiteSpace: 'nowrap', 
                  textOverflow: 'ellipsis', 
                  overflow: 'hidden', 
                  maxWidth:185
                }}
              >
                {initValue}
              </Link>
          : null
        }
        {initValue === null || isShowEdit &&
          <>
            <TextField
              value={value}
              onChange={e=>setValue(e.target.value)}
              size='small'
              placeholder='Введите значение'
              sx={{maxWidth:185}}
              disabled={isLoading}
              autoFocus
              InputProps={{
                endAdornment:(
                  <>
                    {isLoading &&
                      <InputAdornment position="end">
                        <CircularProgress sx={{maxHeight:20, maxWidth:20}}/>
                      </InputAdornment>
                    }
                  </>
                ),
              }}
    
            />
            <CustomIconButton 
              size='small'
              disabled={value?.trim() === '' || value?.trim() === initValue || isLoading}
              onClick={changeField}
              showBackground
              sx={{ml:0.5}}
            >
              <Check fontSize='small'/>
            </CustomIconButton>
            <CustomIconButton size='small' onClick={closeEdit} disabled={isLoading}>
              <Close fontSize='small'/>
            </CustomIconButton>
          </>
        }
        {initValue && isCopy && !isShowEdit &&
          <CopyIconButton copyText={initValue}/>
        }
        {isEdit && !isShowEdit &&
          <EditIconButton size='small' onClick={()=>setShowEdit(true)}/>
        }
      </Stack>
    </>
  )
})