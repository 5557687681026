import { FC, useState } from 'react'
import { PromokodList } from '../../../../Promokod/PromokodList'
import { IPromokodField } from './IPromokodField'
import IntegrationsService from '../../../../../services/integration/integrations.service'
import { IInsertDummiesRequest, IInsertPromokodRequest, IIntegrationPromokodDelete } from '../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import integrationTableStore from '../../../../../store/integrationTable.store'
import { EditModalField } from '../EditModalField/EditModalField'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { NotificationAlerts } from '../../../../NotificationAlerts/NotificationAlerts'

export const PromokodField:FC<IPromokodField> = (props) => {
  const {
    dummies, 
    promokod, 
    id, 
    isEditMode, 
    readOnly, 
    isActivated,
  } = props

  const [isWithSource, setWithSource] = useState(true)
  const [isEdit, setEdit] = useState(false)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [isLoadingDummy, setLoadingDummy] = useState(false)
  const [isShowAddModal, setShowAddModal] = useState(false)

  const addDummysPromokod = (value:string) => {
    setLoadingDummy(true)
    // setError(false)

    const option:IInsertDummiesRequest = {
      mainPromo:promokod,
      dummies:[...dummies, value]
    }
    IntegrationsService.insertDummies(option)
      .then(res=>{
        // console.log(res, 'res add dummy prom')

        if(id) {
          integrationTableStore.updatePromokod(+id, res.data.dummies)
        }
        setLoadingDummy(false)
        // setError(false)
        setShowAddModal(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err dumm add')
          // setError(true)
          setLoadingDummy(false)
        }
      })
  }

  const submit = (value:string) => {
    setLoading(true)
    setError(null)
    
    if(id){
      const option:IInsertPromokodRequest = {
        id:+id,
        promokod:value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase(),
        syncWithSource:isWithSource
      }
      IntegrationsService.insertPromokod(option)
        .then(res=>{
          // console.log(res, 'res prom field')
          if(isWithSource){
            integrationTableStore.insertPromokod({
              id:+id,
              promokodCode:res.data.promokod?.code,
              dummyArray:res.data.promokod?.dummies,
              update_id:res.data.update_id,
              newSource:res.data.promokod?.code,
              isActivated:res.data.promokod?.isActivated
            })
          } else integrationTableStore.insertPromokod({
            id:+id,
            promokodCode:res.data.promokod?.code,
            dummyArray:res.data.promokod?.dummies,
            update_id:res.data.update_id,
            isActivated:res.data.promokod?.isActivated
          })
          
          setEdit(false)
          setLoading(false)
          setWithSource(true)
        })
        .catch(err=>{
           if(err instanceof AxiosError) {
              // console.log(err, 'err prom field')
              setError(err.response?.data?.message)
              setLoading(false)
           }
        })
    }
  }

  const close = () => {
    setError(null)
    setEdit(false)
    setWithSource(true)
  }

  const removeDummyPromokod = (mainPromo:string, removeValue:string) => {
    const option:IInsertDummiesRequest = {
      mainPromo,
      dummies:dummies.filter(item=>item !== removeValue)
    }
    IntegrationsService.insertDummies(option)
      .then(res=>{
        // console.log(res, 'res dum delete')
        integrationTableStore.updatePromokod(+id!, res.data.dummies)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err dummy remove')
        }
      })
  }

  const onDeletePromokod = (id:number) => {
    const option:IIntegrationPromokodDelete = {
      id:id,
      promokod
    }
    IntegrationsService.deletePromokod(option)
      .then(res=>{
        integrationTableStore.deletePromokod(id, res.data.update_id)
      })
      .catch(err=>{
        if(err instanceof AxiosError){

        }
      })

  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWithSource(event.target.checked)
  }

  return (
    <>
      <PromokodList
        dummies={dummies}
        promokod={promokod}
        id={id}
        isEditMode={isEditMode}
        openEdit={()=>setEdit(true)}
        readOnly={readOnly}
        isAddDummy={isShowAddModal}
        setAddDummy={()=>setShowAddModal(true)}
        removeDummyPromokod={removeDummyPromokod}
        isActivated={isActivated}
        onDeletePromokod={id ? ()=>onDeletePromokod(+id) : ()=>''}
        addDummyComponent={
          <EditModalField
            title='Форма добавления промокода'
            open={isShowAddModal}
            onClose={()=>setShowAddModal(false)}
            placeholder='Введите промокод'
            onSubmit={addDummysPromokod}
            loading={isLoadingDummy}
            error={error ? true : false}
            promokodFlag={true}
          />
        }
      />
      {isEdit &&
        <EditModalField
          loading={loading}
          title='Форма редактирования промокода'
          open={isEdit}
          onClose={close}
          onSubmit={submit}
          placeholder='Введите промокод'
          initValue={promokod}
          textCase='uppercase'
          error={error ? true : false}
          subContentElemet={
            <Box>
              <FormControlLabel
                control={<Checkbox disabled={loading} checked={isWithSource} onChange={handleChange} />}
                label="Синхронизировать с источником"
              />
            </Box>
          }
          promokodFlag={true}
        />
      }
      <NotificationAlerts 
        error={{onClose:()=>setError(null), open:error ? true : false, text:error ? error : ''}}
      />
    </>
  )
}