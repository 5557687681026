import { FC } from "react"
import { Stack, Typography } from "@mui/material"
import { AsideCardLayout } from "../../../../../AsideCardLayout/AsideCardLayout"
import { IUserSubDetails } from "./IUserSubDetails"

export const UserSubDetails:FC<IUserSubDetails> = (props) => {
  const {company, roleName} = props

  return (
    <AsideCardLayout headerTitle="детали">
      <Stack spacing={1} sx={{pt:1}}>
        <Typography>Компания: <Typography>{company}</Typography></Typography>
        <Typography>Роль: <Typography>{roleName}</Typography></Typography>
      </Stack>
    </AsideCardLayout>
  )
}