import {FC, useState} from 'react'
import { Button, CircularProgress } from '@mui/material'
import { AxiosError, AxiosProgressEvent } from 'axios'
import { downloadExcel } from '../../../../../../../../shared/functions/functions'
import { IButtonExcelDownload } from './IButtonExcelDownload'
import MotivationService from '../../../../../../../../services/motivation/motivation.service'
import { IMotivationExcelForSearchRequest } from '../../../../../../../../services/motivation/IMotivationRequest'
import { observer } from 'mobx-react-lite'
import AllUsersStore from '../../../../../../../../store/AllUsers.store'
import { NotificationProgress } from '../../../../../../../../components/NotificationProgress/NotificationProgress'

export const ButtonExcelDownload:FC<IButtonExcelDownload> = observer((props) => {
  const {
    month,
    userId,
    year
  } = props

  const [isExcelLoading, setExcelLoading] = useState(false)
  const [propgressExport, setPropgressExport] = useState(0)

  const currentUser = AllUsersStore?.users?.find(x=>x.id === userId)

  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ]

  const onDownloadProgress = (progressEvent:AxiosProgressEvent) => {
    if(progressEvent.estimated && progressEvent.total){
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
      setPropgressExport(prev=>progress)
    }
  }

  const onExportExcel = () => {
    setExcelLoading(true)
    setPropgressExport(prev=>0)

    const option: IMotivationExcelForSearchRequest = {
      month,
      userId,
      year
    }
    MotivationService.motivationExcelForSearch(option, onDownloadProgress)
      .then(res=>{
        downloadExcel(res.data, `Каналы ${currentUser?.firstName} ${currentUser?.lastName} за ${months[month-1]} ${year}г.`)
        setPropgressExport(prev=>0)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'download excel err')
        }
      })
      .finally(()=>{
        setExcelLoading(false)
      })
  }

  return (
    <>
      {isExcelLoading && <NotificationProgress propgress={propgressExport}/>}
      <Button 
        onClick={onExportExcel} 
        disabled={isExcelLoading}
        startIcon={isExcelLoading && <CircularProgress sx={{maxHeight:20, maxWidth:20}}/>}
        variant='outlined'
      >
        выгрузить в excel
      </Button>
    </>
  )
})