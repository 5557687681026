import { useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material'
import { VisibilityOff } from '@mui/icons-material'
import { SESSION_MOTIVATION_MODAL_INFO } from '../../../../../../shared/constants/sessionStorage'

export const InfoMotivationModal = () => {

  if(!window.sessionStorage.getItem(SESSION_MOTIVATION_MODAL_INFO)) {
    window.sessionStorage.setItem(SESSION_MOTIVATION_MODAL_INFO, 'true')
  }

  const [isShowInfo, setShowInfo] = useState(window.sessionStorage.getItem(SESSION_MOTIVATION_MODAL_INFO) === 'true' ? true : false)

  const onHide = () => {
    window.sessionStorage.setItem(SESSION_MOTIVATION_MODAL_INFO, 'false')
    setShowInfo(false)
  }

  return (
    <Dialog
      open={isShowInfo}
      onClose={()=>''}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle sx={{display:'flex', alignItems:'center', justifyContent:'space-between', p:{xs:1, sm:2}, pr:1, fontSize:{xs:'14px', sm:'1.07rem'}}}>
        Ознакомьтесь подробнее с правилами работы
      </DialogTitle>
      <Divider/>
      <DialogContent sx={{p:{xs:1, sm:2},}}>
        <Stack spacing={1}>
          <Typography variant='h6' sx={{fontWeight:'normal', fontSize:{xs:'0.87rem', sm:'1.07rem'}}}>
            Если в параметрах отображения мотивации выбран только тип менеджера, то редактирование таблицы мотивации касается всех менеджеров
          </Typography>
          <Typography variant='h6' sx={{fontWeight:'normal', fontSize:{xs:'0.87rem', sm:'1.07rem'}}}>
            Если в параметрах отображения мотивации выбран менеджер, период и нажата кнопка <b>"применить"</b>, то редактирование коэффициентов коснётся только выбранного менеджера
          </Typography>
        </Stack>
      </DialogContent>
      <Divider/>
      <Stack
        sx={{
          p:{xs:1, sm:2},
          justifyContent:'space-between',
          flexDirection:{xs:'column', sm:'row'}
        }}
      >
        <Button
          variant='outlined'
          onClick={onHide}
          endIcon={<VisibilityOff/>}
          sx={{width:{xs:'100%', sm:'auto'}, mb:{xs:1, sm:0}}}
        >
          <Typography sx={{display:{xs:'inline-block', sm:'none'}}}>Скрыть на время</Typography>
          <Typography sx={{display:{xs:'none', sm:'inline-block'}}}>Скрыть на время текущей сессии</Typography>
        </Button>
        <Button
          variant='contained'
          onClick={()=>setShowInfo(false)}
          sx={{width:{xs:'100%', sm:'auto'}}}
        >
          <Typography>Принять</Typography>
        </Button>
      </Stack>
    </Dialog>
  )
}