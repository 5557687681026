import {FC, useState} from 'react'
import { IChannelPriceEdit } from './IChannelPriceEdit'
import { InputEditField } from '../InputEditField/InputEditField'
import { AxiosError } from 'axios'
import ChannelService from '../../../../../services/channel/channel.service'
import { IUpdateStatusesRequest } from '../../../../../services/integration/IntegrationsRequest'
import channelTableStore from '../../../../../store/channelTable.store'
import { Stack, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { priceTransormFormat } from '../../../../../shared/functions/functions'
import { CustomIconButton } from '../../../../Buttons/CustomIconButton/CustomIconButton'
import { EditIconButton } from '../../../../Buttons/EditIconButton/EditIconButton'

export const ChannelPriceEdit:FC<IChannelPriceEdit> = (props) => {
  const {
    id,
    field,
    update_id,
    readOnly = false,
    value
  } = props

  const [isEdit, setEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const updatePrice = (value:string) => {
    setLoading(true)
    setError(null)

    const option:IUpdateStatusesRequest = {
      field,
      id,
      new_value:value,
      update_id
    }
    ChannelService.updatePrice(option)
      .then(res=>{
        // console.log(res, 'res update channel init price')

        channelTableStore.changePredictFields(id, field, +value, res.data)

        setLoading(false)
        setEdit(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          // console.log(err, 'change price err')
          setLoading(false)
          setError(err?.response?.data?.message)
        }
      })
  }

  return (
    <>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'}>
          {value !== null
            ? <Typography sx={{whiteSpace:'nowrap'}}>{priceTransormFormat(value)}</Typography>
            : <CustomIconButton onClick={()=>setEdit(true)} disabled={readOnly}>
                <Add/>
              </CustomIconButton>
          }
          {!readOnly && value !== null ?
            <EditIconButton size='small' onClick={()=>setEdit(true)}/>
          : null}
        </Stack>
      }
      {isEdit &&
        <InputEditField
          initValue={value !== null ? value.toString() : ''}
          onClose={()=>setEdit(false)}
          onSubmit={updatePrice}
          fullWidth
          inputType='number'
          placeholder='Введите значение'
          loading={isLoading}
          error={isError}
        />
      }
    </>
  )
}
