import {FC, useState} from 'react'
import {InputEditField} from '../InputEditField/InputEditField'
import {IUpdateStatusesRequest} from '../../../../../services/integration/IntegrationsRequest'
import {AxiosError} from 'axios'
import {IChannelNumEditField} from './IChannelNumEditField'
import {Box, Stack, Typography} from '@mui/material'
import {Add} from '@mui/icons-material'
import ChannelService from '../../../../../services/channel/channel.service'
import {NotificationAlerts} from '../../../../NotificationAlerts/NotificationAlerts'
import {priceTransormFormat} from "../../../../../shared/functions/functions";
import { EditIconButton } from '../../../../Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../../../../Buttons/CustomIconButton/CustomIconButton'

export const ChannelNumEditField: FC<IChannelNumEditField> = (props) => {
  const {
    id,
    field,
    update_id,
    readOnly = false,
    onResCallback,
    initValue,
    symbol,
    disabled = false
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)
  const [isEdit, setEdit] = useState(false)

  const submit = (newValue: string) => {
    setLoading(true)
    setError(null)

    const option: IUpdateStatusesRequest = {
      id,
      update_id,
      field,
      new_value: newValue
    }
    ChannelService.updateSimpleField(option)
      .then(res => {
        // console.log(res, 'update pridict field', field)

        if (onResCallback) {
          onResCallback(id, field, newValue, res.data.update_id)
        }

        setLoading(false)
        setEdit(false)
      })
      .catch(err => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err update predicti', field)
          setLoading(false)
          setError(err?.response?.data?.message)
        }
      })
  }
  return (
    <>
      <Box>
        {!isEdit &&
          <Stack direction={'row'} alignItems={'center'}>
            {initValue !== null ?
              <Typography>{`${priceTransormFormat(initValue, false)} ${symbol ? symbol : ''}`}</Typography> : null}
            {initValue === null
              ? <CustomIconButton disabled={disabled} onClick={() => setEdit(true)}>
                  <Add/>
                </CustomIconButton>
              : null}
            {!readOnly && initValue !== null ?
              <EditIconButton size='small' disabled={disabled} onClick={() => setEdit(true)}/>
              : null}
          </Stack>
        }
        {isEdit &&
          <InputEditField
            inputType='number'
            initValue={initValue?.toString()}
            loading={isLoading}
            onClose={() => setEdit(false)}
            onSubmit={submit}
          />
        }
      </Box>
      <NotificationAlerts
        error={{text: isError ? isError : '', open: isError ? true : false, onClose: () => setError(null)}}
      />
    </>
  )
}