import {FC, useState} from 'react'
import {IChannelNamePopUp} from './IChannelNamePopUp'
import {Avatar, Button, Link, Menu, Stack, Typography} from '@mui/material'
import {ContentCopy, VideoCameraFrontOutlined} from '@mui/icons-material'
import {observer} from 'mobx-react-lite'
import channelStore from '../../../../../store/channel.store'
import {NotificationAlerts} from '../../../../NotificationAlerts/NotificationAlerts'
import {EditModalField} from '../EditModalField/EditModalField'
import {useSearchParams} from 'react-router-dom'
import {CHANNEL_CARD_OPEN} from '../../../../../shared/constants/searchParams'
import { EditIconButton } from '../../../../Buttons/EditIconButton/EditIconButton'
import { CopyIconButton } from '../../../../Buttons/CopyIconButton/CopyIconButton'
import { CustomIconButton } from '../../../../Buttons/CustomIconButton/CustomIconButton'

export const ChannelNamePopUp: FC<IChannelNamePopUp> = observer((props) => {
  const {
    name,
    link,
    channelType,
    showChannelButton = false,
    channelId,
    onCloseEditModal,
    onSubmitEdit,
    openEditModal,
    onLoadingModalEdit,
    onOpenEditModal,
    readOnly = false,
    isBanned,
    showCopy,
    width,
    showIcon,
    showVideoReviewIcon
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const currentTypeIcon = channelStore?.channels![channelType] || null

  const [isCopyAlert, setCopyAlert] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const goToChannel = () => {
    searchParams.set(CHANNEL_CARD_OPEN, `${channelId}`)
    setSearchParams(searchParams)
    handleClose()
  }

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text)
    setCopyAlert(true)
    handleClose()
  }

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} sx={{width: width ? width : '100%'}}>
        {currentTypeIcon && <Avatar src={currentTypeIcon.icon} alt={currentTypeIcon.name} sx={{maxHeight: 20, maxWidth: 20, mr: 1, ml: 1}}/>}
        {showVideoReviewIcon && <VideoCameraFrontOutlined sx={{mr:0.5, ml:-0.5}}/>}
        <Typography
          sx={{
            display: 'inline-block',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          onClick={handleClick}
          color={isBanned ? 'error' : 'primary'}
        >
          {name}
        </Typography>
        {!showCopy &&
          <CopyIconButton copyText={name}/>
        }
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Stack
          direction={'row'}
          alignItems='center'
          justifyContent='space-between'
          spacing={2}
          sx={{p: '4px 8px', overflow: 'auto'}}
        >
          <Stack direction={'row'} alignItems='center'>
            <Link
              color={'secondary'}
              href={link}
              target='_blank'
              sx={{
                whiteSpace: "nowrap",
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '200px'
              }}
            >
              {link}
            </Link>
            <Stack direction={'row'} alignItems='center' sx={{ml: 0.5}}>
              <CustomIconButton size='small' onClick={() => copyText(link)}>
                <ContentCopy fontSize='small'/>
              </CustomIconButton>
              {!readOnly && !isBanned &&
                <EditIconButton size='small' onClick={onOpenEditModal}/>
              }
            </Stack>
          </Stack>
          {showChannelButton ?
            <Button
              size='small'
              variant='outlined'
              onClick={goToChannel}
              sx={{whiteSpace: 'nowrap'}}
            >
              к каналу
            </Button>
            : null}
        </Stack>
      </Menu>
      {isCopyAlert &&
			 <NotificationAlerts
				sucsess={{text: 'Успешно скопировалось!', open: isCopyAlert, onClose: () => setCopyAlert(false)}}
			 />
      }
      {openEditModal &&
			 <EditModalField
				open={openEditModal}
				onClose={onCloseEditModal}
				onSubmit={onSubmitEdit}
				title='Форма редактирования ссылки'
				loading={onLoadingModalEdit}
				initValue={link}
				placeholder='Введите ссылку'
				subInputText='Ссылка:'
			 />
      }
    </>
  )
})