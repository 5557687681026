import {FC, useState} from 'react'
import { AsideCardLayout } from '../../../../../../../../components/AsideCardLayout/AsideCardLayout'
import { IntegrationAllSumm } from '../../../../../../../FinancesCreatePage/ui/FinancesIntegrations/ui/integrationAllSumm/IntegrationAllSumm'
import { observer } from 'mobx-react-lite'
import { ITotalPrice } from './ITotalPrice'
import integrationPageStore from '../../../../../../../../store/integrationPage.store'
import { Box } from '@mui/material'
import { IUpdatePriceRequest } from '../../../../../../../../services/integration/IntegrationsRequest'
import IntegrationsService from '../../../../../../../../services/integration/integrations.service'
import integrationTableStore from '../../../../../../../../store/integrationTable.store'
import { AxiosError } from 'axios'

export const TotalPrice:FC<ITotalPrice> = observer((props) => {
  const {
    readOnly,
    setPriceValue,
    setUpdateId,
    update_id,
    id,
    onChangeCpv
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isEdit, setEdit] = useState(false)

  const changeSumm = (value:string) => {
    setLoading(true)

    const option:IUpdatePriceRequest = {
      id:+id,
      new_value:value,
      field:'price',
      update_id:update_id
    }
    
    IntegrationsService.updatePrice(option)
      .then(res=>{
        // console.log(res, 'res update price')
        integrationTableStore.changePrice(id, {cpv:res.data.cpv, price:res.data.price, update_id:res.data.update_id})

        integrationPageStore.setTotalSumm(+value)
        setPriceValue(+value)

        onChangeCpv(res.data.cpv.toString())

        setUpdateId(res.data.update_id)
        
        setEdit(false)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err update res')
          setLoading(false)
        }
      })
    
  }

  return (
    <AsideCardLayout headerTitle='интеграция'>
      <Box sx={{mt:1}}>
        <IntegrationAllSumm
          allSumm={integrationPageStore.totalSumm || 0}
          changeSumm={changeSumm}
          isEdit={isEdit}
          setEdit={setEdit}
          integrations={[]}
          readOnly={readOnly}
          text={'Стоимость интеграции:'}
          loading={isLoading}
        />
      </Box>
    </AsideCardLayout>
  )
})