import {FC} from 'react'
import { ICreateAsideCommentItem } from './ICreateAsideCommentItem'
import { Avatar, Box, Stack, Tooltip, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import createChannelStore from '../../../../../../store/createChannel.store'
import {RemoveItemButton} from "../../../../../../components/RemoveItemButton/RemoveItemButton";

export const CreateAsideCommentItem:FC<ICreateAsideCommentItem> = observer((props) => {
  const {name, text, imageUrl} = props

  return (
    <Box sx={{display:'grid', gridTemplateColumns:'min-content 1fr', columnGap:'8px'}}>
      <Avatar src={imageUrl} alt={name} sx={{height:30, width:30, mt:0.5}}/>
      <Stack>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography color={'secondary'}>{name}</Typography>
          <Tooltip title='Удалить' placement='right'>
            <RemoveItemButton onClick={()=>createChannelStore.setPostedRemoveComment(text)}/>
          </Tooltip>
        </Stack>
        <Typography sx={{lineBreak:'anywhere'}}>{text}</Typography>
      </Stack>
    </Box>
  )
})