import {FC} from 'react'
import { IconButton } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ICustomIconButton } from './ICustomIconButton';
import { theme } from '../../../app/theme';

export const CustomIconButton:FC<ICustomIconButton> = (props) => {
  const {
    children,
    showBackground = false,
  } = props

  const iconTheme = createTheme({
    components: {
      MuiIconButton:{
        variants:[
          {
            props:{
              color:'primary',
            },
            style:{
              color:!showBackground ? 'rgba(0, 0, 0, 0.54)' : '#fff',
              '&:hover':{
                color:!showBackground ? '#7C64D9' : '#fff',
                background:!showBackground ? undefined : '#7C64D9'
              },
              '&:active':{
                color:!showBackground ? '#503D9D' : '#fff',
                background:!showBackground ? undefined : '#503D9D'
              },
              background:!showBackground ? undefined : theme.palette.primary.main,
            }
          },
        ],
        defaultProps:{
          color:'primary',
          size:'small',
        }
      },
    },
    typography:theme.typography
  })

  return (
    <ThemeProvider theme={iconTheme}>
      <IconButton {...props}>
        {children}
      </IconButton>
    </ThemeProvider>
  )
}
