import { useState, FC, useEffect } from 'react'
import { Add } from '@mui/icons-material'
import { Autocomplete, Avatar, Box, CircularProgress, FormControl, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { IAddIntegrationModal } from './IAddIntegrationModal'
import { AlertModal } from '../../../../../../components/AlertModal/AlertModal'
import { IReferencesHintsIntegrationResponse } from '../../../../../../services/references/IReferencesResponse'
import ReferencesService from '../../../../../../services/references/references.service'
import { CustomButton } from '../../../../../../components/Buttons/CustomButton/CustomButton'

export const AddIntegrationModal:FC<IAddIntegrationModal> = (props) => {
  const {
    addedIntegration,
    sx,
    isShowModal,
    onCloseModal,
    onOpenModal,
    error,
    isLoading,
    readOnly = false,
    onCloseError,
  } = props

  const [isLoadingSearch, setLoadingSearch] = useState(false)
  const [errorSearch, setErrorSearch] = useState<string | null>(null)

  const [value, setValue] = useState<IReferencesHintsIntegrationResponse | null>(null)
  const [integrationList, setIntegrationList] = useState<IReferencesHintsIntegrationResponse[] | null>(null)
  const [inputValue, setInputValue] = useState<string>('')

  const getIntegrationList = (value:string) => {
    setLoadingSearch(true)
    setIntegrationList(null)
    setErrorSearch(null)
    onCloseError()

    ReferencesService.hintsIntegrations({target:value})
      .then(res=>{
        // console.log(res, 'res')

        setIntegrationList(res.data)

        setLoadingSearch(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setErrorSearch(err?.response?.data?.message)
          setLoadingSearch(false)
        }
      })
  }

  const closeModal = () => {
    onCloseModal()
    setValue(null)
    setIntegrationList(null)
    setInputValue('')
    setErrorSearch(null)
  }

  useEffect(() => {
    if(inputValue.trim() !== ''){

      if(inputValue !== `${value?.id?.toString()} [${value?.promokod?.code}] ${value?.channel?.name || ''}`){
        const delay = setTimeout(()=> getIntegrationList(inputValue), 500)
        return ()=>clearTimeout(delay)
      }

    }
  },[inputValue])

  const onClickGetIntegrationsHandler = () => {
    onOpenModal(true)
    getIntegrationList(inputValue)
  }

  return (
    <>
      <CustomButton
        size='small'
        variant='outlined'
        startIcon={<Add fontSize='small'/>}
        onClick={onClickGetIntegrationsHandler}
        sx={sx}
        disabled={readOnly}
      >
        добавить интеграцию
      </CustomButton>
      {isShowModal &&
        <AlertModal
          title='Добавить интеграцию'
          open={isShowModal}
          onClose={closeModal}
          onSubmit={value ? ()=>addedIntegration(value?.id?.toString(), closeModal) : ()=>''}
          disabled={value === null}
          buttonCancelName='отмена'
          buttonSubmitName='добавить'
          loading={isLoading}
          subContent={
            <> 
              <FormControl size='small' fullWidth>
                <Typography
                  sx={{mb:0.5}}
                >
                  ID Интеграции <Typography component={'span'} color={'error'}>*</Typography>
                </Typography>
                <Autocomplete
                  value={value}
                  onChange={(event: any, newValue: IReferencesHintsIntegrationResponse | null) => {
                    setValue(newValue)
                  }}
                  inputValue={inputValue}
                  onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue)
                  }}
                  options={integrationList || []}
                  getOptionLabel={(option) => `${option.id?.toString()} [${option?.promokod?.code}] ${option?.channel?.name || ''}`}
                  filterSelectedOptions
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <Typography>{option?.id} [{option?.promokod?.code}] {option?.channel?.name}</Typography>
                      <Avatar src={option?.channel?.type?.icon} alt={option?.channel?.name} sx={{height:20, width:20, ml:1}}/>
                    </Box>
                  )}
                  noOptionsText='Ничего не найдено'
                  loading={isLoadingSearch}
                  disabled={isLoading}
                  loadingText={'Загрузка...'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={'Введите ID интеграции, промокод, название канала'}
                      size='small'
                      autoFocus
                      error={Boolean(error)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingSearch ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  size='small'
                />
              </FormControl>
              {error ? <Typography color={'error'} sx={{mt:0.5}}>{error}</Typography> : null}
            </>
          }
        />
      }
    </>
  )
}
