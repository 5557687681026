import {FC} from 'react'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { Timeline } from '@mui/icons-material'
import releasePlacementsStore from '../../../store/releasePlacements.store'
import { IReleaseSelectPlacement } from '../models/IReleaseSelectPlacement'

export const ReleaseSelectPlacement:FC<IReleaseSelectPlacement> = (props) => {
  const {
    code, 
    onChange, 
    anchorEl, 
    onClose, 
    onOpen, 
    readOnly
  } = props

  const open = Boolean(anchorEl)

  const changeValue = (value:string) => {
    onChange(value)
  }

  return (
    <>
      <Tooltip title='Позиция'>
        <IconButton size='small' onClick={onOpen}>
          <Timeline fontSize='small'/>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{sx:{p:0}}}
      >
        {releasePlacementsStore.placements?.map(item=>
          <MenuItem 
            key={item.code} 
            selected={code === item.code} 
            value={item.code}
            onClick={!readOnly ? ()=>changeValue(item.code) : undefined}
            disabled={readOnly}
          >
            {item.name}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
