import { FC } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from '@mui/material';
import {DateRangePickerProps} from "./IDateRangePicker";
import 'dayjs/locale/ru';

export const DateRangePicker: FC<DateRangePickerProps> = (props) => {
  const {
    initStartDate,
    initEndDate,
    onChangeStart,
    onChangeEnd
  } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <Box sx={{ display: 'flex', gap: 2 }}>
        <DatePicker
          label="Начальная дата"
          views={['year', 'month', 'day']}
          value={initStartDate}
          onChange={onChangeStart}
          slotProps={{textField:{size:'small'}}}
        />
        <DatePicker
          label="Конечная дата"
          views={['year', 'month', 'day']}
          value={initEndDate}
          onChange={onChangeEnd}
          slotProps={{textField:{size:'small'}}}
        />
      </Box>
    </LocalizationProvider>
  );
};
