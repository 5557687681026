import {FC, useState, useEffect} from 'react'
import { IDateEditField } from './IDateEditField'
import 'dayjs/locale/ru'
import dayjs, { Dayjs } from 'dayjs'
import IntegrationsService from '../../../../../services/integration/integrations.service'
import { IUpdateStatusesRequest } from '../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import integrationTableStore from '../../../../../store/integrationTable.store'
import { DateInputEdit } from '../DateInputEdit/DateInputEdit'
import { sendDatePlusCustomTime } from '../../../../../shared/functions/functions'

export const DateEditField:FC<IDateEditField> = (props) => {
  const {
    date,
    readonly,
    field,
    id, 
    update_id,
    fullWidth = false,
    isFullYears = false,
    isIntegrationDates
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isOpenEdit, setOpenEdit] = useState(false)
  const [dateValue, setDateValue] = useState<Dayjs | null>(date ? dayjs(date) : null)

  const submit = (dateValue:any) => {
    if(dateValue) {
      setLoading(true)
      const currentDate:string = sendDatePlusCustomTime({value:dateValue})

      const option:IUpdateStatusesRequest = {
        field,
        id,
        new_value:currentDate,
        update_id
      }
      IntegrationsService.updateSimpleField(option)
        .then(res=>{
          // console.log(res, 'res upd table date')
          integrationTableStore.updateDate(id, res.data.update_id, currentDate, field)

          if(isIntegrationDates){
            integrationTableStore.changeGurange(id, res.data?.guarantee || null, res.data.update_id)
          }

          setDateValue(dayjs(currentDate))
          setOpenEdit(false)
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError) {
            // console.log(err, 'err update date in table')
            setLoading(false)
          }
        })
    }
  }

  const handelEdit = () => {
    setOpenEdit(true)
  }

  const handelClose = () => {
    setOpenEdit(false)
    setDateValue(dayjs(date))
  }


  useEffect(()=>{
    setDateValue(dayjs(date))
  },[date])

  return (
    <DateInputEdit
      date={date}
      value={dateValue as any}
      onChange={setDateValue}
      open={isOpenEdit}
      onClose={handelClose}
      onOpen={handelEdit}
      onSubmit={submit}
      loading={isLoading}
      fullWidth={fullWidth}
      readOnly={readonly}
      isFullYears={isFullYears}
    />
  )
}
