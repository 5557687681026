import { FC, useEffect, useState } from 'react'
import { Notifications } from '@mui/icons-material'
import { Badge, ListItemButton, Tooltip } from '@mui/material'
import { INotificationButton } from './INotificationButton'
import { AsideNotification } from '../../../AsideNotification/AsideNotification'
import NotificationsService from '../../../../services/notifications/notifications.service'
import { AxiosError } from 'axios'
import { INotificationItem } from '../../../AsideNotification/ui/AsideNotificationItem/IAsideNotificationItem'

export const NotificationButton:FC<INotificationButton> = (props) => {
  const {loading = false} = props

  const [data, setData] = useState<null | INotificationItem[]>(null)
  const [hideOnlyReads, setHideOnlyReads] = useState<null | INotificationItem[]>(null)
  const [isLoading, setLoading] = useState(false)
  const [isShow, setShow] = useState(false)

  const getAllNotifications = () => {
    setLoading(true)

    NotificationsService.getAllNotifications()
      .then(res=>{
        // console.log(res, 'res all notific left menu')
        setData(res.data)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err notific all leftMenu')
          setLoading(false)
        }
      })
  }

  const checkedReadNotification = (id:number) => {
    NotificationsService.readNotification(id)
      .then(res=>{
        // console.log(res, 'res read notific', id)
        if(data!==null){
          setData(prev=>[...prev!.map(el=>el.id === id ? {...el, isRead:true} : el)])
        }
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err read notific', id)
        }
      })
  }

  const checkNotificationsIsNew = (array:INotificationItem[]):number => {
    const newArr = array.map(item=>item?.isRead)?.filter(x=>Boolean(x)===false)
    return newArr?.length
  }

  const hideAllReadNotifications = () => {

    if(data){
      setHideOnlyReads(prev=>[...data.filter(item=>Boolean(item?.isRead) === false)])
    }
 
    if(Array.isArray(hideOnlyReads)){
      setHideOnlyReads(null)
    }
  }

  useEffect(()=>{
    if(!isShow) {
      setHideOnlyReads(null)
    }
  }, [isShow])

  useEffect(()=>{
    getAllNotifications()
  },[])

  return (
    <>
      <Tooltip title={'Оповещения'} placement='right'>
        <ListItemButton sx={{justifyContent:'center'}} disabled={loading || isLoading} onClick={()=>setShow(true)}>
          <Badge variant='dot' color='error' invisible={data ? checkNotificationsIsNew(data) === 0 : true}>
            <Notifications sx={{color:'#fff'}} fontSize='large'/>
          </Badge>
        </ListItemButton>
      </Tooltip>
      {data &&
        <AsideNotification
          open={isShow}
          onClose={()=>setShow(false)} 
          anchor='left'
          data={hideOnlyReads ? hideOnlyReads : data} 
          notificationsQuantity={checkNotificationsIsNew(data)}
          changeReadState={checkedReadNotification}
          hideRead={hideAllReadNotifications}
        />
      }
    </>
  )
}