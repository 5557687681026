import {FC} from 'react'
import { ITableFooter } from '../models/ITableFooter'
import { Button, Pagination, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

export const TableCustomFooter:FC<ITableFooter> = (props) => {
  const {
    hideBorder,
    toggleValues,
    onChaneToggleButton,
    totalItems,
    onExportExcel,
    toggleItems,
    onChangePage,
    totalCount,
    paginateStep,
    page,
    disabledExportExcelBtn
  } = props

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if(onChangePage){
      onChangePage(value)
    }
  }

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{
        border:hideBorder ? 'none' : '1px solid #e0e0e0',
        borderTop:hideBorder ? '1px solid #e0e0e0' : 'none',
        p:1,
        mt:'-1px',
        background:'#fff',
        borderBottomLeftRadius:'4px',
        borderBottomRightRadius:'4px',
        overflow:'auto'
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-between',
          overflow: 'auto',
          minWidth: '650px',
          width: '100%',
        }}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography sx={{display:{xs:'none', md:'inline-block'}}}>Кол-во строк:</Typography>
          <ToggleButtonGroup value={toggleValues} onChange={onChaneToggleButton} exclusive>
            {toggleItems?.map(item=><ToggleButton key={item} value={item} sx={{p:1}}>{item}</ToggleButton>)}
          </ToggleButtonGroup>
          <Typography>Всего: {totalCount}</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          {onExportExcel && <Button variant='outlined' onClick={onExportExcel} disabled={disabledExportExcelBtn}>Выгрузить в Excel</Button>}
          {totalCount && paginateStep ?
            <Pagination
              page={page}
              onChange={handleChange} 
              count={Math.ceil(totalCount / paginateStep)}
              size='small'
            />
          : null}
        </Stack>
      </Stack>
    </Stack>
  )
}