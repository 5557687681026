import {FC, useEffect, useState} from 'react'
import {IAsideCardThemeList} from './IAsideCardThemeList'
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography} from '@mui/material'
import {IChannelTheame} from '../../../../entities/ChannelTheame/models/IChannelTheame'
import {observer} from 'mobx-react-lite'
import {Check, Close, Delete} from '@mui/icons-material'
import {IChannelChangeThemeRequest} from '../../../../services/channel/IChannelRequest'
import ChannelService from '../../../../services/channel/channel.service'
import { AxiosError } from 'axios'
import channelTableStore from '../../../../store/channelTable.store'
import {RemoveItemButton} from "../../../../components/RemoveItemButton/RemoveItemButton";
import { EditIconButton } from '../../../../components/Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../../../../components/Buttons/CustomIconButton/CustomIconButton'

export const AsideCardThemeList:FC<IAsideCardThemeList> = observer((props) => {
  const {
    shouldHideSelect,
    value, 
    onChange, 
    lists, 
    loading, 
    label = true, 
    minWidth,
    readOnly,
    channelId,
    update_id,
    setThemeValue,
    setUpdateId,
    maxWidth
  } = props

  const [valueText, setValueText] = useState(value ? value.code : '')
  const [editMode, setEditMode] = useState(false)
  const [shouldHideSelectMode, setShouldHideSelectMode] = useState(shouldHideSelect)

  const handleChange = (event: SelectChangeEvent<any>) => {
    const valueCode = event.target.value

    const theameItem:IChannelTheame | undefined = lists.find(x=>x.code === valueCode)

    if(theameItem){

      const {name, code, isActive} = theameItem

      setValueText(valueCode)

      if(onChange){
        onChange(name, code, isActive)
      }
    }
  }

  const onClickHandler = () => {
    setShouldHideSelectMode(true)
    value && valueText && value.code !== valueText && handleChange({target: {value: valueText}} as SelectChangeEvent<any>)
    setEditMode(false)
  }

  const onDeleteTheame = () => {

    const theme:IChannelChangeThemeRequest = {
      id:channelId,
      new_theme:null as any,
      update_id
    }
    ChannelService.changeTheme(theme)
      .then(res=>{
        // console.log(res, 'res change theme')
        channelTableStore.changeTheme(channelId, null as any, res.data.update_id)

        if(setUpdateId){
          setUpdateId(res.data.update_id)
        }
        
        if(setThemeValue){
          setThemeValue(null)
        }

        setValueText('')
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          // console.log(err, 'change theme err')
        }
      })
  }

  useEffect(()=>{
    setValueText(value ? value?.code : '')
  },[value])

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={0.5} sx={{maxWidth}}>
      {!shouldHideSelectMode &&
        <>
          <FormControl fullWidth>
            {label && <InputLabel id="select-label" size='small'>Тип</InputLabel>}
            <Select
              labelId="select-label"
              label={label ? 'Тип' : null}
              size='small'
              value={valueText}
              onChange={(e) => shouldHideSelect ? setValueText(e.target.value) : handleChange(e)}
              disabled={loading || readOnly}
              sx={{minWidth}}
            >
              {lists.map((item: IChannelTheame) =>
                <MenuItem key={item.code} value={item.code}>
                  <Typography sx={{overflow:'hidden', textOverflow: 'ellipsis'}}>{item.name}</Typography>
                </MenuItem>)
              }
            </Select>
          </FormControl>
          {value?.code && !editMode &&
            <RemoveItemButton onClick={onDeleteTheame} disabled={loading || readOnly} isDisableColor={readOnly} />
          }
        </>
      }
      {shouldHideSelectMode && !editMode &&
        <Stack sx={{minWidth}} direction={'row'} alignItems={'center'}>
          <Typography>{value?.name}</Typography>
          <EditIconButton
            disabled={readOnly} 
            size='small' 
            onClick={() => {
              setShouldHideSelectMode(false)
              setEditMode(true)
            }}
          />
        </Stack>
      }
      {editMode && shouldHideSelect &&
			  <Stack direction={'row'} alignItems={'center'}>
          <CustomIconButton
            onClick={onClickHandler}
            size='small'
            showBackground
          >
            <Check fontSize='small'/>
          </CustomIconButton>
          <CustomIconButton 
            size='small' 
            onClick={() => {
              setShouldHideSelectMode(true)
              setEditMode(false)
            }}
          >
            <Close fontSize='small'/>
          </CustomIconButton>
        </Stack>
      }
    </Stack>
  )
})