import {FC, useState} from 'react'
import { IRadioFilter } from './IRadioFilter'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

export const RadioFilter:FC<IRadioFilter> = (props) => {
  const {changeFilter, initValue, removeRelease = false} = props

  const [filterType, setFilterType] = useState<'release' | 'search'>(initValue)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value as 'release' | 'search'

    setFilterType(prev=>value)
    changeFilter(value)
  }

  return (
    <FormControl>
      <FormLabel id="demo-row-radio">Показать план по типу менеджеров:</FormLabel>
      <RadioGroup
        value={filterType}
        onChange={handleChange}
        row
        aria-labelledby="demo-row-radio"
      >
        {!removeRelease && <FormControlLabel value="release" control={<Radio />} label="Выпускающие" />}
        <FormControlLabel value="search" control={<Radio />} label="Поисковые" />
      </RadioGroup>
    </FormControl>
  )
}
