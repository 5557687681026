import {FC} from 'react'
import { IEditModeButton } from './IEditModeButton'
import { CircularProgress, IconButton, Stack } from '@mui/material'
import { Check, Close, Edit } from '@mui/icons-material'
import { CustomIconButton } from '../CustomIconButton/CustomIconButton'

export const EditModeButton:FC<IEditModeButton> = (props) => {
  const {
    isEdit,
    onOpenEdit,
    onClose,
    onSubmit,
    disabledEdit,
    disabledSubmit,
    loading,
    error,
    btnType
  } = props

  return (
    <>
      {!isEdit &&
        <CustomIconButton 
          size='small' 
          disabled={disabledEdit}
          onClick={onOpenEdit}
        >
          <Edit fontSize='small'/>
        </CustomIconButton>
      }
      {isEdit &&
        <Stack direction={'row'} alignItems={'center'}>
          {!loading &&
            <CustomIconButton 
              size='small'
              disabled={disabledSubmit || loading} 
              color={error ? 'error' : 'primary'}
              onClick={onSubmit}
              type={btnType}
              showBackground
            >
              <Check fontSize='small'/>
            </CustomIconButton>
          }
          {!loading &&
            <CustomIconButton 
              size='small'
              disabled={disabledSubmit || loading} 
              onClick={onClose}
            >
              <Close fontSize='small'/>
            </CustomIconButton>
          }
          {loading &&
            <CircularProgress
              sx={{minHeight:18, minWidth:18, maxHeight:18, maxWidth:18, color:'grey'}}
            />
          }
        </Stack>
      }
    </>
  )
}
