import {FC, useState} from 'react'
import { IChannelDate } from './IChannelDate'
import 'dayjs/locale/ru'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { Stack, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Check, Close } from '@mui/icons-material'
import ChannelService from '../../../../services/channel/channel.service'
import { IUpdateStatusesRequest } from '../../../../services/integration/IntegrationsRequest'
import channelTableStore from '../../../../store/channelTable.store'
import { EditIconButton } from '../../../../components/Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../../../../components/Buttons/CustomIconButton/CustomIconButton'

export const ChannelDate:FC<IChannelDate> = (props) => {
  const {
    initValue,
    id,
    update_id,
    onChangeUpdateId,
    setInitValue,
    readOnly = false,
    field,
    hiddenEdit = false,
    isManagerEdit,
    isNotValueTextHidden,
    marginTop,
    maxWidth
  } = props
  
  const [value, setValue] = useState<Dayjs | null>(initValue ? dayjs(initValue) : null)

  const [isEdit, setEdit] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const submit = (value:Dayjs) => {
    setLoading(true)

    const option:IUpdateStatusesRequest = {
      id,
      field,
      new_value:value.format('YYYY-MM-DDT00:00:00') + '.000Z',
      update_id,
      isManagerEdit:isManagerEdit || undefined
    }
    ChannelService.updateSimpleField(option)
      .then(res=>{

        channelTableStore.changeField({id, field, new_value:value.format('YYYY-MM-DDT00:00:00') + '.000Z', update_id:res.data.update_id})

        if(setInitValue){
          setInitValue(value.format('YYYY-MM-DDT00:00:00') + '.000Z')
        }

        if(onChangeUpdateId){
          onChangeUpdateId(res.data.update_id)
        }
        
        setLoading(false)
        setEdit(false)
      })
      .catch(err=>{
        setLoading(false)
      })

  }

  const closeEdit = () => {
    setValue(initValue ? dayjs(initValue) : null)
    setEdit(false)
  }

  const onKeyDownSend = (e:React.KeyboardEvent) => {
    if(e.code === "Enter" || e.code === "NumpadEnter"){
      if(value && value.isValid()){
        submit(value)
      }
    }
  }

  return (
    <Stack>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          {!initValue && <Typography sx={{whiteSpace:'nowrap'}}>{isNotValueTextHidden ? '—' : 'Выбрать дату'}</Typography>}
          {initValue && <Typography>{dayjs(initValue)?.format('DD.MM.YYYY')}</Typography>}
          {hiddenEdit === false &&
            <EditIconButton size='small' disabled={readOnly} onClick={()=>setEdit(true)}/>
          }
        </Stack>
      }
      {isEdit &&
        <Stack
          sx={{minWidth:190, mt:marginTop, maxWidth}} 
          direction={'row'} 
          alignItems={'center'} 
          spacing={0.5}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
            <DatePicker
              value={value}
              onChange={(newValue) => setValue(newValue)}
              sx={{width:'100%'}}
              slotProps={{textField:{size:'small', onKeyDown:onKeyDownSend, placeholder:'дд.мм.год', disabled:isLoading}}}
            />
          </LocalizationProvider>
          <Stack direction={'row'} alignItems={'center'}>
            <CustomIconButton
              size='small' 
              disabled={!value || !value.isValid() || isLoading} 
              onClick={value && value.isValid() ? ()=>submit(value) : undefined}
              showBackground
            >
              <Check fontSize='small'/>
            </CustomIconButton>
            <CustomIconButton size='small' onClick={closeEdit} disabled={isLoading}>
              <Close fontSize='small'/>
            </CustomIconButton>
          </Stack>
        </Stack>
      }
    </Stack>
  )
}
