import {FC, useState, useEffect} from 'react'
import { IPredictionField } from './IPredictionField'
import { Box, Stack, Typography } from '@mui/material'
import { InputEditField } from '../InputEditField/InputEditField'
import IntegrationsService from '../../../../../services/integration/integrations.service'
import { IUpdateStatusesRequest } from '../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import integrationTableStore from '../../../../../store/integrationTable.store'
import {priceTransormFormat} from "../../../../../shared/functions/functions";
import integrationPageStore from "../../../../../store/integrationPage.store";
import { EditIconButton } from '../../../../Buttons/EditIconButton/EditIconButton'

export const PredictionField:FC<IPredictionField> = (props) => {
  const {
    id, 
    update_id, 
    initValue, 
    field_code, 
    readOnly = false,
    onChangeUpdateId,
    symbol,
    showRemains
  } = props

  const [value, setValue] = useState<string>(initValue?.toString())

  const [isLoading, setLoading] = useState(false)
  const [isEdit, setEdit] = useState(false)

  const submit = (newValue:string) => {
    setLoading(true)

    const option:IUpdateStatusesRequest = {
      id,
      update_id,
      field:field_code,
      new_value:newValue
    }
    IntegrationsService.updatePredictions(option)
      .then( res=>{
        // console.log(res, 'update pridict field', field_code)
        integrationTableStore.updatePridiction(id, res.data?.update_id, +newValue, field_code)
        setValue(newValue)

          integrationPageStore.setAverageOrderSumm(res.data.averageCheck)
          integrationPageStore.setPredictionViews(res.data.prediction_views)
          integrationPageStore.setPredictionTraffic(res.data.prediction_traffic)
          integrationPageStore.setPredictionCpv(res.data.prediction_cpv)
          integrationPageStore.setPredictionCtr(res.data.prediction_ctr)
          integrationPageStore.setPredictionCr(res.data.prediction_cr)
          integrationPageStore.setPredictionOrders(res.data.prediction_orders)
          integrationPageStore.setPredictionRoi(res.data.prediction_roi)
        if(onChangeUpdateId){
          onChangeUpdateId(res.data.update_id)
        }

        setLoading(false)
        setEdit(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err update predicti', field_code)
          setLoading(false)
        }
      })
  }

  useEffect(()=>{
    setValue(initValue?.toString())
  }, [initValue])

  return (
    <Box>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'}>
          <Typography noWrap>{priceTransormFormat(value, false, showRemains, showRemains)} {symbol ? symbol : null}</Typography>
          {!readOnly &&
            <EditIconButton size='small' onClick={()=>setEdit(true)}/>
          }
        </Stack>
      }
      {isEdit && 
        <InputEditField 
          inputType='number' 
          initValue={value?.toString()} 
          loading={isLoading} 
          onClose={()=>setEdit(false)} 
          onSubmit={submit}
          placeholder='Введите прогноз'
        />
      }
    </Box>
  )
}
