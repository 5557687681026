import {FC, useState} from 'react'
import { ITheameEmptyField } from './ITheameEmptyField'
import { Stack } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import { TheameField } from '../TheameField/TheameField'
import { CustomIconButton } from '../../../../Buttons/CustomIconButton/CustomIconButton'

export const TheameEmptyField:FC<ITheameEmptyField> = (props) => {
  const {
    id,
    update_id,
    code,
    isActive,
    name,
    readOnly
  } = props

  const [isOpen, setOpen] = useState(false)

  return (
    <>
      {!isOpen
        ? <CustomIconButton disabled={readOnly} onClick={()=>setOpen(true)}>
            <Add/>
          </CustomIconButton>
        : <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <TheameField
              onClose={()=>setOpen(false)}
              {...props}
              minWidth={175}
            />
            <CustomIconButton size='small' onClick={()=>setOpen(false)}>
              <Close fontSize='small'/>
            </CustomIconButton>
          </Stack>
        }
    </>
  )
}
