import {useEffect, useState} from 'react'
import IntegrationsService from '../../../../../../services/integration/integrations.service'
import { AxiosError } from 'axios'
import { GoodsTable } from './ui/GoodsTable/GoodsTable'
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import rulesStore from '../../../../../../store/rules.store'
import { observer } from 'mobx-react-lite'
import { GoodsAddModal } from './ui/GoodsAddModal/GoodsAddModal'
import categoriesGoodsStore from '../../../../../../store/categoriesGoods.store'

export const GoodsCategoryTable = observer(() => {

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const [isShowAddModal, setShowAddModal] = useState(false)

  const getGoodsCategory = () => {
    setLoading(true)
    setError(null)

    IntegrationsService.getGoodsCategoryThresholds()
      .then(res=>{
        // console.log(res, 'get goods category')

        rulesStore.setGoodsCategories(res.data)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err goods category')
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  useEffect(()=>{
    getGoodsCategory()
  }, [])

  return (
    <>
      <Box
        sx={{
          overflow:'hidden', 
          width:{xs:'100%', md:'50%'}
        }}
      >
        {!isLoading && rulesStore.goodsCategories &&
          <Stack spacing={1}>
            <Typography variant='h6'>Таблица редактирования критериев ROI</Typography>
            <GoodsTable list={rulesStore.goodsCategories}/>
            {categoriesGoodsStore.categories?.length !== rulesStore.goodsCategories?.map(x=>x.goodsCategoryCode)?.length &&
              <Box>
                <Button
                  variant='contained'
                  startIcon={<Add fontSize='small'/>}
                  onClick={()=>setShowAddModal(true)}
                >
                  Добавить
                </Button>
              </Box>
            }
          </Stack>
        }
        {isLoading &&
          <Skeleton variant='rounded' sx={{height:600, width:'100%'}}/>
        }
      </Box>
      {isShowAddModal && rulesStore.goodsCategories &&
        <GoodsAddModal
          open={isShowAddModal}
          onClose={()=>setShowAddModal(false)}
          initCodes={rulesStore.goodsCategories?.map(x=>x.goodsCategoryCode)}
        />
      }
    </>
  )
})