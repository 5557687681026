import { FC } from 'react'
import { IGuaranteeField } from './IGuaranteeField'
import { WbTwilight } from '@mui/icons-material'

export const GuaranteeField:FC<IGuaranteeField> = (props) => {
  const {color} = props

  return (
    <WbTwilight sx={{color}}/>
  )
}
