import { IFilterSelectItem } from "../../../../../components/FilterSelectChip/IFilterSelectChip";
import { IFromToArray } from "../../../../../services/integration/IntegrationsRequest";
import { IProductSampleFilterQuery } from "../../../../../services/productSample/IProductSampleRequest";
import { IRequisteAccount } from "../../../../../services/references/IReferencesResponse";

export const getCitiesFilterItems = (citys:IRequisteAccount[]):IFilterSelectItem[] => {
  return citys.map(x=>{
    return {name:x.name, value:x.code}
  })
}

export const getFilterGoodsQuery = (searchQuery:URLSearchParams):IProductSampleFilterQuery => {

  const transormValueToArrayFromTo = (searchArr:string[], isDate?:boolean):IFromToArray<number | string> => {
    const array = !isDate ? searchArr.map(x=>+x) : searchArr

    const fromToArray:IFromToArray<number | string>  = {
      from:array[0],
      to:array[1]
    }
    return fromToArray
  }

  const option:IProductSampleFilterQuery | any = {
    integrationId:searchQuery.get('integrationId') ? JSON.parse(searchQuery.get('integrationId')!)?.map((x:IFilterSelectItem)=>x.value) : null,
    deliveryCost:searchQuery.get('deliveryCost') ? transormValueToArrayFromTo(searchQuery.get('deliveryCost')?.split(',')!) : null,
    hasRefund:searchQuery.get('hasRefund') ? searchQuery.get('hasRefund') === 'yes' ? true : false : null,
    isSampleRefund:searchQuery.get('isSampleRefund') ? searchQuery.get('isSampleRefund') === 'yes' ? true : false : null,
    productSamples:searchQuery.get('productSamples')?.split(',') || null,
    sendAt:searchQuery.get('sendAt') ? transormValueToArrayFromTo(searchQuery.get('sendAt')?.split(',')!, true) : null,
    sendFrom:searchQuery.get('sendFrom')?.split(',') || null,
    sendTo:searchQuery.get('sendTo') || null,
    userId:searchQuery.get('userId')?.split(',') || null,
  }

  const filterEmptyFieldOption:any = {}

  for (let key in option) {
    if(option[key] !== null){
      filterEmptyFieldOption[key] = option[key]
    }
  }

  return filterEmptyFieldOption
}