import { useState } from 'react'
import { LocalMall, Refresh, Traffic } from '@mui/icons-material'
import { Avatar, Box, CircularProgress, ClickAwayListener, Stack, Tooltip, Typography } from '@mui/material'
import { IReferencesStatsByAutoUpdatesResponse } from '../../../../services/references/IReferencesResponse'
import ReferencesService from '../../../../services/references/references.service'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import channelStore from '../../../../store/channel.store'
import { getDatePlusCustomTime } from '../../../../shared/functions/functions'
import {EmptyText} from "../../../EmptyText/EmptyText";
dayjs.extend(utc)

export const ParserInfoIcon = () => {

  const [isLoading, setLoading] = useState(false)

  const [stats, setStats] = useState<null | IReferencesStatsByAutoUpdatesResponse[]>(null)
  const [open, setOpen] = useState(false)

  const getRefreshInfoData = () => {
    setOpen(prev=>true)
    setLoading(true)
    setStats(prev=>null)

    ReferencesService.statsByAutoUpdates()
      .then(res=>{
        setStats(prev=>res.data)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoading(false)
        }
      })
  }

  const handleTooltipClose = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        enterTouchDelay={0}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={handleTooltipClose}
        open={open}
        title={
          <>
            {isLoading &&
              <Box>
                <Typography variant='h5' fontWeight={'bold'}>Автообновление данных</Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Typography variant='body1'>Идёт загрука...</Typography>
                  <CircularProgress color='inherit' sx={{maxHeight:30, minHeight:30, maxWidth:30, minWidth:30}}/>
                </Stack>
              </Box>
            }
            {!isLoading && stats &&
              <Box>
                <Typography variant='h5' fontWeight={'bold'} sx={{mb:0.5}}>Автообновление данных</Typography>
                <Stack spacing={1}>
                  <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    <Traffic sx={{color:'rgb(255, 185, 4)'}} fontSize='medium'/> 
                    <Typography>Трафик:</Typography>
                    <Typography fontWeight={'bold'}>
                      {stats?.find(x=>x?.code === 'traffic')?.updatedAt
                        ? getDatePlusCustomTime({value:stats?.find(x=>x?.code === 'traffic')?.updatedAt || '', format:'DD.MM.YYYY HH:mm'})
                        : <EmptyText/>
                      }
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    <LocalMall sx={{color:'rgb(255, 185, 4)'}} fontSize='medium'/>
                    <Typography>Продажи:</Typography>
                    <Typography fontWeight={'bold'}>
                      {stats?.find(x=>x?.code === 'orders')?.updatedAt
                        ? getDatePlusCustomTime({value:stats?.find(x=>x?.code === 'orders')?.updatedAt || '', format:'DD.MM.YYYY HH:mm'})
                        : <EmptyText/>
                      }
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    {channelStore?.channels &&
                      <Avatar src={channelStore?.channels?.telegram?.icon} alt='Телеграм' sx={{height:20, width:20}}/>
                    }
                    <Typography>Телеграм:</Typography>
                    <Typography fontWeight={'bold'}>
                      {stats?.find(x=>x?.code === 'telegramViews')?.updatedAt
                        ? getDatePlusCustomTime({value:stats?.find(x=>x?.code === 'telegramViews')?.updatedAt || '', format:'DD.MM.YYYY HH:mm'})
                        : <EmptyText/>
                      }
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    {channelStore?.channels &&
                      <Avatar src={channelStore?.channels?.youtube?.icon} alt='Ютуб' sx={{height:20, width:20}}/>
                    }
                    <Typography>Ютуб:</Typography>
                    <Typography fontWeight={'bold'}>
                      {stats?.find(x=>x?.code === 'youtubeViews')?.updatedAt
                        ? getDatePlusCustomTime({value:stats?.find(x=>x?.code === 'youtubeViews')?.updatedAt || '', format:'DD.MM.YYYY HH:mm'})
                        : <EmptyText/>
                      }
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            }
          </>
        }
        placement='right-start'
        slotProps={{
          tooltip:{
            sx:{background:'rgb(70, 81, 100)'}
          }
        }}
        onClick={!open ? getRefreshInfoData : undefined}
      >
        <Box
          sx={{
            display:'flex', 
            alignItems:'center', 
            justifyContent:'center',
            cursor:'pointer'
          }}
        >
          <Box 
            sx={{
              borderRadius:'100%', 
              background:'rgb(255, 185, 4)', 
              display:'flex', 
              alignItems:'center', 
              justifyContent:'center'
            }}
          >
            <Refresh fontSize='large'/>
          </Box>
        </Box>
      </Tooltip>
    </ClickAwayListener>
  )
}
