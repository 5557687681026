import { FC, useEffect, memo } from 'react'
import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { IFinancesOplata } from './IFinancesOplata'
import dayjs from 'dayjs'
import userStore from '../../../../store/user.store'
import { CustomSelect } from '../../../../components/CustomSelect/CustomSelect'
import { getManagersOnType } from '../../../DashboardPage/pages/MotivationPage/ui/FilterMotivation/shared/dataList'
import AllUsersStore from '../../../../store/AllUsers.store'

export const FinancesOplata:FC<IFinancesOplata> = memo((props) => {
  const {
    plannedPaidAt,
    releaseUserId,
    setReleaseUserId,
    setPlannedPaidAt,
    changeInitReleaseUserId,
    rightContent,
    isEditMode,
    disabled = false,
    underDateContent,
    oplataId
  } = props

  useEffect(()=>{
    if(userStore?.user?.id && changeInitReleaseUserId){
      changeInitReleaseUserId(userStore?.user?.id?.toString())
    }
  },[userStore.user])

  useEffect(()=>{
    if(setPlannedPaidAt){
      setPlannedPaidAt(dayjs()?.format('YYYY-MM-DDT00:00:00')+'.000Z')
    }
  },[])

  return (
    <AsideCardLayout headerTitle={`оплата ${oplataId ? `${oplataId}` : ''}`} rightContent={rightContent}>
      <Stack sx={{mt:1.5}}>
        <Typography>Дата создания оплаты: {dayjs(plannedPaidAt)?.format('DD.MM.YYYY')}</Typography>
        {underDateContent}
        <Stack 
          direction={{xs:'column', lg:'row'}} 
          alignItems={{xs:'start', lg:'center'}} 
          spacing={1} 
          sx={{mt:0.5}}
        >
          <Typography>Выпускающий менеджер:</Typography>
          {isEditMode &&
            <Box 
              sx={{minWidth:{xs:'100%', lg:'200px'}}}
            >
              {AllUsersStore?.users
                ? <CustomSelect
                    value={
                      getManagersOnType({managerType:'release', allUsers:AllUsersStore?.users})?.find(x=>x?.value === releaseUserId)
                        ? (releaseUserId || '')
                        : ''
                    }
                    onChange={e=>setReleaseUserId(e.target.value)}
                    content={getManagersOnType({managerType:'release', allUsers:AllUsersStore?.users})}
                    placeholder='Выберите менеджера'
                    sx={{minWidth:{xs:'100%', lg:'200px'}}}
                    disabled={disabled}
                  />
                : <Skeleton 
                    variant='rounded' 
                    sx={{minHeight:'45px', maxHeight:'45px', minWidth:{xs:'100%', lg:'200px'}, maxWidth:{xs:'100%', lg:'200px'}}}
                  />
              }
            </Box>
          }
          {!isEditMode && AllUsersStore?.users &&
            <Typography noWrap>
              {getManagersOnType({managerType:'release', allUsers:AllUsersStore?.users})?.find(x=>x?.value === releaseUserId)?.name}
            </Typography>
          }
        </Stack>
      </Stack>
    </AsideCardLayout>
  )
})