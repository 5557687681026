import { KeyboardArrowLeft } from '@mui/icons-material'
import { Box, Button, CircularProgress, Drawer, IconButton, Stack, Typography } from '@mui/material'
import {useEffect, useState} from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import userStore from '../../store/user.store'
import { ROUTES } from '../../shared/routes/routes'
import { IChannelCreateRequest } from '../../services/channel/IChannelRequest'
import createChannelStore from '../../store/createChannel.store'
import { CreateAsideMain } from './ui/CreateAsideMain/CreateAsideMain'
import ChannelService from '../../services/channel/channel.service'
import { AxiosError } from 'axios'
import channelTableStore from '../../store/channelTable.store'
import { checkUserPermission } from '../../shared/rules/permission'
import { ACCESS_RULES } from '../../shared/rules/rules'
import { NotificationAlerts } from '../../components/NotificationAlerts/NotificationAlerts'
import { CreateAsideComments } from './ui/CreateAsideComments/CreateAsideComments'
import { CHANNEL_CARD_OPEN } from '../../shared/constants/searchParams'
import { CustomButton } from '../../components/Buttons/CustomButton/CustomButton'

export const CreateChannelPage = observer(() => {

  const navigate = useNavigate()

  const [isOpen, setOpen] = useState(false)

  const [isSaveLoading, setSaveLoading] = useState(false)
  const [isErrorAlert, setErrorAlert] = useState(false)

  const saveChannel = () => {
    setSaveLoading(true)
    setErrorAlert(false)

    createChannelStore.setSaveError(null)

    const option:IChannelCreateRequest = {
      name:createChannelStore.name,
      contact:createChannelStore.contact,
      agency:createChannelStore.agency, 
      type:createChannelStore.type,
      theme:createChannelStore.theme,
      foundById:createChannelStore.foundBy ? createChannelStore.foundBy : userStore.user?.id || null,
      dateFound:createChannelStore.dateFound || '',
      dateGiven:createChannelStore.dateGiven,

      advertiser:createChannelStore.advertiser,
      searchSystem:createChannelStore.searchSystem,

      link:createChannelStore.link,
      initialPrice:createChannelStore.initialPrice,
      commission:createChannelStore.commission ? createChannelStore.commission : 0,
      viewsPredict:createChannelStore.viewsPredict,
      audience:createChannelStore.audience ? createChannelStore.audience : null,
      ctrPredict:createChannelStore.ctrPredict,
      crSite:createChannelStore.crSite,

      man:createChannelStore.editAudienceData?.man ? Number(createChannelStore.editAudienceData?.man) : null,
      woman:createChannelStore.editAudienceData?.woman ? Number(createChannelStore.editAudienceData?.woman) : null,
      targetAudience:createChannelStore.targetAudience,
      ["13-17"]:createChannelStore.editAudienceData?.["13-17"] ? Number(createChannelStore.editAudienceData?.["13-17"]) : null,
      ["18-24"]:createChannelStore.editAudienceData?.["18-24"] ? Number(createChannelStore.editAudienceData?.["18-24"]) : null,
      ["25-34"]:createChannelStore.editAudienceData?.["25-34"] ? Number(createChannelStore.editAudienceData?.["25-34"]) : null,
      ["35-44"]:createChannelStore.editAudienceData?.["35-44"] ? Number(createChannelStore.editAudienceData?.["35-44"]) : null,
      ["45-54"]:createChannelStore.editAudienceData?.["45-54"] ? Number(createChannelStore.editAudienceData?.["45-54"]) : null,
      ["55-64"]:createChannelStore.editAudienceData?.["55-64"] ? Number(createChannelStore.editAudienceData?.["55-64"]) : null,
      ["65_plus"]:createChannelStore.editAudienceData?.["65_plus"] ? Number(createChannelStore.editAudienceData?.["65_plus"]) : null,
      ru:createChannelStore.editAudienceData?.ru ? Number(createChannelStore.editAudienceData?.ru) : null,
      ua:createChannelStore.editAudienceData?.ua ? Number(createChannelStore.editAudienceData?.ua) : null,
      kz:createChannelStore.editAudienceData?.kz ? Number(createChannelStore.editAudienceData?.kz) : null,
      by:createChannelStore.editAudienceData?.by ? Number(createChannelStore.editAudienceData?.by) : null,
      subs:createChannelStore.editAudienceData?.subs ? Number(createChannelStore.editAudienceData?.subs) : null,
      notSubs:createChannelStore.editAudienceData?.notSubs ? Number(createChannelStore.editAudienceData?.notSubs) : null,
      fromPhone:createChannelStore.editAudienceData?.fromPhone ? Number(createChannelStore.editAudienceData?.fromPhone) : null,
      fromPC:createChannelStore.editAudienceData?.fromPC ? Number(createChannelStore.editAudienceData?.fromPC) : null,

      accounts:createChannelStore.accounts.length === 0 ? null : createChannelStore.accounts,
      posted_comments: createChannelStore.posted_comments.length === 0 ? null : createChannelStore.posted_comments,
    }
    //console.log(option)
    ChannelService.createChannel(option)
      .then(res=>{
        // console.log(res, 'res create channel')
        channelTableStore.addChannel(res.data)
        navigate('/' + userStore.currentCompany + '/' + ROUTES.CHANNELS + `?${CHANNEL_CARD_OPEN}=${res.data.id}`)
        createChannelStore.clearStore()
        setSaveLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          // console.log(err, 'create channel err')
          setErrorAlert(true)

          if(err.response?.status !== 500){
            createChannelStore.setSaveError(err?.response?.data?.message)
          }

          setSaveLoading(false)
        }
      })
  }

  const closeModal = () => {
    setOpen(false)
    createChannelStore.clearStore()
    setTimeout(() => navigate('/' + userStore.currentCompany + '/' + ROUTES.CHANNELS), 200)
  }

  useEffect(()=>{
    setOpen(true)
  }, [])

  return (
    <Drawer
      anchor={"right"} 
      open={isOpen} 
      onClose={closeModal} 
      sx={{position:"relative"}}
      PaperProps={{sx:{backgroundColor: "#F2F3F4"}}}
      slotProps={{backdrop:{sx:{left:70}}}}
    >
      {userStore.user && checkUserPermission({rules:ACCESS_RULES.create_channels, userRules:userStore.user.rights as any})
        ? <form onSubmit={e=>e.preventDefault()} style={{height:'inherit'}}>
            <Box
              sx={{
                p:2,
                pt:1,
                width:{xl:"65vw", lg:'calc(75vw - 70px - 100px)', md:'calc(75vw - 70px - 50px)', sm:'calc(75vw - 70px)', xs:'100vw'},
                height:'100%',
                display:'flex',
                flexDirection:'column',
              }}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{mb:1}}>
                <IconButton onClick={closeModal} sx={{ml:-1}}>
                  <KeyboardArrowLeft fontSize="large" />
                </IconButton>
                <Typography variant="h5">Создание Канала</Typography>
              </Stack>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "100%", md: "1fr", lg: "1fr" },
                  columnGap: 2,
                  rowGap: 2,
                  mb: 1,
                  pb: 2,
                  width: {md: '50%'}
                }}
              >
                <Box>
                  <CreateAsideMain/>
                  <Box sx={{height:'fit-content',mt:2}}>
                    <CreateAsideComments/>
                  </Box>
                </Box>
                <Box sx={{display:'flex',gap:2,justifyContent:'center'}}>
                  <Button variant='outlined' size='large' onClick={closeModal} disabled={isSaveLoading} sx={{background:'#fff'}}>Отмена</Button>
                  <CustomButton
                    variant='contained'
                    size='large'
                    onClick={saveChannel}
                    disabled={isSaveLoading}
                    endIcon={isSaveLoading && <CircularProgress sx={{maxHeight:20, maxWidth:20}} />}
                  >
                    Создать
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </form>
        : <Box sx={{p:2}}>
            <Typography variant='h5' color={'action'} align='center'>
              У вас недостаточно прав для просмотра данной страницы
            </Typography>
            <Button fullWidth variant='outlined' onClick={closeModal} sx={{mt:2}}>Вернуться назад</Button>
          </Box>
      }
      {isErrorAlert &&
        <NotificationAlerts
          error={{text:'Что-то пошло не так...', open:isErrorAlert ? true : false, onClose:()=>setErrorAlert(false)}}
        />
      }
    </Drawer>
  )
})