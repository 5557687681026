import { FC, useState, useEffect } from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, List, Stack, TextField, Typography } from '@mui/material'
import { Cancel, Clear, Search } from '@mui/icons-material'
import { IIntegrationsTableSettings } from '../models/IIntegrationsTableSettings'
import { ISettingsSelectedGroup } from '../models/ISettingsSelectedGroup'
import { TableSettingsItem } from './TableSettingsItem'
import { TableSettingsGroup } from './TableSettingsGroup'
import { AlertModal } from '../../AlertModal/AlertModal'
import { observer } from 'mobx-react-lite'

export const IntegrationTableSettings:FC<IIntegrationsTableSettings> = observer((props) => {
  const {
    open,
    onClose,
    onSubmit,
    onReset,
    loading = false,
    deleteConfirmItem,
    arrayConfirmItems,
    arraySelectItems,
    onDragStart,
    onDragOver,
    onDrop,
    addToConfirmList,
    removeConfirmList,
    setSelectedChecked,
    currentArray
  } = props

  const [isShowAlertReset, setShowAlertReset] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const [selectedGropCell, setSelectedGropCell] = useState<ISettingsSelectedGroup[]>(arraySelectItems)

  const handelSearchItem = (value:string) => {
    const searchArray:ISettingsSelectedGroup[] = [...arraySelectItems]

    const newItems:any[] = searchArray.map(item=>{

      return {
        ...item,
        fields:item.fields.filter(field=>{
          if(field.name.toLowerCase().includes(value.toLowerCase())) {
              return {...field}
            }
          })
      }

    }
    ).filter(x=>x!==undefined)

    setSelectedGropCell(newItems)
  }

  const resetSettings = () => {
    onReset()
    setShowAlertReset(false)
  }

  const saveSettings = () => {
    onSubmit()
  }

  const closeModal = () => {
    resetSettings()
    onClose()
  }


  useEffect(()=>{
    if(searchValue.trim()===''){
      setSelectedGropCell(arraySelectItems)
    }

    const delay = setTimeout(()=>handelSearchItem(searchValue), 400)
    return ()=>clearTimeout(delay)

  },[searchValue])

  useEffect(()=>{
    setSelectedGropCell(arraySelectItems)
  },[arraySelectItems])

  return (
    <>
      <Dialog
        open={open}
        onClose={!loading ? closeModal : undefined}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle sx={{display:'flex', alignItems:'center', justifyContent:'space-between', pr:1}}>
          <Typography fontSize={'1.07rem'} fontWeight={'bold'} sx={{display:{xs:'none', sm:'inline-block'}}}>Колонки в таблице</Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{width:{xs:'100%', sm:'auto'}}}>
            <TextField
              value={searchValue}
              onChange={e=>setSearchValue(e.target.value)}
              size='small'
              placeholder='Быстрый поиск...'
              sx={{width:{xs:'100%', sm:'200px'}}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue.trim() !=='' ? 
                      <IconButton size='small' onClick={()=>setSearchValue('')}>
                        <Clear fontSize='small'/>
                      </IconButton>
                    : null}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize='small' />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton disabled={loading} onClick={!loading ? closeModal : undefined}>
              <Cancel/>
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider/>
        <DialogContent sx={{display:'grid', gridTemplateColumns: {xs:'1fr', sm:'min-content min-content 1fr'}, p:0, columnGap:{xs:0, sm:1}}}>
          <List dense sx={{maxWidth:'200px', minWidth:'200px', mt:0.5, pl:1, display:{xs:'none', sm:'block'}}}>
            {arrayConfirmItems.map(item=>
              <TableSettingsItem
                key={item.code}
                disabled={loading}
                {...item}
                isConfirmItem
                isDraggable
                isShowDelete={arrayConfirmItems.length > 1}
                onDelete={()=>deleteConfirmItem(item.code)}
                onDragStart={()=>onDragStart({code:item.code, ordering:item.ordering})}
                onDragOver={(e)=>onDragOver(e)}
                onDrop={(e)=>onDrop(e, {code:item.code, ordering:item.ordering})}
              />)
            }
          </List>
          <Divider orientation='vertical' sx={{display:{xs:'none', sm:'block', borderStyle:'dashed'}}}/>
          <List dense sx={{pl:{xs:1, sm:0}, pt:'2px'}}>
            {selectedGropCell.map((group, ind)=>{
              if(group.fields.length > 0){
                return <TableSettingsGroup
                    key={group.group}
                    {...group}
                    addToConfirmList={addToConfirmList}
                    removeConfirmList={removeConfirmList}
                    setSelectedChecked={setSelectedChecked}
                    loading={loading}
                    currentGroup={currentArray}
                    showDivider={ind !== selectedGropCell?.length-1}
                  />
                }
              }
            )}
            {selectedGropCell.map(x=>x.fields.length).reduce((prev,next)=>prev+next) === 0 &&
              <Typography variant='h5' sx={{display:'flex', alignItems:'center', justifyContent:'center', flexWrap:'wrap'}}>
                По запросу <span style={{margin:'0px 4px'}}>"<b>{searchValue}</b>"</span> ничего не найдено
              </Typography>
            }
          </List>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button variant='outlined' disabled={loading} onClick={()=>setShowAlertReset(true)}>сбросить</Button>
          <Button
            variant='contained'
            disabled={loading}
            onClick={saveSettings}
            endIcon={loading && <CircularProgress sx={{maxHeight:20, maxWidth:20}} />}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      {isShowAlertReset &&
        <AlertModal
          open={isShowAlertReset}
          title='Подтвердите действие'
          subtext='Вы действительно хотите сбросить настройки списка?'
          onClose={()=>setShowAlertReset(false)}
          onSubmit={resetSettings}
          buttonCancelName='Отмена'
          buttonSubmitName='Ок'
        />
      }
    </>
  )
})