import {FC, useState} from 'react'
import { IGuranteeCheckbox } from './IGuranteeCheckbox'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { IIntegrationGaranteeRequest } from '../../../../../../services/integration/IntegrationsRequest'
import IntegrationsService from '../../../../../../services/integration/integrations.service'
import integrationPageStore from '../../../../../../store/integrationPage.store'
import { AxiosError } from 'axios'
import integrationTableStore from '../../../../../../store/integrationTable.store'

export const GuranteeCheckbox:FC<IGuranteeCheckbox> = (props) => {
  const {
    checked,
    readOnly,
    integrationId,
    id,
    setUpdateId,
    update_id
  } = props

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const change = (value:boolean) => {
    setLoading(true)
    setError(null)

    const options: IIntegrationGaranteeRequest = {
      integrationId:integrationId,
      id:id ? id : undefined,
      repeat:value,
      update_id
    }
    IntegrationsService.setGarant(options)
      .then(res=>{
        integrationPageStore.setGuarantee(res.data?.guarantee || null)
        integrationTableStore.changeGurange(integrationId, res.data?.guarantee || null, res.data.update_id)
        setUpdateId(res.data?.update_id)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  return (
    <Box sx={{mb:-1, mt:-1}}>
      <FormControlLabel
        disabled={readOnly || isLoading}
        control={
          <Checkbox
            color={error ? 'error' : 'primary'}
            checked={checked}
            onChange={(e)=>change(e.target?.checked)}
          />
        } 
        label="Перевыпуск по гаранту"
        labelPlacement='start'
        sx={{ml:0}}
      />
    </Box>
  )
}
