import {FC, useState, useEffect} from 'react'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { IAsideCardGoods } from './IAsideCardGoods'
import { Button, Stack } from '@mui/material'
import { TableDefault } from '../../../../components/Table/TableDefault'
import { TableItem } from '../../../../components/Table/ui/TableItem'
import { IStrategyField } from '../../../../strategy/interface/IStrategyField'
import { Add } from '@mui/icons-material'
import { GoodsModalList } from '../../../../components/Table/ui/Fields/GoodsField/ui/GoodsModalList/GoodsModalList'
import goodsStore from '../../../../store/goods.store'
import { IGoodItem } from '../../../../components/Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem'
import { IInsertGoodsRequest } from '../../../../services/integration/IntegrationsRequest'
import IntegrationsService from '../../../../services/integration/integrations.service'
import { AxiosError, AxiosResponse } from 'axios'
import integrationTableStore from '../../../../store/integrationTable.store'
import integrationPageStore from '../../../../store/integrationPage.store'
import { CustomButton } from '../../../../components/Buttons/CustomButton/CustomButton'

export const AsideCardGoods:FC<IAsideCardGoods> = (props) => {
  const {
    id,
    goods,
    readOnly = false,
    setLink,
    setGoods,
    setUpdateId,
    setGoodsCategoryCode,
    editGoodsPredictions,
    forFirstIntegrationWithChannel
  } = props

  const [currentPageGoods, setCurrentPageGoods] = useState<IGoodItem[]>(goods)
  const [totalGoods, setTotalGoods] = useState<IGoodItem[]>([])
  const [isShowAdd, setShowAdd] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isLoadingRemove, setLoadingRemove] = useState(false)

  const allNameGoods = [...currentPageGoods].map(item=>item.name)

  const tableRow:IStrategyField[] = [
    {code:'name', title:'Название', immutable:false, isSimple:true},
    {code:'category', title:'Категория', immutable:false, isSimple:false, isGoodCategory:true},
  ]

  const hadelOpen = () => {
    setShowAdd(true)
  }

  const hadelClose = () => {
    setTotalGoods(prev=>{

      if(goodsStore?.goods){
        return [...goodsStore?.goods].map(item=>{

          if(allNameGoods.find(name=>item.name === name)) {
            return item
          } else return {...item, isActive:false}
      
        })
      } else return prev

    })
    
    setShowAdd(false)
  }

  const changeGoods = (article:string, value:boolean) => {
    setTotalGoods(prev=>prev.map(item=>item.article === article ? {...item, isActive:value} : item))
  }

  const submit = () => {
    setLoading(true)
    
    const option:IInsertGoodsRequest = {
      id,
      items:totalGoods.filter(item=>item.isActive)
    }
    IntegrationsService.insertGoods(option)
      .then((res:AxiosResponse<any>)=>{
        // console.log(res, 'res goods update card')
        setLink(res.data.link)
        integrationTableStore.insertGoods(id, totalGoods.filter(item=>item.isActive), res.data?.goodsCategoryCode, res.data?.update_id, res.data.goodsCategory)
        integrationPageStore.setIntegrationTargetAudience(res.data?.targetAudience)
        
        setCurrentPageGoods(totalGoods.filter(item=>item.isActive))
          if (editGoodsPredictions)
              editGoodsPredictions(res.data)
        if(setUpdateId) {
          setUpdateId(res.data.update_id)
        }

        if(setGoods){
          setGoods(totalGoods.filter(item=>item.isActive))
        }

        if(setGoodsCategoryCode) {
          setGoodsCategoryCode(res.data?.goodsCategoryCode)
        }

        hadelClose()
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          // console.log(err, 'err goods update')
          setLoading(false)
        }
      })
  }

  const deleteItem = (props:any) => {
    setLoadingRemove(true)

    const currentArray = currentPageGoods.filter(item=>item.article !== props.article)

    const option:IInsertGoodsRequest = {
      id,
      items:currentArray
    }
    IntegrationsService.insertGoods(option)
      .then((res:AxiosResponse<any>)=>{
        // console.log(res, 'res goods update remove')
        setLink(res.data.link)
        integrationTableStore.insertGoods(id, currentArray, res.data?.goodsCategoryCode, res.data?.update_id, res.data.goodsCategory)
        integrationPageStore.setIntegrationTargetAudience(res.data?.targetAudience)

        if (editGoodsPredictions)
            editGoodsPredictions(res.data)

        setCurrentPageGoods(prev=>prev.filter(item=>item.article !== props.article))

        if(setUpdateId) {
          setUpdateId(res.data.update_id)
        }

        if(setGoods){
          setGoods(currentArray)
        }

        if(setGoodsCategoryCode) {
          setGoodsCategoryCode(res.data?.goodsCategoryCode)
        }

        setLoadingRemove(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          // console.log(err, 'err goods update')
          setLoadingRemove(false)
        }
      })
  }
  
  useEffect(()=>{

    setTotalGoods(prev=>{

      if(goodsStore?.goods){

        return [...goodsStore?.goods].map(item=>{

          if(allNameGoods.find(name=>item.name === name)) {
            return item
          } else return {...item, isActive:false}
      
        })

      } else return prev
      
    })
    
  },[currentPageGoods])

  useEffect(()=>{
    setCurrentPageGoods(goods)
  },[goods])

  return (
    <>
      <AsideCardLayout
        headerTitle='товары'
        sx={{maxWidth:{xs:'calc(100vw - 32px)', sm:'100%'}}}
        rightContent={!readOnly &&
          <CustomButton variant='outlined' endIcon={<Add/>} onClick={hadelOpen}>
            Выбрать
          </CustomButton>
        }
      >
        <Stack spacing={1} sx={{mt:1, overflow:'auto', maxHeight:'307px'}}>
          {currentPageGoods.length > 0 &&
            <TableDefault
              tableRow={tableRow}
              showDeleteIcon={readOnly ? false : true}
              showBorderRadius={false}
              maxWidth='100%'
            >
              {currentPageGoods.map(item=>
                <TableItem
                  key={item.article}
                  {...item} 
                  cells={tableRow} 
                  showDeleteIcon={readOnly ? false : true} 
                  onDeleteItem={deleteItem} readonly={readOnly}
                  deleteDisabled={isLoadingRemove}
                />)
              }
            </TableDefault>
          }
        </Stack>
      </AsideCardLayout>
      {isShowAdd && 
        <GoodsModalList
          open={isShowAdd}
          onClose={hadelClose}
          id={id}
          goods={totalGoods}
          changeGoods={changeGoods}
          onSubmit={submit}
          loading={isLoading}
        />
      }
    </>
  )
}
