import {FC, Fragment} from 'react'
import { IMotivationTableKpiItem } from './IMotivationTableKpiItem'
import { Divider, Stack, TableCell, TableRow, Typography } from '@mui/material'
import FixCell from '../../../FixCell/FixCell'
import { priceTransormFormat } from '../../../../../../shared/functions/functions'
import { AccordionWrapper } from '../../../../../AccordionWrapper/AccordionWrapper'

export const MotivationTableKpiItem:FC<IMotivationTableKpiItem> = (props) => {
  const {
    name, 
    value, 
    subContent,
    userId,
    readOnly,
    fontWeight,
    isEdit,
    isAccordion,
    lastTextAccordion,
    type,
    onChangeCell,
  } = props

  return (
    <>
      <TableRow>
        <TableCell sx={{p: isAccordion ? 0 : 1}} colSpan={isAccordion ? 2 : undefined}>
          {isAccordion !== true &&
            <Typography noWrap sx={{fontWeight: fontWeight}}>{name}</Typography>
          }
          {isAccordion &&
            <AccordionWrapper
              title={name}
              leftText={lastTextAccordion}
              showDivider={false}
              sxPropsLeftText={{mr: 1, fontWeight: 'normal'}}
              sxAccordionDetails={{pb: 0, pt: 0}}
              sxPropsText={{fontWeight: 'normal'}}
            >
              {subContent &&
                subContent?.map((sub, i) =>
                  <Fragment key={sub.name}>
                    <Divider/>
                    <Stack 
                      direction={'row'} 
                      alignItems={'center'} 
                      justifyContent={'space-between'}
                      sx={{p: 1, pl: 4}}
                    >
                      <Typography>{sub.name}</Typography>
                      <Typography>{sub.value ? priceTransormFormat(sub.value, true) : '—'}</Typography>
                    </Stack>
                  </Fragment>
                )
              }
            </AccordionWrapper>
          }
        </TableCell>
        {isAccordion !== true &&
          <>
            {isEdit ? (
              <FixCell
                name={name}
                userId={userId}
                initValue={value}
                readOnly={readOnly}
                type={type}
                onChangeCell={onChangeCell}
              />
            ) : (
              <TableCell sx={{p: 1}}>
                <Typography sx={{fontWeight: fontWeight, float:'right'}}>{priceTransormFormat(value, true)}</Typography>
              </TableCell>
            )}
          </>
        }
      </TableRow>
    </>
  )
}
