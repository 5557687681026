import {FC} from 'react'
import { IAsideSelectManager } from './IAsideSelectManager'
import { MenuItem, Select, SelectChangeEvent, Skeleton, Stack, Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'
import AllUsersStore from '../../../../store/AllUsers.store'
import { IFilterSelectItem } from '../../../../components/FilterSelectChip/IFilterSelectChip'
import { getManagerFilterItems } from '../../../../components/IntegrationTable/shared/dataList'
import {RemoveItemButton} from "../../../../components/RemoveItemButton/RemoveItemButton";

export const AsideSelectManager:FC<IAsideSelectManager> = observer((props) => {
  const {
    initValue, 
    onChange, 
    isLoading, 
    isError,
    readOnly = false,
    onDelete,
    minWidth,
    maxWidth,
    width
  } = props

  const manager:IFilterSelectItem[] | undefined = AllUsersStore?.users ? getManagerFilterItems(AllUsersStore?.users) : undefined

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value)
  }
  
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={0.5} minWidth={minWidth} width={width} maxWidth={maxWidth}>
      {manager
        ? <Select
            value={initValue ? initValue : ''}
            onChange={handleChange}
            disabled={isLoading || readOnly}
            fullWidth
            size='small'
            sx={{minWidth:'100px'}}
          >
            {manager.map(item=>
              <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)
            }
          </Select>
        : <Skeleton variant='rounded' sx={{minHeight:40, width:'100%'}}/>
      }
      {manager &&
        <Tooltip title='Удалить'>
          <RemoveItemButton isDisableColor={isLoading|| readOnly} onClick={onDelete} disabled={isLoading || readOnly}/>
        </Tooltip>
      }
    </Stack>
  )
})