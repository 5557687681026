import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ISocialType } from './ISocialType'
import channelStore from '../../../../../store/channel.store'
import { Avatar, Stack, Typography } from '@mui/material'
import { VideoCameraFrontOutlined } from '@mui/icons-material'

export const SocialType:FC<ISocialType> = observer((props) => {
  const {
    code, 
    text, 
    sxProps,
    showVideoReviewIcon
  } = props

  const currentIcon = channelStore?.channels![code]

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'} sx={sxProps}>
      {currentIcon && <Avatar src={currentIcon?.icon} alt={code} sx={{height:20, width:20}}/>}
      {showVideoReviewIcon && <VideoCameraFrontOutlined sx={{mr:0.5}}/>}
      {text ? <Typography noWrap>{text}</Typography> : null}
    </Stack>
  )
})