import {FC} from 'react'
import { IGoodsTableItem } from './IGoodsTableItem'
import { Avatar, Skeleton, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import categoriesGoodsStore from '../../../../../../../../store/categoriesGoods.store'
import { IGoodCategoriesItem } from '../../../../../../../../services/references/IReferencesResponse'
import { GoodsTableItemField } from './ui/GoodsTableItemField/GoodsTableItemField'

export const GoodsTableItem:FC<IGoodsTableItem> = observer((props) => {
  const {goodsCategoryCode, value} = props

  const currentCategoryItem:IGoodCategoriesItem | undefined = categoriesGoodsStore.categories?.find(item=>item?.code === goodsCategoryCode)

  return (
    <TableRow>
      <TableCell sx={{p:1}}>
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          {currentCategoryItem
            ? <>
                {currentCategoryItem?.iconUrl ? <Avatar src={currentCategoryItem?.iconUrl} alt={currentCategoryItem?.name}/> : null}
              </>
            : <Skeleton variant='circular' sx={{height:40, width:40}}/>
          }
          {currentCategoryItem
            ? <Typography>{currentCategoryItem?.name}</Typography>
            : <Skeleton variant='rounded' sx={{height:40, width:120}}/>
          }
        </Stack>
      </TableCell>
      <TableCell align="right">
        <GoodsTableItemField goodsCode={goodsCategoryCode} value={value}/>
      </TableCell>
    </TableRow>
  )
})