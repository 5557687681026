import {useState, FC} from 'react'
import { Button } from '@mui/material'
import { AlertModal } from '../../../../../../../../components/AlertModal/AlertModal'
import { Delete } from '@mui/icons-material'
import {DeleteButtonProps} from "./IPaymentDeleteButton";


export const DeleteButton:FC<DeleteButtonProps<any>> = (props) => {
  const {
    onDelete,
    confirmationTitle,
    subtext,
    deleteButtonLabel,
    cancelButtonLabel,
    onDeleteArgs = [],
    isLoading
  } = props

  const [isShowAlert, setShowAlert] = useState(false)
  const handleDelete = () => {
    onDelete(...onDeleteArgs);
    setShowAlert(false);
  };
  return (
    <>
      <Button
        size='small' 
        variant='text'
        sx={{
          color:'rgba(0, 0, 0, 0.54)',
          '&:hover': {
            backgroundColor: '#FFDBDB',
            color: '#FF5252',
          },
          '&:active': {
            backgroundColor: '#FFC7C7',
            color:'#FF5252'
          }
        }}
        endIcon={<Delete/>}
        onClick={()=>setShowAlert(true)}
      >
        {deleteButtonLabel}
      </Button>
      {isShowAlert &&
        <AlertModal
          title={confirmationTitle}
          subtext={subtext}
          open={isShowAlert}
          onClose={() => setShowAlert(false)}
          colorSubmit="error"
          onSubmit={handleDelete}
          buttonCancelName={cancelButtonLabel}
          buttonSubmitName={deleteButtonLabel}
          loading={isLoading}
        />
      }
    </>
  )
}
