import { useState, useEffect, Suspense, Fragment } from 'react'
import { Box, Typography } from '@mui/material'
import { LeftPanel } from '../LeftPanel/LeftPanel'
import { observer } from 'mobx-react-lite'
import LayoutService from '../../services/layout/layout.service'
import userStore from '../../store/user.store'
import { AxiosError } from 'axios'
import { Route, Routes, } from 'react-router-dom'
import { homePageRoutes } from '../../shared/routes/routes'
import integrationTableStore from '../../store/integrationTable.store'
import { ISettingsSelected } from '../IntegrationTable/models/ISettingsSelected'
import statusesStore from '../../store/statuses.store'
import channelStore from '../../store/channel.store'
import channelTableStore from '../../store/channelTable.store'
import companyUsersStore from '../../store/companyUsers.store'
import { checkUserPermission } from '../../shared/rules/permission'
import { PageNotFound } from '../../pages/PageNotFound/PageNotFound'
import { NotRightPage } from '../../pages/NotRightPage/NotRightPage'
import channelTheame from '../../store/channelTheame'
import goodsStore from '../../store/goods.store'
import requisiteStore from '../../store/requisite.store'
import AllUsersStore from '../../store/AllUsers.store'
import categoryStore from '../../store/category.store'
import paymentsTableStore from '../../store/paymentsTable.store'
import releasePlacementsStore from '../../store/releasePlacements.store'
import allTzStore from '../../store/allTz.store'
import searchSystemStore from '../../store/searchSystem.store'
import advertisersStore from '../../store/advertisers.store'
import categoriesGoodsStore from '../../store/categoriesGoods.store'
import { LazyPageLoading } from '../LazyPageLoading/LazyPageLoading'
import tzTableStore from '../../store/tzTable.store'
import contrActorTableStore from "../../store/contrActorTable.store";
import selectsDataStore from '../../store/selectsData.store'
import dashboardStore from '../../store/dashboard.store'
import ordTextStore from '../../store/ordText.store'
import goodsTableStore from '../../store/goodsTable.store'

import dayjs from 'dayjs'
import updateLocale from "dayjs/plugin/updateLocale"
dayjs.extend(updateLocale)
dayjs.updateLocale("en", {
  weekStart: 1
})

export const Layout = observer(() => {

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const getUserData = async () => {
    setLoading(true)
    setError(null)

    try {
      const res = await LayoutService.getLayoutData()
      
      goodsStore.getGoodsItems()
      requisiteStore.getRequisiteInfo()
      AllUsersStore.getAllUsers()
      categoryStore.setCategory(res.data.integrationCategories)
      releasePlacementsStore.getReleasesPlacement()
      allTzStore.getAllUsers()
      searchSystemStore.getSearchSystems()
      advertisersStore.getAdvertisers()
      categoriesGoodsStore.getAllCategoriesGoods()
      selectsDataStore.getPaymentType()
      ordTextStore.getOrdTexts()
      selectsDataStore.getChannelSizes()
      selectsDataStore.getSourceCities()
      ordTextStore.getOrdEridTexts()
      //contrActorPageStore.getAllUsers()
      // console.log(res, 'get data layout')

      companyUsersStore.setUsers(res.data.company_members)

      channelStore.setChannels(res.data.channelTypes)
      statusesStore.setStatuses(res.data.statuses.integration)
      statusesStore.setChannel(res.data.statuses.channel)
      statusesStore.setStatusPayments(res.data.statuses.payment)
      statusesStore.setStatusAgreement(res.data.statuses.agreement)
      statusesStore.setStatusPay(res.data.statuses.pay)
      channelTheame.setTheame(res.data.channelThemes)
      
      userStore.setUser(res.data.user)
      userStore.setCurrentCompany(res.data.user.company)

      //подгрузка фильтров, актиные/неактивные (скорее всего)
      integrationTableStore.setSelectedList(res.data.integrationsFilter)
      integrationTableStore.setInitSettingsSelect(res.data.integrationsFilter)

      channelTableStore.setSelectedList(res.data.channelsFilter)
      channelTableStore.setInitSettingsSelect(res.data.channelsFilter)

      paymentsTableStore.setSelectedList(res.data.paymentsFilter)
      paymentsTableStore.setInitSettingsSelect(res.data.paymentsFilter)

      tzTableStore.setSelectedList(res.data.tzFilter)
      tzTableStore.setInitSettingsSelect(res.data.tzFilter)

      contrActorTableStore.setSelectedList(res.data.counteragentFilter)
      contrActorTableStore.setInitSettingsSelect(res.data.counteragentFilter)
      // 
      const totalArr:ISettingsSelected[] = []
      //получение филдов сделать по аналогии
      for(let i = 0; i < res.data.integrationsFilter.length; i++){
        totalArr.push(...res.data.integrationsFilter[i].fields)
      }
            
      integrationTableStore.setAllFilter(totalArr.map(item=>item.code))
      // 
      const totalArrChannel:ISettingsSelected[] = []

      for(let i = 0; i < res.data.channelsFilter.length; i++){
        totalArrChannel.push(...res.data.channelsFilter[i].fields)
      }

      channelTableStore.setAllFilter(totalArrChannel.map(item=>item.code))
      // 
      const totalPaymentsArr:ISettingsSelected[] = []

      for(let i = 0; i < res.data.paymentsFilter.length; i++){
        totalPaymentsArr.push(...res.data.paymentsFilter[i].fields)
      }
            
      paymentsTableStore.setAllFilter(totalPaymentsArr.map(item=>item.code))
      // повторить для контрагентов (113-119)
      const totalTzArr:ISettingsSelected[] = []

      for(let i = 0; i < res.data.tzFilter.length; i++){
        totalTzArr.push(...res.data.tzFilter[i].fields)
      }

      tzTableStore.setAllFilter(totalTzArr.map(item=>item.code))
      // отсортировать

      const totalContrActorArr:ISettingsSelected[] = []

      for(let i = 0; i < res.data.counteragentFilter.length; i++){
        totalContrActorArr.push(...res.data.counteragentFilter[i].fields)
      }

      contrActorTableStore.setAllFilter(totalContrActorArr.map(item=>item.code))

      totalArr.sort((a,b)=>a.ordering > b.ordering ? 1 : -1)
            
      totalArrChannel.sort((a,b)=>a.ordering > b.ordering ? 1 : -1)

      totalPaymentsArr.sort((a,b)=>a.ordering > b.ordering ? 1 : -1)

      totalContrActorArr[0].ordering = 4
      totalContrActorArr[1].ordering = 2
      totalContrActorArr[2].ordering = 1
      totalContrActorArr[3].ordering = 3
      totalContrActorArr[4].ordering = 0

      totalContrActorArr.sort((a,b) => a.ordering > b.ordering ? 1 : -1)

      integrationTableStore.setConfirmList(totalArr.filter(x=>Boolean(x.isSelected)))
      integrationTableStore.setInitSettingsConfirm(totalArr.filter(x=>Boolean(x.isSelected)))

      channelTableStore.setConfirmList(totalArrChannel.filter(x=>Boolean(x.isSelected)))
      channelTableStore.setInitSettingsConfirm(totalArrChannel.filter(x=>Boolean(x.isSelected)))

      paymentsTableStore.setConfirmList(totalPaymentsArr.filter(x=>Boolean(x.isSelected)))
      paymentsTableStore.setInitSettingsConfirm(totalPaymentsArr.filter(x=>Boolean(x.isSelected)))
      
      contrActorTableStore.setConfirmList(totalContrActorArr.filter(x=>Boolean(!x.isSelected)))
      contrActorTableStore.setInitSettingsConfirm(totalContrActorArr.filter(x=>Boolean(!x.isSelected)))

      const allSelectedTzFilter:ISettingsSelected[] = [
        {
          code: "tz_id",
          isSelected: true,
          name: totalTzArr.find(x=>x.code === 'tz_id')?.name || '',
          ordering: 1,
        },
        {
          code: "tz_allowUsage",
          isSelected: true,
          name: totalTzArr.find(x=>x.code === 'tz_allowUsage')?.name || '',
          ordering: 2,
        },
        {
          code: "tz_name",
          isSelected: true,
          name: totalTzArr.find(x=>x.code === 'tz_name')?.name || '',
          ordering: 3,
        },
        {
          code: "tz_link",
          isSelected: true,
          name: totalTzArr.find(x=>x.code === 'tz_link')?.name || '',
          ordering: 4,
        },
        {
          code: "tz_types",
          isSelected: true,
          name: totalTzArr.find(x=>x.code === 'tz_types')?.name || '',
          ordering: 5,
        },
        {
          code: "tz_goods",
          isSelected: true,
          name: totalTzArr.find(x=>x.code === 'tz_goods')?.name || '',
          ordering: 6,
        },
        {
          code: "tz_interactive",
          isSelected: true,
          name: totalTzArr.find(x=>x.code === 'tz_interactive')?.name || '',
          ordering: 7,
        },
        {
          code: "tz_user",
          isSelected: true,
          name: totalTzArr.find(x=>x.code === 'tz_user')?.name || '',
          ordering: 8,
        },
        {
          code: "tz_positioning",
          isSelected: true,
          name: totalTzArr.find(x=>x.code === 'tz_positioning')?.name || '',
          ordering: 9,
        },
        {
          code: "tz_cta",
          isSelected: true,
          name: totalTzArr.find(x=>x.code === 'tz_cta')?.name || '',
          ordering: 10,
        },
        {
          code: "tz_authorInFrame",
          isSelected: true,
          name: totalTzArr.find(x=>x.code === 'tz_authorInFrame')?.name || '',
          ordering: 11,
        },
      ].filter(x=>x.name !== '')

      tzTableStore.setConfirmList(allSelectedTzFilter)
      tzTableStore.setInitSettingsConfirm(allSelectedTzFilter)

      const totalAgreementArr:ISettingsSelected[] = []

      for(let i = 0; i < res.data.agreementFilter.length; i++){
        totalAgreementArr.push(...res.data.agreementFilter[i].fields)
      }

      totalAgreementArr.sort((a,b)=>a.ordering > b.ordering ? 1 : -1)

      dashboardStore.setSelectedList(res.data.agreementFilter)
      dashboardStore.setInitSettingsSelect(res.data.agreementFilter)

      dashboardStore.setConfirmList(totalAgreementArr.filter(x=>Boolean(x.isSelected)))
      dashboardStore.setInitSettingsConfirm(totalAgreementArr.filter(x=>Boolean(x.isSelected)))

      const totalGoodsArr:ISettingsSelected[] = []

      for(let i = 0; i < res.data.productSampleFilter.length; i++){
        totalGoodsArr.push(...res.data.productSampleFilter[i].fields)
      }

      totalGoodsArr.sort((a,b)=>a.ordering > b.ordering ? 1 : -1)

      goodsTableStore.setSelectedList(res.data.productSampleFilter)
      goodsTableStore.setInitSettingsSelect(res.data.productSampleFilter)

      goodsTableStore.setConfirmList(totalGoodsArr.filter(x=>Boolean(x.isSelected)))
      goodsTableStore.setInitSettingsConfirm(totalGoodsArr.filter(x=>Boolean(x.isSelected)))

      goodsTableStore.setAllFilter(totalGoodsArr.map(item=>item.code))

      setLoading(false)
      setError(null)

    } catch(err) {
      if (err instanceof AxiosError) {
        setLoading(false)
        setError(err.message)
      }
          
    }
  }

  useEffect(() => {
    if(userStore.user === null) {
      getUserData()
    }
  }, [])

  return (
    <Box
      sx={{
        height:'100vh',
        display:'grid',
        gridTemplateColumns:'70px 1fr',
        background:'linear-gradient(90deg, rgba(66,58,180,0.21) 0%, rgba(230,191,191,0.5) 25%, rgba(66,58,180,0.21) 100%)',
      }}
    >
      <LeftPanel loading={(isError && true) || isLoading} />
      {isLoading
        ?  <LazyPageLoading/>
        :  <Box>
            {isError && <Typography variant='h5' color={'error'} textAlign={'center'}>Ошибка. {isError}</Typography>}
            <Routes>
              {homePageRoutes.map(({path, element:Element, isPermission, accessRules})=>
                <Fragment key={path}>
                  {isPermission && userStore.user
                    ?  checkUserPermission({rules:accessRules, userRules:userStore?.user?.rights})
                        ?   <Route
                              path={path} 
                              element={
                                <Suspense fallback={<LazyPageLoading/>}>
                                  <Element/>
                                </Suspense>
                              }
                            />
                        :  <Route path={path} element={<NotRightPage/>} />
                  
                    : <Route
                        path={path}
                        element={
                          <Suspense fallback={<LazyPageLoading/>}>
                            <Element/>
                          </Suspense>
                        }
                      />
                  }
                </Fragment>
              )}
              <Route path='*' element={<PageNotFound/>}/>
            </Routes>
          </Box>
      }
    </Box>
  )
})