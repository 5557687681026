import {FC, useState } from 'react'
import { IChannelAsidePredict } from './IChannelAsidePredict'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { TableDefault } from '../../../../components/Table/TableDefault'
import { TableItem } from '../../../../components/Table/ui/TableItem'
import { IStrategyField } from '../../../../strategy/interface/IStrategyField'
import { Box } from '@mui/material'
import { IAsideTableStatisticField } from '../../../TableAsidePage/ui/AsideTableStatistic/models/IAsideTableStatisticField'
import channelTableStore from '../../../../store/channelTable.store'
import { IChannelFullResponse } from '../../../../services/channel/ICnannelResponse'
import { observer } from 'mobx-react-lite'
import channelCardStore from '../../../../store/channelCard.store'

export const ChannelAsidePredict:FC<IChannelAsidePredict> = observer((props) => {
  const {
    id, 
    update_id, 
    onChangeUpdateId, 
    readOnly
  } = props

  const [predictionsRows] = useState<IStrategyField[]>([
    {code:'cpvPredict', immutable:false, isSimple:true, isMoneySimple:true, title:'CPV', isMoneyShowPredictionRemains:true},
    {code:'cpvNewPredict', immutable:false, isSimple:true, isMoneySimple:true, title:'CPV(нов)', isMoneyShowPredictionRemains:true},
    {code:'viewsPredict', immutable:false, isSimple:false, title:'Просмотры', isChannelPredictionInput:true},
    {code:'trafficPredict', immutable:false, isSimple:true, title:'Трафик'},
    {code:'ctrPredict', immutable:false, isSimple:false, title:'CTR', isChannelPredictionInput:true, ctrlsIcon:'%', isShowRemains:true},
    {code:'crSite', immutable:false, isSimple:false, title:'CR на сайте', isChannelPredictionInput:true, ctrlsIcon:'%', isShowRemains:true},
    {code:'minRequests', immutable:false, isSimple:true, title:'Минимум заявок'},
    {code:'minOrders', immutable:false, isSimple:true, title:'Минимум заказов'},
    {code:'roiPredict', immutable:false, isSimple:true, title:'ROI', isPercent:true},
    {code:'roiNewPredict', immutable:false, isSimple:true, title:'ROI(нов)', ctrlsIcon:'%'},
  ])

  const predictionsItems:IAsideTableStatisticField[] = [
    {code:'crSite', value:channelCardStore.crSiteState},
    {code:'minRequests', value:channelCardStore.minRequests},
    {code:'minOrders', value:channelCardStore.minOrders},
    {code:'roiPredict', value:channelCardStore.roiPredict},
    {code:'viewsPredict', value:channelCardStore.viewsPredict},
    {code:'cpvPredict', value:channelCardStore.cpvPredict},
    {code:'ctrPredict', value:channelCardStore.ctrPredict},
    {code:'cpvNewPredict', value:channelCardStore.cpvNewPredict},
    {code:'roiNewPredict', value:channelCardStore.roiNewPredict},
    {code:'trafficPredict', value:channelCardStore.trafficPredict},
  ]

  const createItem = (fields:IAsideTableStatisticField[]):Object => {
    const item:any = {
      id,
      update_id,
    }

    for(let property of fields) {
      item[property.code] = property.value
    }

    return item
  }

  const changeCallbackNumEdit = (currentId:number, field:string, newValue:string, channelData:IChannelFullResponse) => {

    channelTableStore.changePredictFields(currentId, field, +newValue, channelData)

    channelCardStore.setCrSite(channelData.crSite)
    channelCardStore.setMinRequests(Math.round(channelData.minRequests || 0))
    channelCardStore.setMinOrders(Math.round(channelData.minOrders || 0))
    channelCardStore.setRoiPredict(channelData.roiPredict)
    channelCardStore.setViewsPredict(channelData.viewsPredict)
    channelCardStore.setCpvPredict(channelData.cpvPredict)
    channelCardStore.setCtrPredict(channelData.ctrPredict)

    channelCardStore.setCpvNewPredict(channelData.cpvNewPredict)
    channelCardStore.setRoiNewPredict(channelData.roiNewPredict)
    channelCardStore.setTrafficPredict(channelData.trafficPredict)

    channelCardStore.setChannelSize(channelData?.size?.name || null)
    channelTableStore.changeField({id:channelData.id,  field:'size', new_value:channelData.size as any, update_id:channelData.update_id})
    
    if(onChangeUpdateId) {
      onChangeUpdateId(channelData.update_id)
    }
  }

  return (
    <AsideCardLayout headerTitle='Прогнозы'>
      <Box sx={{mt:2}}>
        <TableDefault tableRow={predictionsRows} showBorderRadius={false} maxWidth='100%'>
          <TableItem
            cells={predictionsRows} 
            {...createItem(predictionsItems)} 
            onChangeUpdateId={onChangeUpdateId}
            changePredictionNumEdit={changeCallbackNumEdit}
            readonly={readOnly}
          />
        </TableDefault>
      </Box>
    </AsideCardLayout>
  )
})