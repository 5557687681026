import {FC, useState} from 'react'
import { ContentCopy } from '@mui/icons-material'
import { CustomIconButton } from '../CustomIconButton/CustomIconButton'
import { ICopyIconButton } from './ICopyIconButton'
import { NotificationAlerts } from '../../NotificationAlerts/NotificationAlerts'

export const CopyIconButton:FC<ICopyIconButton> = (props) => {
  const {
    copyText,
    notificationText = 'Успешно скопировалось!',
    onCopyChange
  } = props

  const [isShowAlert, setShowAlert] = useState(false)

  const onCopyText = (text:string) => {
    navigator.clipboard.writeText(text)
    setShowAlert(true)
    
    if(onCopyChange){
      onCopyChange()
    }
  }

  return (
    <>
      <CustomIconButton {...props} onClick={()=>onCopyText(copyText)}>
        <ContentCopy fontSize='small'/>
      </CustomIconButton>
      <NotificationAlerts
        sucsess={{text:notificationText, open:isShowAlert, onClose:()=>setShowAlert(false)}}
      />
    </>
  )
}
