import {FC} from 'react'
import { ISearchChannelPriceTable } from './ISearchChannelPriceTable'
import { TableDefault } from '../../../../../../components/Table/TableDefault'
import { TableItem } from '../../../../../../components/Table/ui/TableItem'
import { getChannelTableCells } from './shared/dataList'
import { observer } from 'mobx-react-lite'
import channelStore from '../../../../../../store/channel.store'
import { IMotivationSearchPriceResponse } from '../../../../../../services/motivation/IMotivationResponse'

export const SearchChannelPriceTable:FC<ISearchChannelPriceTable> = observer((props) => {
  const {
    data, 
    sx, 
    maxWidth,
    talbeHeaderColor,
    readOnly
  } = props

  return (
    <>
      {channelStore.channels !== null &&
        <TableDefault
          tableRow={getChannelTableCells(data, channelStore.channels)}
          showBorderRadius={false}
          sx={sx}
          maxWidth={maxWidth}
          talbeHeaderColor={talbeHeaderColor}
        >
          {Object.keys(data).map(key=>{

              const currentRow:IMotivationSearchPriceResponse[] = [...data[key]]

              const props = {
                name:key === 'big' ? 'Крупный' : key === 'medium' ? 'Обычный' : 'Мелкий',
                ...Object.fromEntries(currentRow.map((x)=>{
                  return [x.channelTypeCode, x.value]
                })),
                ...Object.fromEntries(currentRow.map((x)=>{
                  return [x.channelTypeCode+'Id', x.id]
                }))
              }

              return <TableItem key={key} cells={getChannelTableCells(data, channelStore.channels!)} {...props} readOnlyChannelPrice={readOnly}/>
            })
          }
        </TableDefault>
      }
    </>
  )
})