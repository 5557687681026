import {FC, useState} from 'react'
import { IReleaseManager } from './IReleaseManager'
import { observer } from 'mobx-react-lite'
import companyUsersStore from '../../../../../store/companyUsers.store'
import { Avatar, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'
import { NotificationAlerts } from '../../../../NotificationAlerts/NotificationAlerts'

export const ReleaseManager:FC<IReleaseManager> = observer((props) => {
  const {id} = props

  const [isAlertOpen, setAlertOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const copyText = (text:string) => {
    navigator.clipboard.writeText(text)
    setAlertOpen(true)
    handleClose()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const manager = companyUsersStore.users.find(item=>item.id === id)

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <Typography>{manager?.firstName}</Typography>
        <IconButton onClick={handleClick}>
          <ErrorOutline fontSize='small'/>
        </IconButton>
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          {manager &&
            <MenuItem sx={{pl:1, pr:1}} onClick={()=>copyText(manager?.email)}>
              <Avatar src={manager?.imageUrl} sx={{height:35, width:35, mr:1}}/>
              <Stack direction={'column'}>
                <Typography>{manager?.firstName} {manager?.lastName}</Typography>
                <Typography>{manager?.email}</Typography>
              </Stack>
            </MenuItem>
          }
        </Menu>
      </Stack>
      <NotificationAlerts
        sucsess={{text:'Почтовый адрес скопирован!', open:isAlertOpen, onClose:()=>setAlertOpen(false)}}
      />
    </>
  )
})