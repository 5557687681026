import {FC} from 'react'
import { ICheckListArrayItem, ICheckListCard } from './ICheckListCard'
import { AsideCardLayout } from '../../../../../../components/AsideCardLayout/AsideCardLayout'
import { CheckListItem } from './ui/CheckListItem/CheckListItem'
import {Box, Stack} from '@mui/material'

export const CheckListCard:FC<ICheckListCard> = (props) => {
  const {
    data,
    setUpdateId,
    update_id,
    id,
    readOnly,
    checkWidth,
  } = props

  const list:ICheckListArrayItem[] = [
    {field:'isTzSelected', title:'ТЗ',},
    {field:'isPreview', title:'Превью',},
    {field:'isPromoWorks', title:'Промокод работает'},
    {field:'isPromoAndSourceInDirectory', title:'Промокод и метка в справочнике',},
  ]

  return (
    <AsideCardLayout headerTitle='чек-лист'>
      <Stack direction={'row'} flexWrap={'wrap'}>
        {list.map(item=>
          <Box width={checkWidth} key={item.field}>
            <CheckListItem
              data={data}
              field={item.field}
              id={id}
              title={item.title}
              update_id={update_id}
              setUpdateId={setUpdateId}
              readOnly={readOnly}
            />
          </Box>
      )
        }
      </Stack>
    </AsideCardLayout>
  )
}
