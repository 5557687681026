import { FC, useState } from 'react'
import { IAsideAddTheme } from './IAsideAddTheme'
import { Box, Button, Stack, Typography } from '@mui/material'
import { AsideCardThemeList } from '../../../TableAsidePage/ui/AsideCardThemeList/AsideCardThemeList'
import {EmptyText} from "../../../../components/EmptyText/EmptyText";

export const AsideAddTheme:FC<IAsideAddTheme> = (props) => {
  const {
    lists, 
    onChange, 
    readOnly,
    channelId,
    update_id,
    setThemeValue,
    setUpdateId,
  } = props

  const [isOpen, setOpen] = useState(false)

  return (
    <Box display={{ xs: 'stretch', sm: 'flex' }} alignItems={{ xs: 'start', sm: 'center' }} sx={{flexWrap:'wrap'}}>
      <Typography minWidth={'180px'}>Тематика отсутствует</Typography>
      {!isOpen &&
        <Box sx={{whiteSpace:'nowrap'}}>
          <Button variant='outlined' disabled={readOnly} onClick={()=>setOpen(true)}>Выбрать тематику</Button>
        </Box>
      }
      <Stack spacing={1} overflow={'hidden'} maxWidth={'320px'} width={'100%'}>
      {isOpen && 
        <AsideCardThemeList
          label={false}
          onChange={onChange} 
          lists={lists} 
          readOnly={readOnly}
          channelId={channelId}
          setThemeValue={setThemeValue}
          update_id={update_id}
          setUpdateId={setUpdateId}
        />
      }
      </Stack>
    </Box>
  )
}
