import { FC, useState, useEffect } from 'react'
import { AsideCardLayout } from '../../../../../AsideCardLayout/AsideCardLayout'
import ReferencesService from '../../../../../../services/references/references.service'
import { IReferencesBudgetParams } from '../../../../../../services/references/IReferencesRequest'
import { IUserBudgetInfo } from './IUserBudgetInfo'
import { AxiosError } from 'axios'
import { IReferencesBudgetItem } from '../../../../../../services/references/IReferencesResponse'
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material'
import { priceTransormFormat } from '../../../../../../shared/functions/functions'
import dayjs, { Dayjs } from 'dayjs'
import { SearchMontDatePicker } from '../../../../../../pages/DashboardPage/pages/MotivationPage/ui/SearchMontDatePicker/SearchMontDatePicker'

export const UserBudgetInfo:FC<IUserBudgetInfo> = (props) => {
  const {userId} = props

  const [date, setDate] = useState<Dayjs | null>(dayjs())
  const [dateMonth, setDateMonth] = useState<number>(new Date().getMonth())
  const [dateYear, setDateYear] = useState<number>(new Date().getFullYear())

  const [monthNames] = useState<string[]>(["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"])

  const [data, setData] = useState<IReferencesBudgetItem | null>(null)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const getUserBudget = (params:IReferencesBudgetParams) => {
    setLoading(true)
    setError(null)

    ReferencesService.budgetGet(params)
      .then(res=>{
        //console.log(res, 'res user budget')
        setData(res.data?.[0])
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          console.log(err, 'err user budget')
          setError(err.response?.data?.message)
          setLoading(false)
        }
      })
  }

  const userBudgetMonth = () => {
    if(date){
      getUserBudget({month:date.month()+1, year:date.year(), userId})
      setDateMonth(date.month())
      setDateYear(date.year())
    }
  }

  useEffect(()=>{
    getUserBudget({month:dateMonth+1, year:date ? date?.year() : new Date().getFullYear(), userId})
  },[])

  return (
    <AsideCardLayout headerTitle={`бюджет на ${monthNames[dateMonth]} ${dateYear}`}>
      <Box sx={{pt:1}}>
        {isLoading && <Skeleton variant='rounded' sx={{height:150}}/>}
        {!isLoading && data !== null &&
          <Stack spacing={1}>
            {data?.amountNew !== undefined && data?.amountOld !== null && data?.wastedNew !== undefined && data?.wastedOld !== null
              ? <>
                  <Typography>Бюджет (план) на новые каналы: {priceTransormFormat(data?.amountNew)}</Typography>
                  <Typography>Бюджет (план) на старые каналы: {priceTransormFormat(data?.amountOld)}</Typography>
                  <Typography>Потрачено на новые каналы: {priceTransormFormat(data?.wastedNew)}</Typography>
                  <Typography>Потрачено на старые каналы: {priceTransormFormat(data?.wastedOld)}</Typography>
                </>
              : <Typography>Бюджет не назначен</Typography>
            }
            <Stack spacing={1.5} sx={{pt:0.5}}>
              <SearchMontDatePicker initValue={date} onChange={setDate}/>
              <Button 
                variant='contained' sx={{mt:1}} 
                onClick={userBudgetMonth} 
                disabled={isNaN(date?.month()!) && isNaN(date?.year()!)}
              >
                применить
              </Button>
            </Stack>
          </Stack>
        }
      </Box>
    </AsideCardLayout>
  )
}