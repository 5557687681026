import { FC } from "react"
import { IAsideModalOrders } from "./IAsideModalOrders"
import { Dialog, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { Cancel } from "@mui/icons-material"
import { TableDefault } from "../../../../components/Table/TableDefault"
import { TableItem } from "../../../../components/Table/ui/TableItem"
import { IStrategyField } from "../../../../strategy/interface/IStrategyField"
import { ANOTHER_ROUTES } from "../../../../shared/routes/routes"

export const AsideModalOrders:FC<IAsideModalOrders> = (props) => {
  const {open, onClose, orders} = props
  
  const hrefJoin = (id:string):string => {
    return ANOTHER_ROUTES.RETAIL_ORDER + id + '/' + 'edit'
  }

  const data:IStrategyField[] = [
    {title:'Номер', code:'number', isSimple:false, immutable:false, isLinks:true, href:hrefJoin, maxWidth:'100%'},
    {title:'Дата создания', code:'createdAt', isSimple:false, immutable:false, isDate:true},
    {title:'Статус', code:'status', isSimple:false, immutable:false, statusReadOnly:true},
    {title:'Сумма заказов', code:'totalSumm', isSimple:false, immutable:false, isMoney:true},
    {title:'Промокод', code:'promokod', isSimple:true, immutable:false,}, 
    {title:'Источник', code:'source', isSimple:true, immutable:false},
  ]

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={'lg'}
    >
      <DialogTitle sx={{display:'flex', alignItems:'center', justifyContent:'space-between', p:{xs:1, sm:2}, pr:1}}>
        Все заказы
        <IconButton onClick={onClose}>
          <Cancel/>
        </IconButton>
      </DialogTitle>
      <Divider/>
      <DialogContent sx={{p:{xs:1, sm:2},}}>
        <TableDefault
          tableRow={data}
          maxHeight="600px"
          maxWidth="1150px"
          showBorderRadius={false}
        >
          {orders.map(item=><TableItem key={item.id} cells={data} {...item} isPriceEdit={false} hover readonly/>)}
        </TableDefault>
      </DialogContent>
    </Dialog>
  )
}
