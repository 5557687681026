import {FC} from 'react'
import { IDateInputEdit } from './IDateInputEdit'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { Check, Close } from '@mui/icons-material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import 'dayjs/locale/ru'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { getDatePlusCustomTime } from '../../../../../shared/functions/functions'
import { EditIconButton } from '../../../../Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../../../../Buttons/CustomIconButton/CustomIconButton'

export const DateInputEdit:FC<IDateInputEdit> = (props) => {
  const {
    onClose,
    onSubmit,
    fullWidth = false,
    loading = false,
    open,
    readOnly = false,
    onOpen,
    value,
    onChange,
    date,
    isFullYears = false,
    maxWidth,
    width,
    minWidth
  } = props

  const onKeyDownSend = (e:React.KeyboardEvent) => {
    if(e.code === "Enter" || e.code === "NumpadEnter"){
      if(value){
        onSubmit(value)
      }
    }
  }

  return (
    <>
      {!open &&
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography sx={{whiteSpace:'nowrap'}}>
            {date ? getDatePlusCustomTime({value:date}) : ''} {!isFullYears ? date ? getDatePlusCustomTime({value:date, format:'HH:mm'}) : '' : null}
          </Typography>
          {!readOnly &&
            <EditIconButton size='small' onClick={onOpen}/>
          }
        </Stack>
      }
      {open &&
        <Stack direction={'row'} spacing={0.5} width={width} maxWidth={maxWidth} minWidth={minWidth}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
            <DateTimePicker
              value={value === '' ? null : value}
              onChange={(val:any)=>onChange(val)}
              slotProps={{
                textField:{
                  size:'small',
                  onKeyDown:onKeyDownSend,
                  placeholder:'дд.мм.год час:мин'
                }
              }}
              sx={{width:fullWidth ? '100%' : '200px'}}
              disabled={loading}
            />
          </LocalizationProvider>
          <Stack direction={'row'} alignItems={'center'}>
            {!loading
              ? <CustomIconButton
                  size='small'
                  onClick={()=>onSubmit(value)}
                  disabled={loading || !value}
                  showBackground
                >
                  <Check fontSize='small'/>
                </CustomIconButton>
              : <CircularProgress sx={{maxHeight:20, maxWidth:20}} /> 
            }
            <CustomIconButton size='small' onClick={onClose} disabled={loading}>
              <Close fontSize='small'/>
            </CustomIconButton>
          </Stack>
        </Stack>
      }
    </>
  )
}
