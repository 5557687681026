export const LS_TAKE_REQUEST_INTEGRATION = 'ls_take_request_integration'

export const LS_TAKE_REQUEST_CHANNEL = 'ls_take_request_channel'

export const LS_TAKE_REQUEST_FINANCES = 'ls_take_request_finances'

export const LS_TAKE_REQUEST_TZ = 'ls_take_request_tz'

export const LS_TAKE_REQUEST_AGREEMENTS = 'ls_take_request_tz'

export const LS_TAKE_REQUEST_GOODS = 'ls_take_request_goods'

// resize page

export const LS_SAVE_WIDTH_INTEGRATION = 'ls_save_width_integration'
export const LS_SAVE_WIDTH_USER = 'ls_save_width_user'
export const LS_SAVE_WIDTH_CHANNEL = 'ls_save_width_channel'
export const LS_SAVE_WIDTH_FINANCE = 'ls_save_width_finance'