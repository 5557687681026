import { IFilterSelectItem } from "../../../components/FilterSelectChip/IFilterSelectChip";
import { IUser } from "../../../entities/user/models/IUser";
import { ILayoutResponse } from "../../../services/layout/layoutResponse";
import { ALL_ROLES, ALL_RULES } from "../../../shared/rules/interface/EnumRights";

export const getSocialTypeInSelect = (typeStore:ILayoutResponse['channelTypes']):IFilterSelectItem[] => {
  const type:IFilterSelectItem[] = Object.keys(typeStore).map(key=>{
    
    const newItem:IFilterSelectItem = {
      name:typeStore[key].name,
      value:key
    }

    return newItem
  })

  return type
}

export const getManagerInSelect = (users:IUser[], enableAllManager?:boolean, showSearchManager?:boolean):IFilterSelectItem[] => {

  const manager:IFilterSelectItem[] = users
    .filter(user=>
      showSearchManager !== true
        ? user.rights.find(x=>x.code === ALL_RULES.release) || user.role === ALL_ROLES.trainee
        : user.rights.find(x=>x.code === ALL_RULES.release) || user.role === ALL_ROLES.trainee || user.role === ALL_ROLES.SEARCH_MANAGER
    )
    .filter(user=>user.isActive === true)
    .map(user=>{
      const newUser:IFilterSelectItem = {
        name:`${user.firstName} ${user.lastName}`,
        value:user.id.toString()
      }
      return newUser
    })
  
  const defaultUser:IFilterSelectItem = {
    name:'все менеджеры',
    value:null!
  }

  if(enableAllManager){
    return manager
  } else return [defaultUser, ...manager]
  
}