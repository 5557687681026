import {useState, FC, useCallback} from 'react'
import {Avatar, Box, IconButton, Link, Skeleton, Stack, Tooltip, Typography} from '@mui/material'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { AsideCardPromokod } from '../../ui/AsideCardPromokod/AsideCardPromokod'
import { PromokodAdd } from '../../../../components/Promokod/PromokodAdd'
import { PriceEdit } from '../../../../components/Table/ui/Fields/PriceEdit/PriceEdit'
import { AsideReleaseManager } from '../../ui/AsideReleaseManager/AsideReleaseManager'
import { AsideCardDate } from '../../ui/AsideCardDate/AsideCardDate'
import { TableAsideRelease } from '../../ui/TableAsideRelease/TableAsideRelease'
import { AsideCardPrediction } from '../../ui/AsideCardPrediction/AsideCardPrediction'
import { AsideCardGoods } from '../../ui/AsideCardGoods/AsideCardGoods'
import { observer } from 'mobx-react-lite'
import { useSearchParams} from 'react-router-dom'
import { IIntegrationMain } from './IIntegrationMain'
import { AsideTableStatistic } from '../../ui/AsideTableStatistic/AsideTableStatistic'
import { getFieldStatisticsCategories, getFieldStatisticsMain, getStatisticsCategories, getStatisticsMain } from './shared/dataList'
import { AsideTableTraficStatistic } from '../../ui/AsideTableTraficStatistic/AsideTableTraficStatistic'
import { IntegrationStatus } from './ui/IntegrationStatus/IntegrationStatus'
import { TechnicalTask } from './ui/TechnicalTask/TechnicalTask'
import { AddIntegrationDate } from './ui/AddIntegrationDate/AddIntegrationDate'
import { IntegrationLink } from './ui/IntegrationLink/IntegrationLink'
import categoriesGoodsStore from '../../../../store/categoriesGoods.store'
import integrationPageStore from '../../../../store/integrationPage.store'
import { AddShortLink } from './ui/AddShortLink/AddShortLink'
import { StatusAddEmpty } from './ui/StatusAddEmpty/StatusAddEmpty'
import { GoodCategory } from '../../../../components/Table/ui/Fields/GoodCategory/GoodCategory'
import { IntegrationComments } from './ui/IntegrationComments/IntegrationComments'
import channelStore from '../../../../store/channel.store'
import { ArrowForward } from '@mui/icons-material'
import { CheckListCard } from './ui/CheckListCard/CheckListCard'
import {IIntegrationsFullResponse} from "../../../../services/integration/IIntegrationsResponse";
import { PaymentStatus } from '../../../../components/Table/ui/Fields/PaymentStatus/PaymentStatus'
import { GuranteePrice } from './ui/GuranteePrice/GuranteePrice'
import { GuranteeCheckbox } from './ui/GuranteeCheckbox/GuranteeCheckbox'
import { VideoReview } from './ui/VideoReview/VideoReview'
import integrationTableStore from '../../../../store/integrationTable.store'
import { getDatePlusCustomTime, priceTransormFormat } from '../../../../shared/functions/functions'
import { IntegrationTargetAudience } from './ui/IntegrationTargetAudience/IntegrationTargetAudience'
import {EmptyText} from "../../../../components/EmptyText/EmptyText";
import userStore from "../../../../store/user.store";
import {ALL_RULES} from "../../../../shared/rules/interface/EnumRights";
import IntegrationsService from "../../../../services/integration/integrations.service";
import {AxiosError} from "axios";
import {DeleteButton} from "../../../FinancesCreatePage/ui/FinancesPayments/ui/FinancesPaymentItem/ui/PaymentDeleteButton/PaymentDeleteButton";

export const IntegrationMain:FC<IIntegrationMain> = observer((props) => {
  const {
    id,
    closeModal,
    data,
    dataOrder,
    isOrdersLoading,
    cpv,
    price,
    setCpvValue,
    setPriceValue,
    promokodList,
    setPromokodList,
    dataOnSource,
    isMetricError,
    isMetricLoading,
    date,
    setDate,
    setTzValue,
    tzValue,
    update_id,
    setUpdateId,
    link,
    setLink,
    setStatusValue,
    statusValue,
    goods,
    setGoods,
    goodsCategoryCode,
    setGoodsCategoryCode,
    readOnly,
    channelSearchParamsKey,
    changePage,
    setQrCodeLink,
    qrCodeLink
  } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading,setIsLoading]= useState(false)
  const [sourceValue, setSourceValue] = useState<string>(data?.source)
  const [shortLink, setShortLink] = useState<string | null>(data?.shortLink === '' ? null : data?.shortLink)

  const channelCardOpen = () => {
    searchParams.set(channelSearchParamsKey, `${data.channel.id}`)
    setSearchParams(searchParams)
  }
  
  const deleteIntegration = useCallback((id?:string | number, update_id?:number) => {
    if(id && update_id){
      setIsLoading(true)
      IntegrationsService.delete({integrationId:+id, update_id})
        .then(res=>{
          integrationTableStore.delete(res.data.id)
          searchParams.delete('integration_card');
          setSearchParams(searchParams);
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            console.log(err, 'err del')
          }
        })
        .finally(()=>{
          setIsLoading(false)
        })
    }
  }, [])

  const checkUserRoleDeleted = useCallback(():boolean => {
    return !!(userStore.user?.rights.find(x=>x?.code === ALL_RULES.ADMIN || x?.code === ALL_RULES.delete_integrations || x?.code?.includes(ALL_RULES.release)))
  }, [])

  const checkUserDeleted = useCallback((userId:number | null):boolean => {
    return !!(userStore?.user?.id === userId || userStore?.user?.rights?.some(x=>x.code === ALL_RULES.ADMIN))
  }, [])

  const editGoodsPredictions = (data: IIntegrationsFullResponse) => {
    
    integrationPageStore.setAverageOrderSumm(data.averageCheck)
    integrationPageStore.setPredictionViews(data.prediction_views)
    integrationPageStore.setPredictionTraffic(data.prediction_traffic)
    integrationPageStore.setPredictionCpv(data.prediction_cpv)
    integrationPageStore.setPredictionCtr(data.prediction_ctr)
    integrationPageStore.setPredictionCr(data.prediction_cr)
    integrationPageStore.setPredictionOrders(data.prediction_orders)
    integrationPageStore.setPredictionRoi(data.prediction_roi)
  }

  const changePrice = (value:string) => {
    setPriceValue(value)
    integrationPageStore.setTotalSumm(+value)
  }

  const onChangePriceAndStatus = (id:number, status:IIntegrationsFullResponse['status'], update_id:number) => {
    setStatusValue(status?.code)
    integrationTableStore.changeUpdateIdItem(id, update_id, status?.code)
  }

  return (
    <Box sx={{height:'100%'}}>
      <Box
        sx={{
          display: "grid", 
          gridTemplateColumns:{
            xs:'1fr', md:'1fr 1fr', lg:`1fr minmax(32%, 1fr) ${integrationPageStore.chatOpen === false ? '0.08fr' : '1fr'}`
          },
          columnGap:2, 
          rowGap:2,
          transition:'all ease .3s'
        }}
      >
        <AsideCardLayout
          headerTitle="Основное"
          sx={{height:'fit-content'}}
          rightContent={
            <VideoReview 
              checked={integrationPageStore.isVideoReview || false}
              id={data.id}
              readOnly={readOnly}
              setUpdateId={setUpdateId}
              update_id={update_id}
            />
          }
        >
          <Stack direction={"column"} spacing={1}>
            <Box sx={{mt:1, display:'grid', gridTemplateColumns:'min-content 1fr', columnGap:'4px', alignItems:'center'}}>
              <Typography noWrap>Имя канала:</Typography>
              <Box sx={{display:'flex', overflow:'hidden', alignItems:'center'}}>
                {data.channel?.name ?
                  <Link
                    onClick={channelCardOpen}
                    color={'secondary'}
                    sx={{textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden', cursor:'pointer'}}
                  >
                    {data.channel.name}
                  </Link>
                  : <EmptyText>Канал отсутствует</EmptyText>}
                  {data.channel?.name ?
                    <Avatar
                      src={channelStore?.channels?.[data?.channel?.type?.code]?.icon}
                      alt={data?.channel?.type?.code}
                      sx={{height:20, width:20, ml:0.5}}
                    />
                  : null}
                  {data.channel?.link ?
                    <Tooltip title={<Typography>Ссылка на канал</Typography>}>
                      <IconButton size='small' onClick={()=>window.open(data?.channel?.link, '_blank')}>
                        <ArrowForward fontSize='small'/>
                      </IconButton>
                    </Tooltip>
                  : null}
              </Box>
            </Box>
            <Typography>Новизна канала: {data?.firstIntegrationWithChannel ? 'Новый' : 'Старый'}</Typography>
            <Typography>Тематика канала: {data?.channel?.theme?.name || 'Отсутствует'}</Typography>
            <PriceEdit
              id={+id}
              price={price !== null ? price : 0}
              onChangeCpv={setCpvValue} 
              isEditMode={!readOnly}
              title="Стоимость"
              onChangePrice={changePrice} 
              fullWidth
              field="price"
              update_id={update_id}
              onChangeUpdateId={setUpdateId}
              onChangeIntegrationsForPayment={integrationPageStore.onChangeIntegrationsForPayment}
              onChangePriceAndStatus={onChangePriceAndStatus}
            />
            <Stack direction={"row"} alignItems={"center"} spacing={statusValue ? 1 : 0}>
              <Typography>Статус:</Typography>
              {statusValue
                ? <IntegrationStatus
                    id={data.id}
                    update_id={update_id}
                    type={statusValue}
                    readOnly={readOnly}
                    onChangeUpdateId={setUpdateId}
                    integrationId={data.id}
                    setStatusValue={setStatusValue}
                  />
                : <StatusAddEmpty
                    id={data.id}
                    update_id={update_id}
                    readOnly={readOnly}
                    onChangeUpdateId={setUpdateId}
                    setStatus={setStatusValue}
                  />
              }
              {checkUserRoleDeleted() && checkUserDeleted(data?.releaseUser?.id) &&  statusValue==='planned' && 
                <DeleteButton 
                  deleteButtonLabel={'удалить интеграцию'} 
                  cancelButtonLabel={'отмена'} 
                  confirmationTitle={'Внимание!'} 
                  subtext={'Вы действительно хотите удалить интеграцию?'}
                  isLoading={isLoading} 
                  onDeleteArgs={[data.id,data.update_id]} 
                  onDelete={deleteIntegration}  
                />
              }
            </Stack>
            <Typography display={'flex'} gap={1}>Источник: {sourceValue ? sourceValue.toLowerCase() : <EmptyText>источник отсутствует</EmptyText>}</Typography>
            {promokodList && id
              ? <AsideCardPromokod
                  onChangeSourceValue={setSourceValue}
                  readOnly={readOnly}
                  onChangeValue={setPromokodList}
                  isEditMode id={id}
                  promokod={promokodList?.code} 
                  dummies={promokodList?.dummies}
                  onChangeUpdateId={setUpdateId}
                  isActivated={promokodList?.isActivated}
                  integrationId={id}
                  update_id={update_id}
                  setLink={setLink}
                  setShortLinkChange={setShortLink}
                  disableActiveOnSiteBtn={goods?.length===0 || goodsCategoryCode == null}
                  goodsCategoryCode={goodsCategoryCode}
                />
              : <PromokodAdd
                  readOnly={readOnly}
                  id={+id}
                  title="Промокод:"
                  subTitle="Добавить промокод"
                  onChangeValue={setPromokodList}
                  onChangeSource={setSourceValue}
                  placeholder="Введите промокод"
                  onChangeUpdateId={setUpdateId}
                  setLink={setLink}
                />
            }
            <IntegrationLink
              link={link} 
              title='Ссылка'
              tooltip
              id={+id}
              field='link'
              update_id={update_id}
              setUpdateId={setUpdateId}
              setLink={setLink}
              deleteModalTitle={'Вы действительно хотите удалить основную ссылку?'}
            />
            {(shortLink !== null && shortLink?.trim() !=='')
              ? <IntegrationLink
                  link={shortLink} 
                  title='Сокращенная ссылка:'
                  id={+id}
                  field='shortLink'
                  update_id={update_id}
                  setUpdateId={setUpdateId}
                  setLink={setShortLink}
                  tooltip
                  deleteModalTitle={'Вы действительно хотите удалить короткую ссылку?'}
                />
              : <AddShortLink 
                  id={+id} 
                  readOnly={readOnly} 
                  shortLink={shortLink} 
                  setShortLink={setShortLink}
                  setUpdateId={setUpdateId}
                  update_id={update_id}
                />
            }
            <IntegrationLink
              link={qrCodeLink}
              title='Ссылка на QR-код'
              tooltip
              id={+id}
              isEditMode
              field='qrCodeLink'
              update_id={update_id}
              setUpdateId={setUpdateId}
              setLink={setQrCodeLink}
              deleteModalTitle={'Вы действительно хотите удалить ссылку на QR-код?'}
            />
            <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{flexWrap:'wrap'}}>
              <Typography display={'flex'} gap={1} >
                Товарная категория: {goodsCategoryCode ? <GoodCategory code={goodsCategoryCode}/> : <EmptyText/>}
              </Typography>
              {goodsCategoryCode && integrationPageStore.integrationTargetAudience ? 
                <IntegrationTargetAudience targetAudience={integrationPageStore.integrationTargetAudience} audienceData={data?.channel}/>
              : null}
            </Stack>
            <Stack spacing={0.5} direction={{sm:'row'}} alignItems={{sm:'center'}}>
              <Typography>Техническое задание:</Typography>
              <TechnicalTask
                width={'100%'}
                maxWidth={'350px'}
                goodsCategoryCode={goodsCategoryCode}
                integrationId={id}
                update_id={update_id}
                readOnly={readOnly}
                iniValue={tzValue}
                setStateValue={setTzValue}
                onChangeUpdateId={setUpdateId}
                channelType={data.channel?.type?.code}
                setLink={setLink}
                setGoods={setGoods}
                setGoodsCategoryCode={setGoodsCategoryCode}
              />
            </Stack>
            <GuranteePrice
              readOnly={readOnly}
              value={(integrationPageStore.guarantee?.viewsCount !== null && integrationPageStore.guarantee?.viewsCount !== undefined)
                ? integrationPageStore.guarantee?.viewsCount
                : null
              }
              integrationId={data?.id}
              id={integrationPageStore.guarantee?.id || null}
              iconColor={integrationPageStore.guarantee?.status?.color || null}
              setUpdateId={setUpdateId}
              update_id={update_id}
            />
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap'}}>
              <Typography display={'flex'} gap={1}>
                Дата окончания гаранта: 
                {integrationPageStore.guarantee?.lastDate ? 
                  getDatePlusCustomTime({value:integrationPageStore.guarantee?.lastDate, format:'DD.MM.YYYY HH:mm'})
                  : <EmptyText/>
                }
              </Typography>
              {integrationPageStore.guarantee?.viewsInLastDay
                ? <Typography>{priceTransormFormat(integrationPageStore.guarantee.viewsInLastDay, false)}</Typography>
                : null
              }
            </Box>
            <Box>
              <GuranteeCheckbox
                checked={Boolean(integrationPageStore.guarantee?.repeat)}
                readOnly={readOnly || (integrationPageStore?.guarantee?.viewsCount === null || integrationPageStore?.guarantee?.viewsCount === undefined)}
                integrationId={data?.id}
                id={integrationPageStore.guarantee?.id || null}
                setUpdateId={setUpdateId}
                update_id={update_id}
              />
            </Box>
            <Stack spacing={0.5} direction={{sm:'row'}} alignItems={{sm:'center'}} >
              <Typography mr={1} >Выпускающий менеджер:</Typography>
              <AsideReleaseManager
                id={id}
                initValue={data?.releaseUser?.id}
                update_id={update_id} 
                onChangeUpdateId={setUpdateId}
                field={'releaseUser'}
                fieldNotCode='releaseUserId'
                readOnly={readOnly}
              />
            </Stack>
            {date
              ? <AsideCardDate
                  title="Запланированная дата:"
                  date={date} 
                  setValue={setDate}
                  id={data.id} 
                  field="integration_date"
                  update_id={update_id}
                  readonly={readOnly}
                  fullWidth
                  onChangeUpdateId={setUpdateId}
                />
              : <AddIntegrationDate
                  id={data.id} 
                  update_id={update_id} 
                  onChangeUpdateId={setUpdateId}
                  readOnly={readOnly}
                />
            }
            <Stack spacing={1}>
              <Stack direction={{xs:'column', sm:'row'}} alignItems={{xs:'start', sm:'center'}} flexWrap={'wrap'}>
                <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'} sx={{mr:1}}>
                  <Typography sx={{mr:1, whiteSpace:'nowrap'}}>Статус оплаты:</Typography>
                  {integrationPageStore.status
                    ? <PaymentStatus id={id} statusCode={integrationPageStore.status?.code} type='payment' readOnly/>
                    : <EmptyText sx={{whiteSpace:'nowrap'}}>Оплата не создана</EmptyText>
                  }
                </Stack>
                {integrationPageStore.status &&
                  <Box ml={{sm:2, md:0}}>
                    <Link color={'secondary'} onClick={()=>changePage(3)} sx={{cursor:'pointer', whiteSpace:'nowrap'}}>
                      {integrationPageStore.status ? 'Открыть оплату' : 'Создать оплату'}
                    </Link>
                  </Box>
                }
                {!integrationPageStore.status &&
                  <Box ml={{sm:2, md:0}}>
                    <Link color={'secondary'} onClick={()=>changePage(3)} sx={{cursor:'pointer', whiteSpace:'nowrap'}}>
                      {integrationPageStore.status ? 'Открыть оплату' : 'Создать оплату'}
                    </Link>
                  </Box>
                }
              </Stack>
              {integrationPageStore.plannedPaidAt
                ? <Typography>Дата плановой оплаты: {getDatePlusCustomTime({value:integrationPageStore.plannedPaidAt})}</Typography>
                : null
              }
            </Stack>
          </Stack>
        </AsideCardLayout>
        <Stack gap={2}>
          <TableAsideRelease
            id={id.toString()}
            releases={data?.releases}
            readOnly={readOnly}
            onChangeUpdateId={setUpdateId}
          />
          <AsideCardGoods
            id={+id}
            goods={goods}
            readOnly={readOnly}
            setLink={setLink}
            setGoods={setGoods}
            setUpdateId={setUpdateId}
            setGoodsCategoryCode={setGoodsCategoryCode}
            editGoodsPredictions={editGoodsPredictions}
            forFirstIntegrationWithChannel={Boolean(data.firstIntegrationWithChannel)}
          />
          <CheckListCard checkWidth={'50%'} data={data} id={id} setUpdateId={setUpdateId} update_id={update_id} readOnly={readOnly}/>
        </Stack>
        <Box
          sx={{
            gridRowStart: {md:4, lg:1},
            gridRowEnd: {xs:7, md:4, lg:3},
            gridColumn: {md:'1 / 3', lg:'3'},
          }}
        >
          <IntegrationComments data={data} id={id} readOnly={readOnly}/>
        </Box>
        <Box
          sx={{
            gridColumnStart:1, 
            gridColumnEnd:{xs:'auto', md:3},
            maxWidth:{xs:'calc(100vw - 32px)', sm:'calc(100vw - 70px - 32px)', md:'100%'}
          }}
        >
          <AsideCardLayout headerTitle='Прогнозы'>
            <Box sx={{mt:2}}>
              <AsideCardPrediction
                id={data.id}
                update_id={update_id}
                data={data}
                readOnly={readOnly}
                onChangeUpdateId={setUpdateId}
              />
            </Box>
          </AsideCardLayout>
          <AsideCardLayout sx={{mt:2}} headerTitle='Фактические данные'>
            <Box sx={{mt:2}}>
              <AsideTableStatistic
                id={data.id}
                update_id={update_id}
                rows={getStatisticsMain(data)}
                onChangeUpdateId={setUpdateId}
                dataField={getFieldStatisticsMain(data, cpv !== null ? cpv?.toString() : '0', `${integrationPageStore.views}`,dataOrder, data.cac)}
              />
            </Box>
          </AsideCardLayout>
          <AsideCardLayout sx={{mt:2}} headerTitle='Категории товаров в заказе:'>
            <Box sx={{mt:2}}>
              {categoriesGoodsStore.categories && data.categories &&
                <AsideTableStatistic rows={getStatisticsCategories(categoriesGoodsStore.categories)} dataField={getFieldStatisticsCategories(data.categories)} />
              }
              {data.categories === null && 
                <Typography variant='h6' color={'grey'} sx={{fontWeight:'normal', fontStyle: 'italic', fontSize:'0.9rem'}}>Товаров не найдено</Typography>
              }
            </Box>
          </AsideCardLayout>
          <AsideCardLayout sx={{mt:2}} headerTitle='Трафик'>
            <Box sx={{mt:2}}>
              {data &&
                <>
                  {!isMetricLoading && dataOnSource &&
                    <AsideTableTraficStatistic dataOnSource={dataOnSource}/>
                  }
                  {isMetricLoading && <Skeleton variant='rounded' height={48} width={'100%'}/>}
                  {isMetricError && <Typography variant='h6' color={'error'} sx={{fontWeight:'normal', fontStyle: 'italic', fontSize:'0.9rem'}}>{isMetricError}</Typography>}
                  {!isMetricLoading && dataOnSource === null && !isMetricError &&
                    <Typography variant='h6' color={'grey'} sx={{fontWeight:'normal', fontStyle: 'italic', fontSize:'0.9rem'}}>Динамики по трафику нет</Typography>
                  }
                </>
              }
            </Box>
          </AsideCardLayout>
        </Box>
      </Box>
    </Box>
  )
})