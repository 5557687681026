import {FC, useState} from 'react'
import { IGoodsTableItemField } from './IGoodsTableItemField'
import { InputEditField } from '../../../../../../../../../../components/Table/ui/Fields/InputEditField/InputEditField'
import { IconButton, Stack, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { AxiosError } from 'axios'
import IntegrationsService from '../../../../../../../../../../services/integration/integrations.service'
import { IIntegrationGoodsCategoryThresholds } from '../../../../../../../../../../services/integration/IIntegrationsResponse'
import rulesStore from '../../../../../../../../../../store/rules.store'

export const GoodsTableItemField:FC<IGoodsTableItemField> = (props) => {
  const {goodsCode, value} = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const [isEdit, setEdit] = useState(false)

  const changeField = (value:string) => {
    if(value?.trim() !== ''){
      setLoading(true)
      setError(null)

      const options: IIntegrationGoodsCategoryThresholds = {
        goodsCategoryCode:goodsCode,
        value:+value
      }
      IntegrationsService.setGoodsCategoryThreshold(options)
        .then(res=>{
          // console.log(res.data, 'chane field')

          rulesStore.changeGoodsCategories(goodsCode, +value)
          setEdit(false)
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            // console.log(err, 'err change field')
            setLoading(false)
            setError(err?.response?.data?.message)
          }
        })
    }
  }

  return (
    <>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          <Typography noWrap>{value} %</Typography>
          <IconButton size='small' onClick={()=>setEdit(true)}>
            <Edit fontSize='small'/>
          </IconButton>
        </Stack>
      }
      {isEdit &&
        <InputEditField
          initValue={`${value ? value : 0}`}
          inputType='number'
          placeholder='Введите план'
          onClose={()=>setEdit(false)}
          onSubmit={changeField}
          error={isError}
          loading={isLoading}
          fullWidth
        />
      }
    </>
  )
}
