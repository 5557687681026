import { makeAutoObservable } from "mobx";
import { IGoodItem } from "../components/Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem";
import ReferencesService from "../services/references/references.service";
import { AxiosError } from "axios";

class GoodsStore {
    
  goods:IGoodItem[] | null = null
  fix: string = ''

  constructor(){
    makeAutoObservable(this)
  }

  setGoods(goods:IGoodItem[]){
    this.goods = goods
  }
  setFixValue(newValue: string){
    this.fix = newValue
  }
  getGoodsItems(){
    ReferencesService.getAllGoods()
      .then(res=>{
        // console.log(res, 'res goods all')
        this.setGoods(res.data)
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          // console.log(err, 'err goods all')
        }
      })
  }

}

export default new GoodsStore()