import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { GoodsCategoryTable } from './ui/GoodsCategoryTable/GoodsCategoryTable'

export const RulesPage = () => {
  return (
    <AsideCardLayout sx={{overflow:'auto'}}>
      <GoodsCategoryTable/>
    </AsideCardLayout>
  )
}
