import {FC, useEffect, useState} from 'react'
import { IOrdSelectEridText } from './IOrdSelectEridText'
import { Box, SelectChangeEvent, Skeleton, Stack, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { CustomSelect } from '../../../../../../../../components/CustomSelect/CustomSelect'
import { observer } from 'mobx-react-lite'
import { EditModalField } from '../../../../../../../../components/Table/ui/Fields/EditModalField/EditModalField'
import PaymentsService from '../../../../../../../../services/payments/payments.service'
import { IordTextInsertRequest } from '../../../../../../../../services/payments/IPaymentsRequest'
import { AxiosError } from 'axios'
import ordTextStore from '../../../../../../../../store/ordText.store'
import { CustomIconButton } from '../../../../../../../../components/Buttons/CustomIconButton/CustomIconButton'

export const OrdSelectEridText:FC<IOrdSelectEridText> = observer((props) => {
  const {
    field,
    onChange,
    text,
    title,
    id,
    readOnly,
    ordInfo
  } = props

  const [showModalAddedText, setShowModalAddedText] = useState(false)
  const [loadingAddedText, setLoadingAddedText] = useState(false)

  const addedOrdText = (text:string) => {
    setLoadingAddedText(true)
    const option: Omit<IordTextInsertRequest, "goodsCategory"> = {
      text,
    }
    PaymentsService.eridTextInsert(option)
      .then(res=>{
        ordTextStore.addOrdEridText({name:res.data?.text, value:`${res.data?.id}`})
        onChange(res.data as any, field, 'attachment', id)
        setShowModalAddedText(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){

        }
      })
      .finally(()=>{
        setLoadingAddedText(false)
      })
  }

  const changeSelect = (event:SelectChangeEvent<string>) => {
    onChange({text:ordTextStore.ordEridTexts?.find(x=>x?.value === event.target.value)?.name || '', id:event.target.value} as any, field, 'attachment', id)
  }

  useEffect(()=>{
    if(ordTextStore.ordEridTexts && !text){
      const currentText = ordTextStore.ordEridTexts.find(x=>x?.value === '1')
      onChange(text ? ordInfo.eridText : {text:currentText?.name || '', id:currentText?.value || ''} as any, field, 'attachment', id)
    }
  },[ordTextStore.ordEridTexts])
  
  return (
    <>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Stack direction={'row'} alignItems={'center'} sx={{width:'50%'}}>
          <Typography noWrap>{title}</Typography>        
          <CustomIconButton size='small' onClick={()=>setShowModalAddedText(true)} showBackground sx={{ml:0.5}}>
            <Add fontSize='small'/>
          </CustomIconButton>   
        </Stack>    
        <Box sx={{width:'50%'}}>
          {ordTextStore.ordEridTexts 
            ? <CustomSelect
                content={ordTextStore.ordEridTexts}
                value={text ? text : ordTextStore.ordEridTexts.find(x=>x?.value === '1')?.value || ''}
                onChange={changeSelect}
                disabled={readOnly}
                placeholder='Выберите значение'
                fullWidth
                noWrap
              />
            : <Skeleton variant='rounded' sx={{minHeight:37, maxHeight:37}}/>
          }
        </Box>
      </Stack>
      {showModalAddedText &&
        <EditModalField
          title='Добавление нового текста erid'
          open={showModalAddedText}
          onClose={()=>setShowModalAddedText(false)}
          onSubmit={addedOrdText}
          loading={loadingAddedText}
          placeholder='Введите текст'
          subInputText='Текст erid:'
          textCancel='Отмена'
          textSubmit='Добавить'
        />
      }
    </>
  )
})