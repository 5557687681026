import {FC, useEffect, useState} from 'react'
import {ITechnicalTask} from './ITechnicalTask'
import {Autocomplete, Link, Stack, TextField, Typography} from '@mui/material'
import {Close} from '@mui/icons-material'
import {AxiosError} from 'axios'
import {IReferencesTzResponse} from '../../../../../../services/references/IReferencesResponse'
import IntegrationsService from '../../../../../../services/integration/integrations.service'
import {IUpdateStatusesRequest} from '../../../../../../services/integration/IntegrationsRequest'
import integrationTableStore from '../../../../../../store/integrationTable.store'
import {observer} from 'mobx-react-lite'
import allTzStore from '../../../../../../store/allTz.store'
import {NotificationAlerts} from '../../../../../../components/NotificationAlerts/NotificationAlerts'
import {RemoveItemButton} from "../../../../../../components/RemoveItemButton/RemoveItemButton";
import { EditIconButton } from '../../../../../../components/Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../../../../../../components/Buttons/CustomIconButton/CustomIconButton'

export const TechnicalTask: FC<ITechnicalTask> = observer((props) => {
  const {
    readOnly,
    integrationId,
    update_id,
    minWidth,
    setStateValue,
    iniValue,
    onChangeUpdateId,
    channelType,
    setLink,
    setGoods,
    setGoodsCategoryCode,
    goodsCategoryCode,
    maxWidth,
    width
  } = props

  const [isEdit, setEdit] = useState(false)

  const [isError, setError] = useState<string | null>(null)
  const [isLoading, setLoading] = useState(false)

  const [value, setValue] = useState<IReferencesTzResponse | null>(iniValue)
  const [inputValue, setInputValue] = useState('')

  const filteredTzItems = allTzStore.tzItems
    ?.filter(x=>x.allowUsage === true)
    ?.filter(tz =>
      tz?.types?.some(type => type?.code === channelType) &&
      (!goodsCategoryCode || tz?.goodsCategory?.code === goodsCategoryCode)
    )
  || [];
  
  const onChangeTz = (event: any, newValue: IReferencesTzResponse | null) => {
    if (newValue) {
      setLoading(true)
      setError(null)

      const option: IUpdateStatusesRequest = {
        id: integrationId,
        field: 'tz',
        new_value: newValue?.id as any,
        update_id
      }
      IntegrationsService.updateSimpleField(option)
        .then((res) => {
          // console.log(res, 'res uodate tz')
          integrationTableStore.updateTz(integrationId, res.data.update_id, newValue, res.data?.goods, res.data?.goodsCategoryCode, res.data.goodsCategory)
          setValue(newValue)

          if (setStateValue) {
            setStateValue(newValue)
          }

          if (onChangeUpdateId) {
            onChangeUpdateId(res.data.update_id)
          }

          if (setGoods) {
            setGoods(res.data.goods)
          }

          if (setLink) {
            setLink(res.data.link as any)
          }

          if (setGoodsCategoryCode) {
            setGoodsCategoryCode(res.data?.goodsCategoryCode)
          }
          setLoading(false)
          setEdit(false)
        })
        .catch(err => {
          if (err instanceof AxiosError) {
            // console.log(err, 'err update tz')
            setError(err?.response?.data?.message)
            setLoading(false)
          }
        })
    }
  }

  const removeTz = () => {
    setLoading(true)

    const option: IUpdateStatusesRequest = {
      id: integrationId,
      field: 'tz',
      new_value: null as any,
      update_id
    }
    IntegrationsService.updateSimpleField(option)
      .then((res) => {
        // console.log(res, 'res uodate tz')
        integrationTableStore.updateTz(integrationId, res.data.update_id, null as any, res.data?.goods, res.data?.goodsCategoryCode, res.data.goodsCategory)
        setValue(null)

        if (setStateValue) {
          setStateValue(null)
        }

        if (onChangeUpdateId) {
          onChangeUpdateId(res.data.update_id)
        }

        if (setGoods) {
          setGoods(res.data.goods)
        }

        if (setLink) {
          setLink(res.data.link as any)
        }

        if (setGoodsCategoryCode) {
          setGoodsCategoryCode(res.data?.goodsCategoryCode)
        }

        setEdit(false)
        setLoading(false)
      })
      .catch(err => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err update tz')
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  useEffect(() => {
    setValue(iniValue)
  }, [iniValue])

  return (
    <>
      <Stack spacing={0.5} width={isEdit ? width : 'auto'} maxWidth={maxWidth}>
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          {!isEdit && value &&
            <Link
              href={value?.link}
              target='_blank'
              color={'secondary'}
              sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}
              variant='body1'
            >
              {value?.id}
            </Link>
          }
          {!readOnly&&!isEdit && value === null && <Typography sx={{whiteSpace: 'nowrap'}}>Добавить ТЗ</Typography>}
          {!readOnly && !isEdit &&
					  <Stack direction={'row'}>
              <EditIconButton size='small' onClick={() => setEdit(true)} disabled={isLoading}/>
              {value !== null &&
                <RemoveItemButton onClick={removeTz} disabled={readOnly || isLoading}/>
              }
					  </Stack>
          }
        </Stack>
        {isEdit &&
				  <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <Autocomplete
              value={value}
              onChange={(event: any, newValue: IReferencesTzResponse | null) => {
                if (newValue === null) {

                } else {
                  onChangeTz(event, newValue)
                }
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
              }}
              disabled={isLoading}
              disablePortal
              options={filteredTzItems}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => `${option.id} ${option.name}`}
              size='small'
              sx={{minWidth: minWidth ? minWidth : 'inherit'}}
              fullWidth
              renderInput={(params) =>
                <TextField
                  {...params}
                  placeholder='Выберите техническое задание'
                />
              }
              noOptionsText='Ничего не найдено'
            />
            <CustomIconButton size='small' onClick={() => setEdit(false)} disabled={isLoading}>
              <Close fontSize='small'/>
            </CustomIconButton>
				  </Stack>
        }
      </Stack>
      <NotificationAlerts
        error={{text: isError ? isError : '', open: isError ? true : false, onClose: () => setError(null)}}
      />
    </>
  )
})