import {FC, useEffect, useState} from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { IMotivationCalcAll, IMotivationCoefsSizeAudienctResponse, IMotivationPercentForSalesResponse, IMotivationRoiRow, IMotivationSearchPricesResponse } from '../../../../../../services/motivation/IMotivationResponse'
import { IMotivationCoefsRequest, IMotivationReleaseRequest, IMotivationSearchPricesRequest } from '../../../../../../services/motivation/IMotivationRequest'
import MotivationService from '../../../../../../services/motivation/motivation.service'
import { AxiosError } from 'axios'
import { Stack } from '@mui/system'
import { Box, Button, CircularProgress, Skeleton, Typography } from '@mui/material'
import { SearchMontDatePicker } from '../../../../../../pages/DashboardPage/pages/MotivationPage/ui/SearchMontDatePicker/SearchMontDatePicker'
import { IUserReleaseTables } from './IUserReleaseTables'
import { MotivationAllTables } from '../../../../../../pages/DashboardPage/pages/MotivationPage/ui/MotivationAllTables/MotivationAllTables'
import { AsideCardLayout } from '../../../../../AsideCardLayout/AsideCardLayout'

export const UserReleaseTables:FC<IUserReleaseTables> = (props) => {
  const {userId} = props

  const [date, setDate] = useState<Dayjs | null>(dayjs())

  const [coefs, setCoefs] = useState<IMotivationRoiRow[] | null>(null)
  const [sizeAudience, setSizeAudience] = useState<null | IMotivationCoefsSizeAudienctResponse[]>(null)

  const [isLoading, setLoading] = useState(false)
  const [isLoadingSearch, setLoadingSearch] = useState(false)

  const [percentForSales, setPercentForSales] = useState<IMotivationPercentForSalesResponse[] | null>(null)
  const [isLoadingPercentForSales, setLoadingPercentForSales] = useState(false)

  const [releaseMotivationByUser, setReleaseMotivationByUser] = useState<IMotivationCalcAll | null>(null)
  const [allSearchTable, setAllSearchTable] = useState<IMotivationSearchPricesResponse | null>(null)
  const [isLoadingByUser, setLoadingByUser] = useState(false)

  const getAllSearch = () => {
    setLoadingSearch(true)

    MotivationService.searchPrices()
      .then(res=>{
        setAllSearchTable(res.data)
        setLoadingSearch(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoadingSearch(false)
        }
      })
  }

  const getMotivationCoef = () => {
    if(date){
      setLoading(true)

      const option:IMotivationCoefsRequest = {
        month:date?.month()+1,
        userId,
        year:date?.year()
      }
      MotivationService.getNewMotivationCoefs(option)
        .then(res=>{
          setCoefs(res.data.roiRows)
          setSizeAudience(res.data.sizeAudience)
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            setLoading(false)
          }
      })
    }
  }

  const getReleaseMotivationByUser = (options:IMotivationReleaseRequest) => {
    setLoadingByUser(true)

    const option:IMotivationReleaseRequest = {
      month:options.month,
      userId:options.userId,
      year:options.year
    }
    MotivationService.motivationCalcAll(option)
      .then(res=>{
        setReleaseMotivationByUser(res.data)
        setLoadingByUser(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoadingByUser(false)
        }
      })
  }

  const changeDateReleaseTables = () => {

    if(date && date.isValid()){
      getMotivationCoef()
      getReleaseMotivationByUser({month:date?.month()+1, userId, year:date?.year()})
    }

    if(userId === 8 && date){
      getAllSearch()
      getPercentForSales({month:date?.month()+1, userId, year:date?.year()})
    }

  }

  const getPercentForSales = (params:IMotivationSearchPricesRequest) => {
    setLoadingPercentForSales(true)
    
    MotivationService.percentForSales(params)
      .then(res=>{
        setPercentForSales(res.data)
        setLoadingPercentForSales(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoadingPercentForSales(false)
        }
      })
  }

  useEffect(()=>{
    getMotivationCoef()

    if(date && date.isValid()){
      getReleaseMotivationByUser({month:date?.month()+1, userId, year:date?.year()})
    }

    if(userId === 8 && date){
      getAllSearch()
      getPercentForSales({month:date?.month()+1, userId, year:date?.year()})
    }

  },[])
 
  return (
    <Box
      sx={{
        maxHeight:'100%',
        overflow:'auto',
        pt:0.5,
      }}
    >
      {isLoading &&
        <Skeleton variant='rounded' sx={{height:400}}/>
      }
      {!isLoading && coefs &&
        <AsideCardLayout sx={{mb:2, mt:2}}>
          <Stack
            direction={'row'}
            justifyContent={'start'}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              spacing={1}
            >
              <SearchMontDatePicker initValue={date} onChange={setDate}/>
              <Button
                variant='contained'
                onClick={changeDateReleaseTables} 
                disabled={isNaN(date?.month()!) && isNaN(date?.year()!)}
              >
                применить
              </Button>
            </Stack>
          </Stack>
        </AsideCardLayout>
      }
      {isLoadingByUser && 
        <Stack 
          direction={'row'} 
          alignItems={'center'} 
          justifyContent={'center'} 
          spacing={1}
          sx={{mt:2}}
        >
          <Typography variant='h5'>Идёт загрука таблицы...</Typography>
          <CircularProgress sx={{minHeight:25, maxHeight:25, minWidth:25, maxWidth:25}}/>
        </Stack>
      }
      {!isLoadingByUser && releaseMotivationByUser &&
        <AsideCardLayout>
          <MotivationAllTables
            data={releaseMotivationByUser}
            isShowTableReleaseCoefs
            dataReleaseCoef={coefs || undefined}
            userId={userId}
            readOnlyRelease
            isShowChannelMotivation={userId === 8}
            dataSearchChannel={(userId === 8 && allSearchTable) || undefined}
            isShowTableSearchChannelPrice={userId === 8}
            readOnlyMotivationUser
            talbeHeaderColor='#f2f3f5'
            readOnlyChannelPrice={true}
            readOnlyPercentChannels
            dataSearchPercentChannels={percentForSales ? percentForSales : undefined}
            motivationType='release'
            managerId={userId}
            showDeleteIconRoiTable={false}
            showAddedSelectRoiTable={false}
            month={date ? date?.month()+1 : new Date().getMonth()+1}
            year={date?.year() || new Date().getFullYear()}
            sizeAudience={(sizeAudience !== null && sizeAudience?.length > 0) ? sizeAudience : null}
          />
        </AsideCardLayout>
      }
    </Box>
  )
}