import {useState} from 'react'
import { Stack, TextField } from '@mui/material'
import createChannelStore from '../../../../../../store/createChannel.store'
import { IChannelCreateComment } from '../../../../../../services/channel/IChannelRequest'
import userStore from '../../../../../../store/user.store'
import { CustomButton } from '../../../../../../components/Buttons/CustomButton/CustomButton'

export const CreateAsideInputField = () => {
  const [value, setValue] = useState('')

  const addComment = () => {
    if(userStore.user){
      const item:IChannelCreateComment = {
        text:value,
        type:['user_comment'],
        postedById:userStore.user?.id
      }
      createChannelStore.setPostedComment(item)
      setValue('')
    }
  }

  const onKeyDownSend = (e:React.KeyboardEvent) => {
    if(value.trim() !==''){
      if(e.code === "Enter" || e.code === "NumpadEnter"){
        addComment()
      }
    }
  }

  return (
    <Stack direction={'row'}>
      <TextField
        value={value}
        onChange={e=>setValue(e.target.value)}
        size='small'
        fullWidth
        placeholder='Введите комментарий'
        onKeyDown={onKeyDownSend}
        sx={{"& fieldset": {borderTopRightRadius:'0px', borderBottomRightRadius:'0px'}}}
      />
      <CustomButton
        size='small'
        variant='contained'
        onClick={addComment}
        disabled={value.trim() === ''}
        sx={{borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px'}}
      >
        добавить
      </CustomButton>
    </Stack>
  )
}
