import {FC, useState} from 'react'
import { AlertModal } from '../../../../../../../../components/AlertModal/AlertModal'
import { IGoodsAddModal } from './IGoodsAddModal'
import { observer } from 'mobx-react-lite'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, Stack, TextField } from '@mui/material'
import categoriesGoodsStore from '../../../../../../../../store/categoriesGoods.store'
import { getOthersGoodsCategories } from './shared/dataList'
import IntegrationsService from '../../../../../../../../services/integration/integrations.service'
import rulesStore from '../../../../../../../../store/rules.store'
import { IIntegrationGoodsCategoryThresholds } from '../../../../../../../../services/integration/IIntegrationsResponse'
import { AxiosError } from 'axios'

export const GoodsAddModal:FC<IGoodsAddModal> = observer((props) => {
  const {onClose, open, initCodes} = props

  const [category, setCategory] = useState('')
  const [value, setValue] = useState('')

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string)
  }

  const onSubmit = () => {
    if(value?.trim() !== '' && category?.trim() !== ''){
      setLoading(true)
      setError(null)

      const options: IIntegrationGoodsCategoryThresholds = {
        goodsCategoryCode:category,
        value:+value
      }
      IntegrationsService.setGoodsCategoryThreshold(options)
        .then(res=>{
          // console.log(res.data, 'chane field')

          rulesStore.addGoodsCategories(res.data)
          setLoading(false)
          onClose()
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            // console.log(err, 'err change field')
            setLoading(false)
            setError(err?.response?.data?.message)
          }
        })
    }
  }

  return (
    <AlertModal
      title='Добавить категорию'
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      buttonSubmitName='Добавить'
      buttonCancelName='Отмена'
      disabled={value?.trim() === '' || category?.trim() === ''}
      subContent={
        <Stack spacing={2}>
          {categoriesGoodsStore.categories
            ? <FormControl fullWidth>
                <InputLabel id="simple-select" size='small'>Категория товаров</InputLabel>
                <Select
                  size={'small'}
                  labelId="simple-select"
                  value={category}
                  label="Категория товаров"
                  onChange={handleChange}
                  disabled={isLoading}
                >
                  {getOthersGoodsCategories(categoriesGoodsStore.categories, initCodes).map(item=>
                    <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)
                  }
                </Select>
              </FormControl>
            : <Skeleton variant='rounded' sx={{height:40, width:'100%'}}/>
          }
          <TextField
            value={value}
            onChange={e=>setValue(e.target.value)}
            size='small'
            placeholder='Введите порог ROI (%)'
            type='number'
          />
        </Stack>
      }
    />
  )
})