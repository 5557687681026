import {FC} from 'react'
import { IAsideCardHeader } from './IAsideCardHeader'
import { Box, Divider, Stack, Typography } from '@mui/material'

export const AsideCardHeader:FC<IAsideCardHeader> = (props) => {
  const {
    title, 
    rightContent, 
    sx,
    requiredTitle,
    isErrorTitle,
    headerContent,
    headerIcon,
    headetTextSx
  } = props

  return (
    <Stack spacing={1}>
      <Stack
        direction={'row'} 
        alignItems={'center'} 
        justifyContent={'space-between'} 
        sx={{...sx}}
      >
        <Box sx={{display:'inline-flex', alignItems:'center'}}>
          {headerIcon && headerIcon}
          <Typography
            variant="h6"
            fontWeight={'bold'}
            sx={{textTransform:'uppercase', mr:headerContent ? 1 : 0, ml:headerIcon ? 1 : 0, ...headetTextSx}}
            color={isErrorTitle ? 'error' : 'inherit'}
          >
            {title} {requiredTitle ? <Typography component={'span'} color={'error'} fontSize={'1rem'}>*</Typography> : null}
          </Typography>
          {headerContent}
        </Box>
        {rightContent && rightContent}
      </Stack>
      <Divider/>
    </Stack>
  )
}