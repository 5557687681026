import {FC, useState} from 'react'
import { IReleaseList } from './models/IReleaseList'
import { Avatar, Box, IconButton, Link, Menu, Stack, Typography } from '@mui/material'
import channelStore from '../../store/channel.store'
import { ErrorOutline} from '@mui/icons-material'
import { NotificationAlerts } from '../NotificationAlerts/NotificationAlerts'
import { ReleaseItem } from './ui/ReleaseItem'
import ReleasesService from '../../services/releases/releases.service'
import { AxiosError } from 'axios'
import integrationTableStore from '../../store/integrationTable.store'
import { IReleasesRequestEdit } from '../../services/releases/IReleasesRequest'

export const ReleaseList:FC<IReleaseList> = (props) => {
  const {
    array, 
    fullWidth = false, 
    minWidth, 
    id, 
    readOnly
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string |  null>(null)

  const [copyAlert, setCopyAlert] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
    
  const currentIcon = channelStore?.channels?.[array[0]?.type?.code]

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as any)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const copyText = (text:string) => {
    navigator.clipboard.writeText(text)
    setAnchorEl(null)
    setCopyAlert(true)
  }

  const removeItem = (itemId:number) => {
    setLoading(true)

    ReleasesService.removeReleaes(itemId)
      .then(res=>{
        // console.log(res, 'res remove releases in list')
        integrationTableStore.removeRelese(id, array.filter(item=>item.id !== itemId))
        integrationTableStore.updateSimpleField(+id, res.data?.integration?.update_id, 'views', res.data?.integration?.views)
        handleClose()
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err remove relese item in list')
          setLoading(false)
        }
      })
  }

  const editItem = (item:IReleasesRequestEdit) => {
    setLoading(true)
    setError(null)

    const option:IReleasesRequestEdit = {
      id:item.id!,
      comments:item.comments,
      likes:item.likes,
      link:item.link,
      views:item.views,
      type:item.type,
      releaseDate:item.releaseDate,
      integrationPlace:item.integrationPlace
    }

    ReleasesService.releaesEdit(option)
      .then(res=>{
        // console.log(res, 'res edit in modal')

        if(id && res.data.id){
          integrationTableStore.editRelese(id, res.data.id, res.data, res.data?.integration?.update_id)
          integrationTableStore.updateSimpleField(+id, res.data?.integration?.update_id, 'views', res.data?.integration?.views)
        }
        handleClose()
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'res err edit vodal')
          setLoading(false)
          setError(Array.isArray(err.response?.data?.message) ? err.response?.data?.message?.join(', ') : err.response?.data?.message)
        }
      })
  }

  const editPlacement = (item:IReleasesRequestEdit, placement:string) => {
    setLoading(true)

    const option:IReleasesRequestEdit = {
      id:item.id!,
      comments:item.comments,
      likes:item.likes,
      link:item.link,
      views:item.views,
      type:item.type,
      releaseDate:item.releaseDate,
      integrationPlace:placement
    }

    ReleasesService.releaesEdit(option)
      .then(res=>{
        // console.log(res, 'res edit in modal')

        if(id && res.data.id){
          integrationTableStore.editRelese(id, res.data.id, res.data, res.data?.integration?.update_id)
        }
        handleClose()
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'res err edit vodal')
          setLoading(false)
        }
      })
  }

  return (
    <>
      <Box sx={{display:'grid', maxWidth:fullWidth ? '100%' :'220px'}}>
        <Box sx={{display:'grid', alignItems:'center', gridTemplateColumns:'min-content 1fr min-content'}}>
          <Avatar src={currentIcon?.icon} alt={currentIcon?.name} sx={{height:25, width:25, mr:1}}/>
          <Link color={'secondary'} href={array[0].link} target='_blank' sx={{textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>{array[0].link}</Link>
          <Stack direction={'row'} alignItems={'center'}>
            <IconButton
              color={'default'}
              onClick={(e)=>handleClick(e)}
            >
              <ErrorOutline fontSize='small'/>
            </IconButton>
            <Typography>({array.length})</Typography>
          </Stack>
        </Box>
        {<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {array.map(link=>
            <ReleaseItem
              id={link.id}
              key={link.id + link.link}
              link={link.link}
              onClick={handleClose}
              onCopyText={()=>copyText(link.link)}
              type={link.type}
              releaseDate={link.releaseDate}
              integrationPlace={link.integrationPlace}
              comments={link.comments}
              likes={link.likes}
              views={link.views}
              fullWidth={fullWidth}
              minWidth={minWidth}
              onDelete={removeItem}
              isEditMode
              parentId={id}
              onEdit={editItem}
              loading={isLoading}
              readOnly={readOnly}
              lastUpdate={link.lastUpdate}
              wrap
              onChangePlacement={editPlacement}
            />)
          }
        </Menu>}
      </Box>
      <NotificationAlerts
        sucsess={{text:'Успешно скопировалось!', autoHideDuration:1000, open:copyAlert, onClose:()=>setCopyAlert(false)}}
        error={{onClose:()=>setError(null), open:Boolean(isError), text:isError ? isError : ''}}
      />
    </>
  )
}