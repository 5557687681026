import {FC, useState} from 'react'
import { IAsideOrdersQuanity } from './IAsideOrdersQuanity'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { getFieldStatisticsOrders, getStatisticsOrders } from '../../pages/Main/shared/dataList'
import { Box, Button } from '@mui/material'
import { AsideTableStatistic } from '../AsideTableStatistic/AsideTableStatistic'
import { AsideModalOrders } from '../AsideModalOrders/AsideModalOrders'

export const AsideOrdersQuanity:FC<IAsideOrdersQuanity> = (props) => {
  const {dataOrder, price} = props

  const [isOpenAllOrders, setOpenAllOrders] = useState(false)

  const changeOpenAllOreders = (value:boolean) => {
    setOpenAllOrders(value)
  }

  return (
    <>
      <AsideCardLayout>
        <Box 
          sx={{
            display:'grid', 
            gridTemplateColumns:{xs:'1fr', sm:'1fr max-content'}, 
            columnGap:2, 
            alignItems:'center',
            rowGap:2
          }}
        >
          <AsideTableStatistic rows={getStatisticsOrders()} dataField={getFieldStatisticsOrders(dataOrder, +price)} />
          <Button
            variant="outlined"
            onClick={()=>changeOpenAllOreders(true)}
            sx={{whiteSpace:'nowrap', height:'fit-content'}}
          >
            все заказы
          </Button>
        </Box>
       </AsideCardLayout>
        <AsideModalOrders open={isOpenAllOrders} onClose={()=>changeOpenAllOreders(false)} orders={dataOrder.orders}/>
    </>
  )
}
