import { IFilterSelectItem } from "../../../../../../../../../components/FilterSelectChip/IFilterSelectChip";
import { IGoodCategoriesItem } from "../../../../../../../../../services/references/IReferencesResponse";

export const getOthersGoodsCategories = (allCategory:IGoodCategoriesItem[], initCategoryCodes:string[]):IFilterSelectItem[] => {
  const totalCategory:IGoodCategoriesItem[] = allCategory.filter(x=>!initCategoryCodes.includes(x.code))

  return totalCategory.map(item=>{
    const newItem:IFilterSelectItem = {
      name:item.name,
      value:item.code
    }
    return newItem
  })

}