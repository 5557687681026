import {FC} from 'react'
import { observer } from 'mobx-react-lite'
import channelStore from '../../../store/channel.store'
import { toJS } from 'mobx'
import { IAsideReleaseForm } from '../models/IAsideReleaseForm'
import { Comment, Favorite, Visibility } from '@mui/icons-material'
import { Avatar, Box, Button, CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import 'dayjs/locale/ru'
import releasePlacementsStore from '../../../store/releasePlacements.store'

export const AsideReleaseForm:FC<IAsideReleaseForm> = observer((props) => {
  const {
    typeValue,
    error = false,
    loading = false,
    onClose,
    onSubmit,
    linkValue,
    onChangeLink,
    viewValue,
    onChangeView,
    likeValue,
    onChangeLike,
    commentsValue,
    onChangeComments,
    selectValueRelease,
    onChangeSelect,
    dateValue,
    onChangeDate,
    timeValue,
    onChangeTime,
    placementValue,
    onChangePlacement
  } = props

  return (
  <Box sx={{p:1,pb:0,pt:0}}>
    <Stack direction={'column'} spacing={1}>
      <Typography>Ссылка релиза: <Typography component={'span'} color={'error'}>*</Typography></Typography>
      <TextField
        size='small'
        fullWidth
        placeholder='Добавьте ссылку'
        value={linkValue}
        onChange={onChangeLink}
        disabled={loading}
      />
      <Typography>Статистика:</Typography>
      <Stack direction={{xs:"column", sm:'row'}} spacing={1}>
        <TextField
          size='small'
          placeholder='Просмотры'
          type='number'
          value={viewValue}
          onChange={onChangeView}
          disabled={loading}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                  <Visibility color='action'/>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          size='small'
          placeholder='Лайки'
          type='number'
          value={likeValue}
          onChange={onChangeLike}
          disabled={loading}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Favorite color='error'/>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          size='small'
          placeholder='Комментарии'
          type='number'
          value={commentsValue}
          onChange={onChangeComments}
          disabled={loading}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Comment color='disabled'/>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack>
        <Typography sx={{mb:1}}>Выберите площадку: <Typography component={'span'} color={'error'}>*</Typography></Typography>
        <FormControl>
          <InputLabel id="demo-simple-select-label" size='small'>Тип</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label='Тип'
            size='small'
            value={selectValueRelease}
            onChange={onChangeSelect}
            disabled={loading}
            renderValue={(val)=>
              <Box sx={{display:'flex', alignItems:'center'}}>
                <Avatar src={channelStore?.channels![val]?.icon} sx={{height:20, width:20, mr:1}}/>
                <Typography variant='body2'>{channelStore?.channels![val]?.name}</Typography>
              </Box>
            }
          >
            {Object.keys(toJS(channelStore).channels!).map(item=>
              <MenuItem key={item} value={item}>
                <Avatar src={channelStore?.channels![item]?.icon} sx={{height:25, width:25, mr:1}}/>
                <Typography>{channelStore?.channels![item]?.name}</Typography>
              </MenuItem>)
            }
          </Select>
          {typeValue&&selectValueRelease!==typeValue&&<Typography color={'#f44336'}>Обратите внимание, выбранная площадка отличается от площадки канала</Typography>}
        </FormControl>
      </Stack>
      <Stack>
        <Typography sx={{mb:1}}>Местоположение рекламы:</Typography>
        <FormControl>
          <InputLabel id="select-label" size='small'>Позиция</InputLabel>
          <Select
            labelId="select-label"
            label='Позиция'
            size='small'
            value={placementValue}
            onChange={onChangePlacement}
            disabled={loading}
          >
            {releasePlacementsStore.placements?.map(item=>
              <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>)
            }
          </Select>
        </FormControl>
      </Stack>
      <Stack spacing={1}>
        <Typography>Выберите дату: <Typography component={'span'} color={'error'}>*</Typography></Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
          <DatePicker
            value={dateValue}
            onChange={(val:any)=>onChangeDate(val)}
            disabled={loading}
            slotProps={{textField:{size:'small', placeholder:'дд.мм.год'}}}
          />
        </LocalizationProvider>
      </Stack>
      <Stack spacing={1}>
        <Typography>Выберите время: <Typography component={'span'} color={'error'}>*</Typography></Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
          <TimePicker
            value={timeValue}
            onChange={(val:any)=>onChangeTime(val)}
            disabled={loading}
            slotProps={{textField:{size:'small', placeholder:'часы:минуты'}}}
          />
        </LocalizationProvider>
      </Stack>
      {error && <Typography color={'error'}>Заполните все поля</Typography>}
      <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
        <Button variant='outlined' onClick={onClose} disabled={loading}>Отмена</Button>
        <Button
          variant='contained'
          disabled={loading}
          onClick={onSubmit}
          endIcon={loading && <CircularProgress sx={{maxHeight:20, maxWidth:20}} />}
        >
          Сохранить
        </Button>
      </Stack>
    </Stack>
  </Box>
  )
})