import {FC, memo} from 'react'
import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { AgrementIcons } from '../AgrementIcons/AgrementIcons'
import { IWrapperCellIcons } from './IWrapperCellIcons'
import { Chat } from '@mui/icons-material'

export const WrapperCellIcons:FC<IWrapperCellIcons> = memo((props) => {
  const {userApprovments, id} = props
  
  return (
    <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
      <AgrementIcons userApprovments={userApprovments} id={id}/>
      {userApprovments.map(x=>x.comment ? true : false).find(x=>x === true)
        ? <Tooltip
            slotProps={{
              tooltip:{sx:{background:'rgb(89,90,91)'}}}
            }
            placement='bottom-end'
            title={
              <Box>
                {userApprovments.map((item, i, arr)=>
                  <>
                    {item.comment
                      ? <Box key={item.user?.id + id} sx={{mb:0.5}}>
                          <Typography fontWeight={'bold'}>{item.user?.firstName} {item.user?.lastName}:</Typography>
                          <Typography>{item.comment}</Typography>
                          {(i < userApprovments?.length-1 && arr[i+1]?.comment) ? <Divider sx={{backgroundColor:'#fff', mt:0.5}}/> : null}
                        </Box>
                      : null
                    }
                  </>
                )}
              </Box>
            }
          >
            <Chat fontSize='small' color='action' sx={{":hover":{color:'#ed6c02'}, transition:'all ease .3s'}}/>
          </Tooltip>
        : null
      }
    </Stack>
  )
})
