import { FC, useState, useEffect} from 'react'
import { CircularProgress, Skeleton, Stack, Typography } from '@mui/material'
import { CustomSelect } from '../../../../../../../../components/CustomSelect/CustomSelect'
import ReferencesService from '../../../../../../../../services/references/references.service'
import { AxiosError } from 'axios'
import { IFilterSelectItem } from '../../../../../../../../components/FilterSelectChip/IFilterSelectChip'
import MotivationService from '../../../../../../../../services/motivation/motivation.service'
import { IAddedCategorySelect } from './IAddedCategorySelect'
import dashboardStore from '../../../../../../../../store/dashboard.store'
import { observer } from 'mobx-react-lite'
import { CustomButton } from '../../../../../../../../components/Buttons/CustomButton/CustomButton'

export const AddedCategorySelect:FC<IAddedCategorySelect> = observer((props) => {
  const {
    month = 0,
    year = 0,
    userId
  } = props

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [isLoadingList, setLoadingList] = useState(false)
  const [list, setList] = useState<null | IFilterSelectItem[]>(null)
  const [categoryValue, setValue] = useState('')
  
  const getListCategories = () => {
    setLoadingList(true)

    ReferencesService.goodsCategoryForMotivation({userId, month, year})
      .then(res=>{

        setList(prev=>res.data?.map(x=>{
          const item:IFilterSelectItem = {
            name:x?.name,
            value:x?.code,
          }
          return item
        }))

        setLoadingList(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoadingList(false)
        }
      })
  }

  const addCategory = () => {
    if(categoryValue!=='' && month!==0 && year!==0){
      setLoading(true)

      MotivationService.addGoodsCategoryInMotivation({code:categoryValue, month, year})
        .then(res=>{
          dashboardStore.setReleaseMotivation(res.data?.roiRows)

          // setList(prev=>prev?.filter(x=>x?.value !== categoryValue) || [])
          setValue('')

          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            setLoading(false)
          }
        })
    }
  }

  useEffect(()=>{
    getListCategories()
  },[dashboardStore.releaseMotivation])

  return (
    <Stack 
      direction={{xs:'column', sm:'row'}}
      alignItems={{xs:'start', sm:'center'}}
      spacing={{xs:1, sm:2}}
    >
      <Typography>Добавить товарную категорию</Typography>
      {list && !isLoadingList &&
        <CustomSelect
          content={list}
          onChange={(e)=>setValue(e.target.value)}
          value={categoryValue}
          disabled={isLoading}
          placeholder='Выберите категорию'
          error={Boolean(error)}
          sx={{minWidth:185}}
        />
      }
      {isLoadingList &&
        <Skeleton variant='rounded' sx={{minWidth:185, maxWidth:185, maxHeight:37.5, minHeight:37.5}}/>
      }
      <CustomButton
        variant='contained'
        onClick={addCategory}
        disabled={isLoading}
        endIcon={
          isLoading ? <CircularProgress sx={{maxHeight:25, minHeight:25, maxWidth:25, minWidth:25}}/> : null
        } 
      >
        Добавить
      </CustomButton>
    </Stack>
  )
})