import {FC, useState, useCallback, useEffect} from 'react'
import { IAgreementTableItem } from './IAgreementTableItem'
import { TableItem } from '../../../../../../components/Table/ui/TableItem'
import { observer } from 'mobx-react-lite'
import { AgreementsTable } from '../../../../../../components/AgreementsTable/AgreementsTable'
import { Box, TableCell, TableRow } from '@mui/material'
import userStore from '../../../../../../store/user.store'
import { IAgreementsResponseUpdateStatus } from '../../../../../../services/agreements/IAgreementsResponse'
import dashboardStore from '../../../../../../store/dashboard.store'
import statusesStore from '../../../../../../store/statuses.store'
import { WrapperRouteAgree } from './ui/WrapperRouteAgree/WrapperRouteAgree'
import { INTEGRATION_CARD_OPEN } from '../../../../../../shared/constants/searchParams'

export const AgreementTableItem:FC<IAgreementTableItem> = observer((props) => {
  const {
    cells,
    agreementId,
    userApprovments,
    integrationId,
    onNavigate,
    integrationPrice,
    agreementStatus,
    integrationDate,
    integrationProps,
    createdAt,
    allDecisionDate,
    ...restProps
  } = props
  
  const [open, setOpen] = useState(false)

  const isAgree = userApprovments.find(user=>(user.user.id === userStore.user?.id) && agreementStatus === 'agreed')
  const isNotAgree = userApprovments.find(user=>(user.user.id === userStore.user?.id) && agreementStatus === 'not_agreed')
  const isWaiting = userApprovments.find(user=>(user.user.id === userStore.user?.id) && agreementStatus === 'wait')

  const newProps = {
    ...restProps,
    ...integrationProps,
    integrationId,
    integration_date:integrationDate,
    ...{price:integrationPrice},
    createdAt,
    userApprovments,
    allDecisionDate,
    agreementId
  }

  let currentColor = '#fff'

  if(isAgree !== undefined && isNotAgree === undefined){
    currentColor = '#DAF9DA'
  } else if (isAgree === undefined && isNotAgree === undefined && isWaiting !== undefined) {
    currentColor = '#FFF6C8'
  } else if (isNotAgree !== undefined) {
    currentColor = '#FFCECE'
  } else {
    currentColor = 'inherit'
  }

  const onKeyDown = useCallback((e:any, id:number) => {
    if(e.altKey){
      
    } else {
      setOpen(prev=>!prev)
    }

  },[])

  const changeStatus = (userAprov: IAgreementsResponseUpdateStatus) => {
    if(statusesStore.agreement){

      const currentStatus = statusesStore?.agreement[userAprov.approvments[0].approvedStatus.code]

      dashboardStore.changeAgreementStatus(
        userAprov.id, userAprov.approvments[0].user.id, currentStatus, userAprov.approvments[0].approvedStatus.code, userAprov.agreementStatus.code,  userAprov.approvments[0]?.decisionDate, userAprov?.allDecisionDate
      )
    }
  }

  const navigateAside = useCallback(((id:any) => {
    onNavigate(`${integrationId}`)
  }), [])

  // console.log('render', agreementId)

  useEffect(()=>{
    if(dashboardStore.searchIntegrationId === integrationId){
      setTimeout(()=>{
        dashboardStore.setSearchIntegrationId(null)
      }, 1750)
    }
  }, [dashboardStore.searchIntegrationId])
 
  return (
    <WrapperRouteAgree id={`${integrationId}`} searchParamsKey={INTEGRATION_CARD_OPEN}>
      <TableItem
        tableRowId={integrationId}
        cells={cells}
        onKeyDown={onKeyDown}
        {...newProps}
        backgroundColor={currentColor}
        onNavigate={navigateAside}
        isShowNavigateIcon
        showAgreeIconsCell
        searchBackgroundColor={dashboardStore.searchIntegrationId === integrationId}
      />
      {userStore?.user &&
        <TableRow sx={{p:0, border:'none'}}>
          <TableCell sx={{p:open ? 1 : 0, transition:'0', borderBottom:!open ? 'none' : '1px solid rgba(224, 224, 224, 1)'}} colSpan={cells.length+2}>
            {open
              ? <Box sx={{width:'40%'}}>
                  <AgreementsTable
                    agreementId={agreementId}
                    userApprovments={userApprovments}
                    userId={userStore?.user?.id}
                    callback={changeStatus}
                  />
                </Box>
              : null
            }
          </TableCell>
        </TableRow>
      }
    </WrapperRouteAgree>
  )
})