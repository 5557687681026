import {useState} from "react";
import ReferencesService from "../../../../../services/references/references.service";
import {Add} from "@mui/icons-material";
import {EditModalField} from "../../Fields/EditModalField/EditModalField";
import ChannelTheame from "../../../../../store/channelTheame";
import { CustomIconButton } from "../../../../Buttons/CustomIconButton/CustomIconButton";

export const ThemeAddButtonDialog = () => {
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const addTheme = async (themeValue: string) => {
    setLoading(true)
    const res= await ReferencesService.createChannelTheme({name:themeValue})
    ChannelTheame.addTheame(res.data)
    setLoading(false)
    setOpen(false)
  }

  return (
    <>
      <CustomIconButton onClick={() => setOpen(true)} sx={{ml: 1}} size='small' showBackground>
        <Add fontSize='small'/>
      </CustomIconButton>
      <EditModalField 
        loading={isLoading} 
        textCancel={'Отмена'} 
        textSubmit={'Добавить '} 
        placeholder={'Введите значение'} 
        subInputText={'Тематика:'} 
        title={'Добавление тематики'} 
        onClose={() => {setOpen(false)}} 
        open={open} 
        onSubmit={addTheme}
        />
    </>
  )
}