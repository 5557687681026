import {FC} from 'react'
import { ISearchMontDatePicker } from './ISearchMontDatePicker'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ru'

export const SearchMontDatePicker:FC<ISearchMontDatePicker> = (props) => {
  const {initValue, onChange, disabled = false} = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
      <DatePicker
        value={initValue}
        onChange={(value)=>onChange(value)}
        label={'Выбор периода'}
        views={['month', 'year']}
        slotProps={{
          textField:{
            size:'small',
            placeholder:'месяц год'
          }
        }}
        sx={{width:'100%'}}
        disabled={disabled}
      />
    </LocalizationProvider>
  )
}