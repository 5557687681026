import {FC} from 'react'
import { IIntegrationAudienceCard } from './IIntegrationAudienceCard'
import { Box, Stack, Typography } from '@mui/material'
import { IChannelAudienceContentOnType, getChannelContentOnType } from '../../../../../../../ChannelCardPage/ui/ChannelAudience/shared/dataList'
import { Man, Woman } from '@mui/icons-material'
import { ReactComponent as RuFlag } from '../../../../../../../../images/flags/ru.svg'
import { ReactComponent as ByFlag } from '../../../../../../../../images/flags/by.svg'
import { ReactComponent as UaFlag } from '../../../../../../../../images/flags/ua.svg'
import { ReactComponent as KzFlag } from '../../../../../../../../images/flags/kz.svg'
import { pink } from '@mui/material/colors'

export const IntegrationAudienceCard:FC<IIntegrationAudienceCard> = (props) => {
  const {
    data
  } = props

  const type:string | null = data?.type?.code || null

  const list:IChannelAudienceContentOnType | null = type ? 
    getChannelContentOnType(
      data,
      {
        byIcon:<Box sx={{display:'flex', alignItems:'center'}}>
          <ByFlag height={30} width={30} style={{marginRight:'8px'}}/>
          <Typography>Белоруссия</Typography>
        </Box>,
        kzIcon:<Box sx={{display:'flex', alignItems:'center'}}>
          <KzFlag height={30} width={30} style={{marginRight:'8px'}}/>
          <Typography>Казахстан</Typography>
        </Box>,
        maleIcon:<Man color='secondary' sx={{height:30, width:30}}/>,
        ruIcon:<Box sx={{display:'flex', alignItems:'center'}}>
          <RuFlag height={30} width={30} style={{marginRight:'8px'}}/>
          <Typography>Россия</Typography>
        </Box>,
        uaIcon:<Box sx={{display:'flex', alignItems:'center'}}>
          <UaFlag height={30} width={30} style={{marginRight:'8px'}}/>
          <Typography>Украина</Typography>
        </Box>,
        womanIcon:<Woman sx={{height:30, width:30, color:pink[200]}}/>,
      }, 
      ()=>'',
      type
    )
  : null

  console.log(list, 'list')
  return (
    <Box>
      {type !== 'telegram' &&
        <Box>
          <Box sx={{display:{xs:'block', sm:'flex'}, justifyContent:'space-between'}}>
            <Box>
              <Stack spacing={1}>
                <Typography variant='h6'>География</Typography>
                <Stack spacing={1}>
                  {list?.countries?.map((item, i)=>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1} key={item.code + i}>
                      {item.icon}
                      <Typography sx={{whiteSpace:'nowrap'}}>{item.value} %</Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Box sx={{display:{xs:'none', sm:'block'}, mt:1}}>
                <Typography variant='h6'>Пол</Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  {list?.gender?.map((item, i)=>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={item.code + i}>
                      {item.icon}
                      <Typography>{item.value} %</Typography>
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Box>
            <Box>
              <Typography variant='h6' whiteSpace={'nowrap'}>Возраст аудитории</Typography>
              <Stack spacing={1} sx={{mt:1}}>
                {list?.ages?.map((item, i)=>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={item.code + i}>
                    <Typography>{item.text}</Typography>
                    <Typography>{item.value} %</Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
            <Box sx={{display:{xs:'block', sm:'none'}, mt:1}}>
              <Typography variant='h6'>Пол</Typography>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                {list?.gender?.map((item, i)=>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={item.code + i}>
                    {item.icon}
                    <Typography>{item.value} %</Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Box>
        </Box>
      }
      {type === 'telegram' &&
        <Stack spacing={1}>
          {list?.telegramStats?.map((item, i)=>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={item.code + i}>
              <Typography>{item.text}</Typography>
              <Typography>{item.value} %</Typography>
            </Stack>
          )}
        </Stack>
      }
    </Box>
  )
}
