import { useState } from 'react'
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout"
import { IIntegrationsFullResponse } from '../../../../services/integration/IIntegrationsResponse'
import { Box, Typography } from '@mui/material'
import { IBurgerItem } from '../../../../components/Table/models/ITableItem'
import { IntegrationAllSumm } from './ui/integrationAllSumm/IntegrationAllSumm'
import { FinanceIntegrationTable } from './ui/FinanceIntegrationTable/FinanceIntegrationTable'
import { useSearchParams } from 'react-router-dom'
import { CHANNEL_CARD_OPEN, INTEGRATION_CARD_OPEN } from '../../../../shared/constants/searchParams'
import { TableAsidePage } from '../../../TableAsidePage/TableAsidePage'
import { ChannelCardPage } from '../../../ChannelCardPage/ChannelCardPage'
import createPaymentStore from '../../../../store/createPayment.store'
import { observer } from 'mobx-react-lite'
import { AddIntegrationWrapperButton } from './ui/AddIntegrationWrapperButton/AddIntegrationWrapperButton'

export const FinancesIntegrations = observer(() => {

  const [searchParams, setSearchParams] = useSearchParams()

  const [integrations, setIntegrations] = useState<IIntegrationsFullResponse[]>([])

  const [isEditTotalPrice, setEditTotalPrice] = useState(false)

  const addIntegration = (item:IIntegrationsFullResponse) => {
    setIntegrations(prev=>{
      if(prev?.length===0){
        createPaymentStore.setLastPayInfo(item?.channel?.lastPayInfo || null)
      }
      return [...prev, item]
    })
  }

  const deleteIntegration = (id:number | string | undefined) => {
    if(id){
      setIntegrations(prev=>{
        createPaymentStore.setIntegrationContacts(prev.find(x=>x.id === id)?.channel?.contact!, true)
        createPaymentStore.setLastPayInfo(prev.filter(x=>x.id !== +id)?.[0]?.channel?.lastPayInfo || null)
        return prev.filter(x=>x.id !== +id)
      })
      createPaymentStore.deleteIntegrationsIds(+id)
    }
  }

  const navigateToIntegration = (id:string | number) => {
    searchParams.set(INTEGRATION_CARD_OPEN, id?.toString())
    setSearchParams(searchParams)
  }

  const [burgerItems] = useState<IBurgerItem[]>([
    {title:'Посмотреть', onNavigate:navigateToIntegration},
    {title:'Удалить', onClick:deleteIntegration},
  ])

  const closePages = (type:string) => {
    searchParams.delete(type)
    setSearchParams(searchParams)
  }

  const changeSumm = (value:string) => {
    createPaymentStore.setAllSumm(+value)
    setEditTotalPrice(false)
  }

  const changeTotalSummOnIntegration = () => {
    createPaymentStore.setAllSumm(
      integrations?.reduce((prev, cur)=>{
        return prev + cur?.price
      }, 0)
    )
  }

  return (
    <>
      <AsideCardLayout
        headerTitle='интеграции'
        rightContent={
          <Box sx={{mt:{xs:0.5, sm:0}, width:{xs:'100%', sm:'auto'}}}>
            <AddIntegrationWrapperButton setIntegrations={addIntegration}/>
          </Box>
        }
        headerSx={{flexDirection:{xs:'column', sm:'row'}, alignItems:{xs:'start', sm:'center'}}}
      >
        <Box sx={{mt:1}}>
          {integrations.length === 0 &&
            <Typography>Добавьте интеграцию</Typography>
          }
          {integrations.length > 0 &&
            <FinanceIntegrationTable integrations={integrations} burgerItems={burgerItems}/>
          }
          {integrations.length > 0 &&
            <Box sx={{mt:1.5}}>
              <IntegrationAllSumm 
                integrations={integrations}
                isEdit={isEditTotalPrice}
                setEdit={setEditTotalPrice}
                changeSumm={changeSumm}
                changeTotalSummOnIntegration={changeTotalSummOnIntegration}
                allSumm={createPaymentStore.allSumm || 0}
                readOnly
              />
            </Box>
          }
        </Box>
      </AsideCardLayout>
      {searchParams.get(INTEGRATION_CARD_OPEN) &&
        <TableAsidePage integrationId={searchParams.get(INTEGRATION_CARD_OPEN)!} onClose={()=>closePages(INTEGRATION_CARD_OPEN)}/>
      }
      {searchParams.get(CHANNEL_CARD_OPEN) &&
        <ChannelCardPage channelId={searchParams.get(CHANNEL_CARD_OPEN)!} onClose={()=>closePages(CHANNEL_CARD_OPEN)}/>
      }
    </>
  )
})