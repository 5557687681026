import {FC, useState, useEffect} from 'react'
import { ITzChannelSelect } from './ITzChannelSelect'
import { Avatar, SelectChangeEvent, Skeleton, Stack, Typography } from '@mui/material'
import { SelectCheckboxField } from '../SelectCheckboxField/SelectCheckboxField'
import channelStore from '../../../../../store/channel.store'
import { observer } from 'mobx-react-lite'
import { getTzChannelList } from './shared/dataList'
import { Check, Close } from '@mui/icons-material'
import TzService from '../../../../../services/tz/tz.service'
import { ITzUpdateSimpleFieldRequest } from '../../../../../services/tz/ITzRequest'
import { AxiosError } from 'axios'
import tzTableStore from '../../../../../store/tzTable.store'
import { EditIconButton } from '../../../../Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../../../../Buttons/CustomIconButton/CustomIconButton'

export const TzChannelSelect:FC<ITzChannelSelect> = observer((props) => {
  const {
    id,
    field,
    initValue,
    update_id,
  } = props

  const [value, setValue] = useState<string[]>(initValue ? initValue.map(item=>item?.code) : [])

  const [isLoading, setLoading] = useState(false)

  const [isEdit, setEdit] = useState(false)

  const onChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value

    setValue(typeof value === 'string' ? value.split(',') : value)
  }

  const closeEdit = () => {
    setValue(initValue ? initValue.map(item=>item?.code) : [])
    setEdit(false)
  }

  const submit = () => {
    setLoading(true)

    const option:ITzUpdateSimpleFieldRequest = {
      field,
      id,
      new_value:value?.map(item=>{
        return {code:item}
      }) as any,
      update_id
    }
    TzService.updateSimpleField(option)
      .then(res=>{

        tzTableStore.updateSimpleField(id, field, res.data[field], res.data.update_id)
        
        setLoading(false)
        setEdit(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError) {
          setLoading(false)
        }
      })
  }

  useEffect(()=>{
    setValue(initValue ? initValue.map(item=>item?.code) : [])
  },[initValue])

  return (
    <>
      <Stack 
        direction={'row'} 
        alignItems={'center'} 
        spacing={0.3}
      >
        {!isEdit &&
          <>
            {initValue?.map(item=>
                <Avatar key={item.code} src={item.icon} alt={item.name} sx={{maxHeight:20, maxWidth:20}}/>
              )
            }
            {initValue === null || initValue?.length === 0 &&
              <Typography color={'grey'}>Нет площадки</Typography>
            }
            <EditIconButton size='small' onClick={()=>setEdit(true)}/>
          </>
        }
      </Stack>
      {isEdit && 
        <Stack direction={'row'} alignItems={'center'}>
          {channelStore?.channels
            ? <SelectCheckboxField
                label={field === 'types' ? 'Площадки' : 'Товары'}
                value={value}
                onChange={onChange}
                minWidth={180}
                maxWidth={180}
                list={
                  getTzChannelList(channelStore.channels)
                }
                loading={isLoading}
              />
            : <Skeleton variant='rounded' sx={{height:40, width:40}}/>
          }
          <CustomIconButton size='small' disabled={isLoading} onClick={submit} showBackground sx={{ml:0.5}}>
            <Check fontSize='small'/>
          </CustomIconButton>
          <CustomIconButton size='small' disabled={isLoading} onClick={closeEdit}>
            <Close fontSize='small'/>
          </CustomIconButton>
        </Stack>
      }
    </>
  )
})