import {FC, useState} from 'react'
import { IMotivationChannelViews } from './IMotivationChannelViews'
import MotivationService from '../../../../../../../../services/motivation/motivation.service'
import { IMotivationRoiEditRequest } from '../../../../../../../../services/motivation/IMotivationRequest'
import { AxiosError } from 'axios'
import { InputEditField } from '../../../../../../../../components/Table/ui/Fields/InputEditField/InputEditField'
import { Avatar, Stack, Typography } from '@mui/material'
import { priceTransormFormat } from '../../../../../../../../shared/functions/functions'
import dashboardStore from '../../../../../../../../store/dashboard.store'
import {EmptyText} from "../../../../../../../../components/EmptyText/EmptyText";
import { EditIconButton } from '../../../../../../../../components/Buttons/EditIconButton/EditIconButton'

export const MotivationChannelViews:FC<IMotivationChannelViews> = (props) => {
  const {
    id,
    initValue,
    readOnly,
    channel
  } = props

  const [isEdit, setEdit] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const submit = (value:string) => {
    setLoading(true)

    const option: IMotivationRoiEditRequest = {
      id,
      propertyType:'sizeAudience',
      value:+value
    }
    MotivationService.edit(option)
      .then(res=>{
        dashboardStore.changeSizeAudience(id, res.data.value)
        setLoading(false)
        setEdit(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoading(false)
        }
      })
  }

  return (
    <Stack direction={'row'} alignItems={'center'}>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'}>
          <Avatar sx={{height:20, width:20, mr:1}} src={channel?.icon} alt={channel?.name}/>
          <Typography>{(initValue !== null && initValue !== undefined) ? priceTransormFormat(initValue, false) :  <EmptyText>значение отсутствует</EmptyText>}</Typography>
          {!readOnly &&
            <EditIconButton size='small' onClick={()=>setEdit(true)}/>
          }
        </Stack>
      }
      {isEdit &&
        <InputEditField
          initValue={initValue ? String(initValue) : ''}
          onSubmit={submit}
          onClose={()=>setEdit(false)}
          inputType='number'
          placeholder={'Введите значение'}
          loading={isLoading}
        />
      }
    </Stack>
  )
}
