import {IconButton} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {IRemoveItemButton} from "./IRemoveItemButton";
export const RemoveItemButton = (props:IRemoveItemButton) => {
  const {Icon,isDisableColor, sx, ...rest} = props
  const IconToShow = Icon || Delete;
  return (
      <IconButton  {...rest}  sx={{
        '&:hover': {
          backgroundColor: '#FFDBDB',
          color: '#FF5252',
        },
        '&:active': {
          backgroundColor: '#FFC7C7',
          color:'#FF5252'
        },
        ...sx
      }} size='small'>
        <IconToShow {...(isDisableColor && { color: 'disabled' })} fontSize="small" />
      </IconButton>
  );
};