import {FC} from 'react'
import { ITextMultiplyDateSelect } from './ITextMultiplyDateSelect'
import { Box, Button, DialogActions, Menu, Stack, Typography } from '@mui/material'
import { LocalizationProvider, PickersActionBarProps, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ru'

export const TextMultiplyDateSelect:FC<ITextMultiplyDateSelect> = (props) => {
  const {
    title, 
    anchorEl, 
    onClose, 
    onOpen,
    valueFirst,
    changeValueFirst,
    valueSecon,
    changeValueSecond,
    disabled,
    onSubmit,
    sx,
    contentDateSx,
    headerDateSx,
    submitBtnSx
  } = props

  const open = Boolean(anchorEl)

  const MyActionBar = ({}: PickersActionBarProps) => {
    return (
      <DialogActions sx={{display:'none'}}/>
    )
  }

  return (
    <>
      <Typography
        color={'secondary'}
        onClick={onOpen}
        sx={{
          textDecoration:'underline',
          cursor:'pointer',
          '&:hover':{
            textDecorationStyle:'dashed',
          }
        }}
      >
        {title}
      </Typography>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <Stack direction={'row'} sx={{position:'relative', ...sx}}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
            <StaticDatePicker
              value={valueFirst}
              onChange={value=>changeValueFirst(value)}
              slots={{
                toolbar:undefined,
                actionBar:MyActionBar,
              }}
              sx={{
                "& .MuiPickersCalendarHeader-root": {...headerDateSx as any},
                "& .MuiPickersFadeTransitionGroup-root":{...contentDateSx as any},
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
            <StaticDatePicker
              value={valueSecon}
              onChange={value=>changeValueSecond(value)}
              slots={{
                toolbar:undefined,
                actionBar:MyActionBar,
              }}
              sx={{
                "& .MuiPickersCalendarHeader-root": {...headerDateSx as any},
                "& .MuiPickersFadeTransitionGroup-root":{...contentDateSx as any},
              }}
            />
          </LocalizationProvider>
          <Box sx={{position:'absolute', bottom:0, right:0, pl:2, pr:2, ...submitBtnSx}}>
            <Button
              variant='contained'
              disabled={disabled}
              onClick={onSubmit}
            >
              Готово
            </Button>
          </Box>
        </Stack>
      </Menu>
    </>
  )
}