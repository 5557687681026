import {FC} from 'react'
import { IPromokodItem } from '../models/IPromokodItem'
import { MenuItem, Stack, Typography } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import {RemoveItemButton} from "../../RemoveItemButton/RemoveItemButton";
import { CustomIconButton } from '../../Buttons/CustomIconButton/CustomIconButton';

export const PromokodItem:FC<IPromokodItem> = (props) => {
  const {
    title,
    onClick,
    onDeleted,
    isMainPromokod = false,
    mainTitle,
    readOnly = false,
    isActivated = false,
  } = props

  return (
    <MenuItem
      sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}
    >
      <Typography noWrap sx={{color: isActivated === false ? '#f44336' : 'inherit'}}>
        {title}
      </Typography>
      <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ml:1}}>
        <CustomIconButton onClick={onClick}>
          <ContentCopy fontSize='small'/>
        </CustomIconButton>
        {isMainPromokod === false && readOnly === false && isActivated === false && 
          <RemoveItemButton onClick={onDeleted ? ()=>onDeleted(mainTitle, title) : undefined}/>
        }
      </Stack>
    </MenuItem>
  )
}