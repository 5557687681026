import {FC} from 'react'
import { ILastCommentField } from './ILastCommentField'
import { Tooltip, Typography } from '@mui/material'

export const LastCommentField:FC<ILastCommentField> = (props) => {
  const {
    id,
    postedAt,
    text,
    type
  } = props

  return (
    <>
      {text?.length > 15
        ? <Tooltip title={<Typography>{text}</Typography>}>
            <Typography
              noWrap 
              sx={{
                overflow:'hidden', 
                textOverflow:'ellipsis',
                maxWidth:150, 
                width:'fit-content'
              }}
            >
              {text}
            </Typography>
          </Tooltip>
        : <Typography
            noWrap 
            sx={{
              overflow:'hidden', 
              textOverflow:'ellipsis',
              maxWidth:150, 
              width:'fit-content'
            }}
          >
            {text}
          </Typography>

      }
      
    </>
  )
}
