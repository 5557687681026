import { IUser } from "../../../entities/user/models/IUser"
import { IIntegrationMinMaxResponse } from "../../../services/integration/IIntegrationsResponse"
import { IFromToArray, IIntegrationUrlFilters } from "../../../services/integration/IntegrationsRequest"
import { ILayoutResponse } from "../../../services/layout/layoutResponse"
import { IGoodCategoriesItem, IReferencesTzResponse, IRequisteAccount } from "../../../services/references/IReferencesResponse"
import { ALL_ROLES } from "../../../shared/rules/interface/EnumRights"
import { IFilterMultiple } from "../../FilterMultiple/IFilterMultiple"
import { IFilterSelectItem } from "../../FilterSelectChip/IFilterSelectChip"
import { IStatusesPayments } from "../../Statuses/models/ISettingsFilters"
import { IGoodItem } from "../../Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem"

export const getGoodsFilterItems = (initGoods:IGoodItem[]):IFilterSelectItem[] => {
  const goods:IFilterSelectItem[] = initGoods.map(good=>{
    return {name:good.name, value:good.article, filterCode:good?.category?.name}
  })

  return goods
}

export const getSocialTypeFiltes = (socialStore:ILayoutResponse['channelTypes']):IFilterSelectItem[] => {
  const socialKeys:string[] = Object.keys(socialStore)

  const social:IFilterSelectItem[] = socialKeys.map(key=>{
    const item:IFilterSelectItem = {
      name:socialStore[key].name,
      value:key
    }
    return item
  })

  return social
}

export const getManagerFilterItems = (allUsers:IUser[]):IFilterSelectItem[] => {

  const filterArrays = [
    ALL_ROLES.trainee,
    ALL_ROLES.RELEASE_MANAGER,
    ALL_ROLES.SEARCH_MANAGER,
    ALL_ROLES.PRE_HEAD_MANAGER,
    ALL_ROLES.SEARCH_HEAD_MANAGER,
  ]

  const managers:any[] = allUsers.filter(user=>user.isActive === true).map(user=>{
    if(filterArrays.includes(user.role)){
      const manager:IFilterSelectItem = {
        name:`${user.firstName} ${user.lastName}`,
        value:`${user.id}`
      }
      return manager
    } else return undefined
  }).filter(item=>item !== undefined)

  return managers
}

export const getChannelSizeFilterItems = (sizes:IRequisteAccount[]):IFilterSelectItem[] => {
  return sizes.map(x=>{
    return {name:x?.name, value:x?.code}
  })
}

export const getAllTz = (allTz:IReferencesTzResponse[]):IFilterSelectItem[] => {

  const tzInSelect:IFilterSelectItem[] = allTz
    .filter(x=>x.allowUsage === true)
    .map(item=>{
      const newItem:IFilterSelectItem = {
        name:item.name,
        value:item.id.toString()
      }
      return newItem
    })
  
  return tzInSelect
}

export const getAllGoodsCategories = (allGoodsCategories:IGoodCategoriesItem[]):IFilterSelectItem[] => {

  const tzInSelect:IFilterSelectItem[] = allGoodsCategories.map(item=>{
    const newItem:IFilterSelectItem = {
      name:item.name,
      value:item.code
    }
    return newItem
  })
 
  return tzInSelect
}

export const getAllPaymentStatus = (statusPayments:IStatusesPayments):IFilterSelectItem[] => {
  const statusesKey:string[] = Object.keys(statusPayments)

  const statuses:IFilterSelectItem[] = statusesKey.map(key=>{
    const item:IFilterSelectItem = {
      name:statusPayments[key].name,
      value:key
    }
    return item
  })

  return statuses
}

export const getAllFilterMultiple = (dataMinMax:IIntegrationMinMaxResponse, isLoading:boolean):IFilterMultiple[] => {

  const allFilters:IFilterMultiple[] = [
    {
      initValue:dataMinMax?.roi,
      label:'ROI %',
      searchParamName:'roi',
      isLoading,
    },
    {
      initValue:dataMinMax?.cpv,
      label:'Стоимость CPV',
      searchParamName:'cpv',
      isLoading,
      step:0.1
    },
    {
      initValue:dataMinMax?.ctr,
      label:'Конверсия рекламы CTR %',
      searchParamName:'ctr',
      isLoading,
      step:0.1
    },
    {
      initValue:dataMinMax?.cr,
      label:'Коэффицент конверсии CR',
      searchParamName:'cr',
      isLoading,
      step:0.1
    },
    {
      initValue:dataMinMax?.crThrough,
      label:'Сквозной CR',
      searchParamName:'crThrough',
      isLoading,
      step:0.1
    },
    {
      initValue:dataMinMax?.validOrders,
      label:'Кол-во заказов',
      searchParamName:'orders',
      isLoading,
    },
    {
      initValue:dataMinMax?.canceledOrders,
      label:'Кол-во отмен',
      searchParamName:'canceledOrders',
      isLoading,
    },
    {
      initValue:dataMinMax?.views,
      label:'Кол-во просмотров',
      searchParamName:'views',
      isLoading,
    },
    {
      initValue:dataMinMax?.likes,
      label:'Кол-во лайков',
      searchParamName:'likes',
      isLoading,
    },
    {
      initValue:dataMinMax?.comments,
      label:'Кол-во комментариев',
      searchParamName:'comments',
      isLoading,
    },
    {
      initValue:dataMinMax?.traffic,
      label:'Трафик',
      searchParamName:'traffic',
      isLoading,
    },
    {
      initValue:dataMinMax?.prediction_orders,
      label:'ПРОГНОЗ Заказов',
      searchParamName:'prediction_orders',
      isLoading,
    },
    {
      initValue:dataMinMax?.prediction_views,
      label:'ПРОГНОЗ Просмотров',
      searchParamName:'prediction_views',
      isLoading,
    },
    {
      initValue:dataMinMax?.prediction_traffic,
      label:'ПРОГНОЗ Трафика',
      searchParamName:'prediction_traffic',
      isLoading,
    },
  ]

  return allFilters
}

export const getFilterIntegrationQuery = (searchQuery:URLSearchParams):IIntegrationUrlFilters => {

  const transormValueToArrayFromTo = (searchArr:string[], isDate?:boolean):IFromToArray<number | string> => {
    const array = !isDate ? searchArr.map(x=>+x) : searchArr

    const fromToArray:IFromToArray<number | string>  = {
      from:array[0],
      to:array[1]
    }
    return fromToArray
  }

  const option:IIntegrationUrlFilters | any = {
    id:searchQuery.get('id')?.split(',').map(x=>+x) || null,
    roi:searchQuery.get('roi') ? transormValueToArrayFromTo(searchQuery.get('roi')?.split(',')!) : null,
    cpv:searchQuery.get('cpv') ? transormValueToArrayFromTo(searchQuery.get('cpv')?.split(',')!) : null,
    cr:searchQuery.get('cr') ? transormValueToArrayFromTo(searchQuery.get('cr')?.split(',')!) : null,
    crThrough:searchQuery.get('crThrough') ? transormValueToArrayFromTo(searchQuery.get('crThrough')?.split(',')!) : null,
    ctr:searchQuery.get('ctr') ? transormValueToArrayFromTo(searchQuery.get('ctr')?.split(',')!) : null,
    promokod:searchQuery.get('promokod') || null,
    channelType:searchQuery.get('channelType')?.split(',') || null,
    channel:searchQuery.get('channel') ? JSON.parse(searchQuery.get('channel')!).map((x:IFilterSelectItem)=>x.value) : null,
    goodsCategory:searchQuery.get('goodsCategory')?.split(',') || null,
    tz:searchQuery.get('tz')?.split(',').map(x=>+x) || null,
    goods:searchQuery.get('goods')?.split(',') || null,
    likes:searchQuery.get('likes') ? transormValueToArrayFromTo(searchQuery.get('likes')?.split(',')!) : null,
    comments:searchQuery.get('comments') ? transormValueToArrayFromTo(searchQuery.get('comments')?.split(',')!) : null,
    views:searchQuery.get('views') ? transormValueToArrayFromTo(searchQuery.get('views')?.split(',')!) : null,
    validOrders:searchQuery.get('orders') ? transormValueToArrayFromTo(searchQuery.get('orders')?.split(',')!) : null,
    canceledOrders:searchQuery.get('canceledOrders') ? transormValueToArrayFromTo(searchQuery.get('canceledOrders')?.split(',')!) : null,
    traffic:searchQuery.get('traffic') ? transormValueToArrayFromTo(searchQuery.get('traffic')?.split(',')!) : null,
    prediction_orders:searchQuery.get('prediction_orders') ? transormValueToArrayFromTo(searchQuery.get('prediction_orders')?.split(',')!) : null,
    prediction_traffic:searchQuery.get('prediction_traffic') ? transormValueToArrayFromTo(searchQuery.get('prediction_traffic')?.split(',')!) : null,
    prediction_views:searchQuery.get('prediction_views') ? transormValueToArrayFromTo(searchQuery.get('prediction_views')?.split(',')!) : null,
    releaseUserId:searchQuery.get('releaseUserId')?.split(',') || null,
    foundById:searchQuery.get('foundById')?.split(',') || null,
    integration_date:searchQuery.get('integration_date') ? transormValueToArrayFromTo(searchQuery.get('integration_date')?.split(',')!, true) : null,
    paidAt:searchQuery.get('paidAt') ? transormValueToArrayFromTo(searchQuery.get('paidAt')?.split(',')!, true) : null,
    status:searchQuery.get('status')?.split(',') || null,
    channelTheme:searchQuery.get('channelTheme')?.split(',') || null,
    firstIntegrationWithChannel: searchQuery.get('firstIntegrationWithChannel') ? (searchQuery.get('firstIntegrationWithChannel') === 'true') : null,
    price:searchQuery.get('price') ? transormValueToArrayFromTo(searchQuery.get('price')?.split(',')!) : null,
    paidSum:searchQuery.get('paidSum') ? transormValueToArrayFromTo(searchQuery.get('paidSum')?.split(',')!) : null,
    plannedPaidAt:searchQuery.get('plannedPaidAt') ? transormValueToArrayFromTo(searchQuery.get('plannedPaidAt')?.split(',')!, true) : null,
    paymentStatus:searchQuery.get('paymentStatus')?.split(',') || null,
    size:searchQuery.get('size')?.split(',') || null,
    guaranteeNotCompleted:searchQuery.get('guaranteeNotCompleted') ? (searchQuery.get('guaranteeNotCompleted') === 'true') : null,
    hasGuarantee:searchQuery.get('hasGuarantee') ? (searchQuery.get('hasGuarantee') === 'true') : null,
    isVideoReview:searchQuery.get('isVideoReview') ? (searchQuery.get('isVideoReview') === 'true') : null,
  }

  const filterEmptyFieldOption:any = {}

  for (let key in option) {
    if(option[key] || option[key] === false){
      filterEmptyFieldOption[key] = option[key]
    }
  }

  return filterEmptyFieldOption
}