import { useSearchParams } from 'react-router-dom'
import { AgreementTable } from './ui/AgreementTable/AgreementTable'
import { CHANNEL_CARD_OPEN, INTEGRATION_CARD_OPEN } from '../../../../shared/constants/searchParams'
import { ChannelCardPage } from '../../../ChannelCardPage/ChannelCardPage'
import { TableAsidePage } from '../../../TableAsidePage/TableAsidePage'

export const AgreementPage = () => {

  const [searchParams, setSearchParams] = useSearchParams()

  const closeChannel = () => {
    searchParams.delete(CHANNEL_CARD_OPEN)
    setSearchParams(searchParams)
  }

  const closeIntegration= () => {
    searchParams.delete(INTEGRATION_CARD_OPEN)
    setSearchParams(searchParams)
  }

  return (
    <>
      <AgreementTable/>
      {searchParams.get(INTEGRATION_CARD_OPEN) &&
        <TableAsidePage integrationId={searchParams.get(INTEGRATION_CARD_OPEN)!} onClose={closeIntegration}/>
      }
      {searchParams.get(CHANNEL_CARD_OPEN) &&
        <ChannelCardPage channelId={searchParams.get(CHANNEL_CARD_OPEN)!} onClose={closeChannel}/>
      }
    </>
  )
}