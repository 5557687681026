import {FC, useState, useEffect, memo} from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, IconButton, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import {IFilterSelect} from "../../../FilterSelect/IFilterSelect";
import {CustomSelect} from "../../../CustomSelect/CustomSelect";

export const FinanceSelect:FC<IFilterSelect> = memo((props) => {
    const {
        label,
        searchParamName,
        content,
        placeholder
    } = props

    const [searchParams, setSearchParams] = useSearchParams()

    const queryItem:string | undefined = searchParams.get(searchParamName) ? content.find(x=>x.value === searchParams.get(searchParamName))?.value : ''

    const [value, setValue] = useState<string>(queryItem ? queryItem : '')

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string)
    }

    const resetFilter = () => {
        searchParams.delete(searchParamName)
        setSearchParams(searchParams)
        setValue('')
    }

    const changeUrlValue = () => {
        if (value.length === 0 ){
            searchParams.delete(searchParamName)
            setSearchParams(searchParams)
        } else {
            searchParams.set(searchParamName, `${value}`)
            setSearchParams(searchParams)
        }
    }

    useEffect(()=>{
        changeUrlValue()
    },[value])

    return (
        <Stack>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{minHeight:27}}>
                <Typography variant='body2'>{label}</Typography>
                {value !== '' &&
                    <Box>
                        <IconButton size='small' color='error' onClick={resetFilter}>
                            <Close fontSize='small'/>
                        </IconButton>
                    </Box>
                }
            </Stack>
            <CustomSelect value={value} onChange={handleChange} content={content} placeholder={placeholder}/>
        </Stack>
    )
})