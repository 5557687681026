import {FC, useEffect, useState} from 'react'
import { priceTransormFormat } from '../../../../../shared/functions/functions'
import { IPriceEdit } from './IPriceEdit'
import IntegrationsService from '../../../../../services/integration/integrations.service'
import { IUpdatePriceRequest } from '../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'
import { Check, Clear, } from '@mui/icons-material'
import { CircularProgress, Stack, TextField, Tooltip, Typography } from '@mui/material'
import integrationTableStore from '../../../../../store/integrationTable.store'
import { NotificationAlerts } from '../../../../NotificationAlerts/NotificationAlerts'
import integrationPageStore from "../../../../../store/integrationPage.store";
import { EditIconButton } from '../../../../Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../../../../Buttons/CustomIconButton/CustomIconButton'

export const PriceEdit:FC<IPriceEdit> = (props) => {
  const {
    price,
    field,
    update_id,
    isEditMode = false,
    id,
    minWidth,
    title,
    fullWidth = false,
    onChangePrice,
    onChangeCpv,
    onChangeUpdateId,
    showRemains = false,
    onChangeIntegrationsForPayment,
    onChangePriceAndStatus
  } = props

  const [value, setValue] = useState(price.toString())

  const [isEdit, setEdit] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const updatePrice = () => {
    setLoading(true)
    setError(null)

    const option:IUpdatePriceRequest = {
      id:+id,
      new_value:value,
      field,
      update_id
    }
    
    IntegrationsService.updatePrice(option)
      .then((res:any)=>{
        // console.log(res, 'res update price')
        integrationTableStore.changePrice(id, {cpv:res.data.cpv, price:res.data.price, update_id:res.data.update_id,})
        integrationPageStore.setRoi(res.data.roi)
        integrationTableStore.changeUpdateRoiTable(res.data.id,res.data.roi,res.data.update_id)
        integrationPageStore.setPredictionRoi(res.data.prediction_roi)

        if(onChangePrice){
          onChangePrice(res.data.price.toString())
        }

        if(onChangeCpv){
          onChangeCpv(res.data.cpv.toString())
        }

        if(onChangeUpdateId){
          onChangeUpdateId(res.data.update_id)
        }

        if(onChangeIntegrationsForPayment){
          onChangeIntegrationsForPayment(id, res.data.price)
        }

        if(onChangePriceAndStatus){
          onChangePriceAndStatus(id, res.data.status, res.data.update_id)
        }
        
        setEdit(false)
        setLoading(false)
        setValue(res.data.price.toString())
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err update res')
          setLoading(false)
          setError(err?.response?.data?.message)
          setEdit(true)
        }
      })
  }

  const closeEdit = () => {
    setValue(price.toString())
    setEdit(false)
  }

  const onKeyDownSend = (e:React.KeyboardEvent) => {
    if(e.code === "Enter" || e.code === "NumpadEnter"){
      updatePrice()
    }
  }

  useEffect(()=>{
    setValue(price?.toString())
  },[price])

  return (
    <>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          {title !== null && title !== undefined && <Typography>{title}:</Typography>}
          <Typography noWrap>{priceTransormFormat(price, false, showRemains)}</Typography>
          {isEditMode &&
            <Tooltip title='Изменить'>
              <EditIconButton size='small' onClick={()=>setEdit(true)}/>
            </Tooltip>
          }
        </Stack>
      }
      {isEdit && 
        <>
          {title && <Typography>{title}:</Typography>}
          <Stack direction={'row'} spacing={0.5}>
            <TextField
              sx={{minWidth:minWidth}}
              value={value}
              onChange={e=>setValue(e.target.value)} 
              placeholder='Введите новую цену'
              size='small'
              fullWidth={fullWidth}
              type='number'
              disabled={isLoading}
              InputProps={{
                endAdornment:isLoading && <CircularProgress sx={{maxHeight:20, maxWidth:20}} /> 
              }}
              onKeyDown={onKeyDownSend}
              autoFocus
            />
            <Stack direction={'row'} alignItems={'center'}>
              <Tooltip title='Сохранить'>
                <CustomIconButton
                  disabled={isLoading || value.trim()==='' || value.trim() === price.toString()}
                  size='small'
                  onClick={updatePrice}
                  showBackground
                >
                  <Check fontSize='small'/>
                </CustomIconButton>
              </Tooltip>
              <Tooltip title='Отмена' onClick={closeEdit}>
                <CustomIconButton disabled={isLoading} size='small'>
                  <Clear fontSize='small'/>
                </CustomIconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </>
      }
      <NotificationAlerts
        error={{text:isError ? isError : '', open:isError ? true : false, onClose:()=>setError(null)}}
      />
    </>
  )
}