import { ILayoutResponse } from "../../../../../../../services/layout/layoutResponse"
import { IMotivationSearchPricesResponse } from "../../../../../../../services/motivation/IMotivationResponse"
import { IStrategyField } from "../../../../../../../strategy/interface/IStrategyField"

export const getChannelTableCells = (tableChannels:IMotivationSearchPricesResponse, allChannels:ILayoutResponse['channelTypes']):IStrategyField[] => {
  const currentChannelKey:string[] = tableChannels.big.map(channel=>channel.channelTypeCode)

  const tableRowChannels:IStrategyField[] = currentChannelKey.map(key=>{

    const tableChannelField:IStrategyField = {
      code:key,
      immutable:false,
      isSimple:false,
      title:allChannels[key].name,
      isMotivationChannelPrice:true,
      minWidth:'100px',
    }
    return tableChannelField
  })

  const channelCategotyNameCell:IStrategyField = {
    code:'name',
    immutable:false,
    isSimple:true,
    title:'Категория',
    minWidth:'100px'
  }

  return [channelCategotyNameCell, ...tableRowChannels]
}