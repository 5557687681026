import { FC, useState, useEffect } from 'react'
import ReferencesService from '../../../../../../services/references/references.service'
import { AxiosError } from 'axios'
import { IPaymentCountagentSelect } from './IPaymentCountagentSelect'
import { Autocomplete, Box, CircularProgress, FormControl, TextField, Typography, createFilterOptions } from '@mui/material'
import { IContractorCreateResponse } from '../../../../../../services/contractor/IContractorIResponse'

export const PaymentCountagentSelect:FC<IPaymentCountagentSelect> = (props) => {
  const {
    text,
    value,
    onChangeValue,
    placeholder,
    disabled = false
  } = props
 
  const [searchValue, setSearchValue] = useState<string>('')

  const [counterAgents, setCounterAgents] = useState<null | IContractorCreateResponse[]>(null)

  const [isSearchLoading, setSearchLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const filterOptions = createFilterOptions({
    stringify: (option:IContractorCreateResponse) => option.shortName + option.inn,
  })

  const getCounterAgentsList = (value:string) => {
    setSearchLoading(true)
    setCounterAgents(null)
    setError(null)

    ReferencesService.hintsCounteragent({target:value})
      .then(res=>{
        // console.log(res, 'res')

        setCounterAgents(res.data)

        setSearchLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
          setSearchLoading(false)
        }
      })
  }

  useEffect(() => {
    if(searchValue.trim() !== ''){

      if(searchValue !== value?.shortName){
        const delay = setTimeout(()=> getCounterAgentsList(searchValue), 500)
        return ()=>clearTimeout(delay)
      }

    }
  },[searchValue])
  
  return (
    <FormControl size='small' fullWidth disabled={disabled}>
      {text &&
        <Typography
          sx={{mb:0.5}}
        >
          {text}
        </Typography>
      }
      <Autocomplete
        disabled={disabled}
        filterOptions={filterOptions}
        value={value}
        onChange={(event: any, newValue: IContractorCreateResponse | null) => {
          onChangeValue(newValue)
        }}
        inputValue={searchValue}
        onInputChange={(_, newInputValue) => {
          setSearchValue(newInputValue)
        }}
        options={counterAgents || []}
        getOptionLabel={(option) => option.shortName}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.shortName}
          </Box>
        )}
        noOptionsText='Ничего не найдено'
        loading={isSearchLoading}
        loadingText={'Загрузка...'}
        sx={{
          "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
            '&:hover': {
              color:'#7C64D9'
            },
            '&:active': {
              color:'#503D9D'
            },
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            size='small'
            error={Boolean(isError)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isSearchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        size='small'
      />
    </FormControl>
  )
}