import {FC} from 'react'
import { IDateCustomInput } from './IDateCustomInput'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import 'dayjs/locale/ru'

export const DateCustomInput:FC<IDateCustomInput> = (props) => {
  const {
    value,
    onChange,
    placeholder,
    disabled = false,
    sx,
    error,
    errorText
  } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
      <DatePicker
        value={value}
        onChange={onChange}
        slotProps={{textField:{size:'small',placeholder:placeholder,error:error,helperText:error?errorText:''}}}
        sx={sx}
        disabled={disabled}
      />
    </LocalizationProvider>
  )
}