import { IPaymentsFullPayResponse } from "../../../services/payments/IPaymentsResponse"
import { IFinancesPaymentItem } from "../../FinancesCreatePage/ui/FinancesPayments/ui/FinancesPaymentItem/IFinancesPaymentItem"

export const transformPaysData = (initPays:IPaymentsFullPayResponse[]):IFinancesPaymentItem[] => {
  const pays:IFinancesPaymentItem[] = initPays.map(pay=>{

    const newPay:IFinancesPaymentItem = {
      id:pay.id,
      source:pay.paymentType?.code === 'card' ? pay.source?.code : pay.source?.code,
      direction:pay.paymentType?.code === 'card' ? pay.direction?.code : pay.direction?.id,
      paymentType:pay.paymentType?.code || null,
      plannedPaidAt:pay.plannedPaidAt,
      totalSumm:pay.totalSumm,
      cardOrPhone:pay.phoneOrCard,
      fio:pay.fio,
      paymentLink:pay.paymentLink,
      selfEmployedCheck:pay.selfEmployedCheck,
      status:pay?.status || undefined,
      paidAt:pay?.paidAt || undefined
    }

    return newPay
  })
  return pays
}