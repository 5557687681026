import {FC, useEffect, useState} from 'react'
import { ICreateDateInput } from './ICreateDateInput'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Box, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import 'dayjs/locale/ru'
import { Dayjs } from 'dayjs'
import { sendDatePlusCustomTime } from '../../../../shared/functions/functions'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export const CreateDateInput:FC<ICreateDateInput> = observer((props) => {
  const {
    onSubmit, 
    title, 
    date, 
    required = false,
    error,
    errorText,
    maxWidth
  } = props

  const [value, setValue] = useState<Dayjs | null>(date ? dayjs(date)?.utc(false) : null)

  const submit = () => {
    onSubmit(sendDatePlusCustomTime({value:value?.toISOString() || ''}))
  }

  useEffect(()=>{
    if(value && value.isValid()){
      submit()
    } else {
      onSubmit(null as any)
    }
  },[value])

  return (
    <Stack direction={{xs:'column', sm:'row'}} alignItems={{sm:'center'}}>
      <Typography sx={{width:{sm:'170px'}}} >
        {title} {required && <Typography color={'error'} component={'span'}>*</Typography>}
      </Typography>
      <Box sx={{width:{xs:'100%', sm:'70%'}}} maxWidth={maxWidth}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
          <DatePicker
            value={value}
            onChange={(newValue) => setValue(newValue)}
            sx={{width:'100%'}}
            slotProps={{textField:{size:'small', required, placeholder:'дд.мм.год', error}}}
          />
        </LocalizationProvider>
        {error && <Typography variant='body2' color={'error'}>{errorText}</Typography>}
      </Box>
    </Stack>
  )
})