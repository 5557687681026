import {FC, useState, memo} from "react";
import {Box, CircularProgress, Stack, TableCell, TextField, Tooltip, Typography,} from "@mui/material";
import {Check, Clear} from "@mui/icons-material";
import {IFixCell} from "../../models/IMotivationTableSection";
import {AxiosError} from "axios";
import {NotificationAlerts} from "../../../NotificationAlerts/NotificationAlerts";
import {priceTransormFormat} from "../../../../shared/functions/functions";
import MotivationService from "../../../../services/motivation/motivation.service";
import DashboardStore from "../../../../store/dashboard.store";
import { IMotivatioEditForManagerORFine } from "../../../../services/motivation/IMotivationRequest";
import { EditIconButton } from "../../../Buttons/EditIconButton/EditIconButton";
import { CustomIconButton } from "../../../Buttons/CustomIconButton/CustomIconButton";

const FixCell: FC<IFixCell> = memo((props) => {
  const {
    userId,
    initValue,
    readOnly = false,
    name,
    type,
    onChangeCell
  } = props

    const [isEdit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [error, setError] = useState<string | null>(null)

  const [inputValue, setInputValue] = useState(initValue)

  const closeEdit = async () => {
    setInputValue(initValue)
    setEdit(false)
  }

  const updateSalary = () => {
    setLoading(true)
    setError(null)

    if (userId) {
      const option: IMotivatioEditForManagerORFine = {
        month:DashboardStore.dateValue?.month!,
        userId:+userId,
        year:DashboardStore.dateValue?.year!,
        sum:+inputValue,
        type:type,
      }
      MotivationService.editMotivationForManagerORFine(option)
        .then(res=>{
          
          if(onChangeCell){
            onChangeCell(res.data)
          }

          setLoading(false)
          setEdit(false)
        })
        .catch(err=>{
          if (err instanceof AxiosError) {
            // console.log(err, 'err add prom iuntegr card')
            setError(err?.response?.data?.message)
            setLoading(false)
          }
        })
    }
  }

  const onKeyDownSend = async (e: React.KeyboardEvent) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
        updateSalary()
    }
  }

  return (
    <>
      {!readOnly && isEdit ? (
        <TableCell sx={{p: 1}}>
          <Stack direction={"row"} spacing={0.5} sx={{width: 'fit-content', float: 'right'}}>
            <TextField
              value={inputValue || ''}
              onChange={e=>setInputValue(e.target.value)}
              placeholder="Введите новое значение"
              size="small"
              type="number"
              disabled={isLoading}
              InputProps={{
                endAdornment: isLoading && (
                  <CircularProgress sx={{maxHeight: 20, maxWidth: 20}}/>
                ),
              }}
              onKeyDown={onKeyDownSend}
              autoFocus
              sx={{minWidth: 150}}
            />
            <Stack direction={"row"} alignItems={"center"}>
              <Tooltip title="Сохранить">
                <CustomIconButton
                  disabled={isLoading || inputValue.trim() === ""}
                  size="small"
                  onClick={updateSalary}
                  showBackground
                >
                  <Check fontSize="small"/>
                </CustomIconButton>
              </Tooltip>
              <Tooltip title="Отмена" onClick={closeEdit}>
                <CustomIconButton disabled={isLoading} size="small">
                  <Clear fontSize="small"/>
                </CustomIconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </TableCell>
      ) : (
        <TableCell sx={{p: 1}}>
          <Box sx={{float: 'right'}}>
            <Typography noWrap component={"span"}>{priceTransormFormat(initValue, true)}</Typography>
            {!readOnly &&
              <Tooltip title="Изменить">
                <EditIconButton size="small" onClick={() => setEdit(true)}/>
              </Tooltip>
            }
          </Box>
        </TableCell>
      )}
      <NotificationAlerts
        error={{text: error ? error : "", open: error ? true : false, onClose: () => setError(null)}}
      />
    </>
  )
})

export default FixCell
