import { FC } from 'react'
import { IDrawer } from '../../entities/Drawer/models/IDrawer'
import { Box, Button, Divider, Drawer, IconButton, List, Stack, Typography } from '@mui/material'
import { Cancel, Info, LibraryBooks, PeopleAlt } from '@mui/icons-material'
import { IAsideSettingsItem } from './models/IAsideSettingsItem'
import { AsideSettingsItem } from './ui/AsideSettingsItem'

export const AsideSettings:FC<IDrawer> = (props) => {
  const {
    open, 
    onClose, 
    anchor = 'left'
} = props

  const menu:IAsideSettingsItem[] = [
    {
      icon:<PeopleAlt/>,
      title:'Пользователи',
      group:[{name:'Все пользователи', url:''}, {name:'Роли пользователей', url:''}]
    },
    {
      icon:<LibraryBooks/>, 
      title:'Справочники',
      group:[{name:'Типы реквизитов', url:''}, {name:'Площадки', url:''}, {name:'Товары', url:''}]
    },
    {
      icon:<Info/>,
      title:'Статусы',
      group:[{name:'Все статусы', url:''}, {name:'Переходы статусов', url:''}]
    },
  ]

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
    >
      <List sx={{minWidth:320, display:'flex', flexDirection:'column', height:'100%'}}>
        <Stack
          direction={'row'} 
          alignItems={'center'} 
          justifyContent={'space-between'} 
          sx={{
            pr:0, 
            pl:1, 
            pt:0, 
            pb:1
          }}
        >
          <Typography variant='h5'>Настройки системы</Typography>
          <IconButton onClick={onClose}>
            <Cancel/>
          </IconButton>
        </Stack>
        <Divider/>
        <Box sx={{p:2, overflow:'auto'}}>
          {menu.map(item=><AsideSettingsItem key={item.title} {...item}/>)}
        </Box>
        <Box sx={{mt:'auto'}}>
          <Divider/>
          <Box sx={{p:2,pb:1}}>
            <Button fullWidth variant='outlined' onClick={onClose}>Закрыть настройки</Button>
          </Box>
        </Box>
      </List>
    </Drawer>
  )
}
