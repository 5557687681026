import {FC, useState} from 'react'
import { IMotivationEditField } from './IMotivationEditField'
import { CircularProgress, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { Check, Close } from '@mui/icons-material'
import { EditIconButton } from '../../../../../../../../components/Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../../../../../../../../components/Buttons/CustomIconButton/CustomIconButton'

export const MotivationEditField:FC<IMotivationEditField> = (props) => {
  const {
    initValue, 
    placeholder = 'Введите проценты',
    onSubmit,
    isEdit,
    setEdit,
    isError = null,
    isLoading = false,
    readOnly = false,
    sxProps
  } = props

  const [value, setValue] = useState<string>(initValue ? initValue?.toString() : '')

  const closeEdit = () => {
    setEdit(false)
    setValue(initValue ? initValue?.toString() : '')
  }

  const handleKeyDown = (e:React.KeyboardEvent) => {
    if(e.key === 'Enter' || e?.keyCode === 32){
      setEdit(true)
    }
  }

  const handleSubmitDown = (e:React.KeyboardEvent) => {
    if(e.key === 'Enter' || e?.keyCode === 32){
      
      if(initValue?.toString()?.trim() === value?.trim() || value?.trim()==='' || value === null){

      } else {
        onSubmit(value)
      }
      
    }
  }

  const handleCloseKeyDown = (e:React.KeyboardEvent) => {
    if(e.key === 'Escape'){
      closeEdit()
    }
  }

  return (
    <>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={initValue !== null ? 0 : 0.5}>
          <Typography
            onClick={!readOnly ? ()=>setEdit(true) : undefined}
            sx={{
              cursor:!readOnly ? 'pointer' : 'auto',
              '&:hover':{opacity:'.7'},
              '&:focus':{opacity:'.7'},
              transition:'all ease .2s',
              display:'inline-block',
              whiteSpace:'nowrap'
            }}
            tabIndex={0}
            onKeyDown={handleKeyDown}
          >
            {initValue !== null ? `${initValue} %` : '0 %'}
          </Typography>
          {!readOnly &&
            <EditIconButton size='small' onClick={()=>setEdit(true)}/>
          }
        </Stack>
      }
      {isEdit &&
        <Stack
          direction={'row'} 
          alignItems={'center'} 
          tabIndex={0} 
          onKeyDown={handleCloseKeyDown}
        >
          <TextField
            value={value}
            onChange={(e)=>setValue(e.target.value)}
            fullWidth
            size='small'
            type='number'
            autoFocus
            placeholder={placeholder}
            disabled={isLoading}
            onKeyDown={handleSubmitDown}
            sx={sxProps}
            InputProps={{
              endAdornment: (
                <>
                  {isLoading ?
                    <InputAdornment position="end">
                      <CircularProgress sx={{maxWidth:20, maxHeight:20}} />
                    </InputAdornment>
                  : null}
                </>
              ),
            }}
          />
          <CustomIconButton
            size='small' 
            disabled={initValue?.toString()?.trim() === value?.trim() || value?.trim()==='' || value === null || isLoading}
            onClick={()=>onSubmit(value)}
            showBackground
            sx={{ml:0.5}}
          >
            <Check fontSize='small'/>
          </CustomIconButton>
          <CustomIconButton size='small' onClick={closeEdit} disabled={isLoading}>
            <Close fontSize='small'/>
          </CustomIconButton>
        </Stack>
      }
    </>
  )
}