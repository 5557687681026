import { FC } from 'react'
import { IAlertModal } from './models/IAlertModal'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material'
import { Cancel } from '@mui/icons-material'

export const AlertModal:FC<IAlertModal> = (props) => {
  const {
    open,
    onClose,
    title,
    subtext,
    buttonCancelName,
    buttonSubmitName,
    onSubmit,
    loading = false,
    colorSubmit,
    error,
    subContent,
    disabled
  } = props

  return (
    <Dialog
      open={open}
      onClose={!loading ? onClose : undefined}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle
        sx={{
          display:'flex', 
          alignItems:'center', 
          justifyContent:'space-between', 
          p:{xs:1, sm:2}, 
          pr:{xs:0.5, sm:0.5},
        }}
      >
        {title}
        <IconButton disabled={loading} onClick={onClose}>
          <Cancel/>
        </IconButton>
      </DialogTitle>
      <Divider/>
      {(subtext !== undefined || subContent !== undefined) &&
        <>
          <DialogContent sx={{pl:2}}>
            {subtext}
            {subContent}
          </DialogContent>
          <Divider/>
        </>
      }
      <DialogActions>
        <Button disabled={loading} variant='outlined' onClick={onClose}>{buttonCancelName}</Button>
        <Button
          disabled={loading || disabled}
          variant='contained'
          onClick={onSubmit}
          color={colorSubmit}
          endIcon={loading && <CircularProgress sx={{maxHeight:20, maxWidth:20}} />}
        >
          {buttonSubmitName}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
