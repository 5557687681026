import {Box, Stack} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {ICategoryTriggerAccordion} from "./ICategoryTriggerAccordion";
import { CustomIconButton } from "../../../../Buttons/CustomIconButton/CustomIconButton";

export const CategoryTriggerAccordion = ({nameCategory, quantity, isOpen}:ICategoryTriggerAccordion) => {
  return (
    <Box minWidth={'140px'} display="flex" alignItems="center" justifyContent={'space-between'}>
      <Stack direction={'row'}>
        <Box sx={{mr:0.5,whiteSpace:'nowrap'}}>{nameCategory}</Box>
        <Box>{`(${quantity})`}</Box>
      </Stack>
      <CustomIconButton size="small">
        {isOpen ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
      </CustomIconButton>
    </Box>
  );
};