import {FC, useEffect, useState} from 'react'
import { IIntegrationPaymentDate } from './IIntegrationPaymentDate'
import { DateCustomInput } from '../../../../DateCustomInput/DateCustomInput'
import PaymentsService from '../../../../../services/payments/payments.service'
import { IFinancePaymentEditRequest } from '../../../../../services/payments/IPaymentsRequest'
import dayjs, { Dayjs } from 'dayjs'
import { Stack, Typography } from '@mui/material'
import { Check, Close } from '@mui/icons-material'
import { AxiosError } from 'axios'
import integrationTableStore from '../../../../../store/integrationTable.store'
import { EditIconButton } from '../../../../Buttons/EditIconButton/EditIconButton'
import { CustomIconButton } from '../../../../Buttons/CustomIconButton/CustomIconButton'

export const IntegrationPaymentDate:FC<IIntegrationPaymentDate> = (props) => {
  const {
    date,
    field,
    oplataId,
    readOnly = false,
    integrationId,
    field_data_code,
    update_id
  } = props

  const [value, setValue] = useState<null | Dayjs>(date ? dayjs(date) : null)

  const [isEdit, setEdit] = useState(false)

  const [isLoading, setLoding] = useState(false)
  const [error, setError] = useState(null)

  const changeDate = (value:null | Dayjs) => {
    setValue(value)
  }

  const submt = () => {
    if(value && value?.isValid()){
      setLoding(true)
      setError(null)

      const option: IFinancePaymentEditRequest = {
        id:oplataId,
        update_id,
        [field_data_code]:value?.toISOString()
      }
      PaymentsService.financePaymentEdit(option)
        .then(res=>{
          integrationTableStore.updatePaymentsDate(integrationId, res.data[field_data_code], field)
          setLoding(false)
          setEdit(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            setLoding(false)
          }
        })
    }
  }

  useEffect(()=>{
    setValue(date ? dayjs(date) : null)
  }, [date])

  return (
    <>
      {!isEdit &&
        <Stack direction={'row'} alignItems={'center'}>
          <Typography noWrap>{date ? dayjs(date)?.format('DD.MM.YYYY') :'Выберите дату'}</Typography>
          {readOnly !== true &&
            <EditIconButton size='small' onClick={()=>setEdit(true)}/>
          }
        </Stack>
      }
      {isEdit &&
        <Stack direction={'row'} alignItems={'center'}>
          <DateCustomInput
            value={date ? dayjs(date) : null}
            onChange={changeDate}
            disabled={isLoading || readOnly}
            placeholder='Введите дату'
            sx={{minWidth:'130px'}}
          />
          <CustomIconButton size='small' onClick={submt} disabled={isLoading || (value?.isValid() === false)} showBackground sx={{ml:0.5}}>
            <Check fontSize='small'/>
          </CustomIconButton>
          <CustomIconButton size='small' onClick={()=>setEdit(false)} disabled={isLoading}>
            <Close fontSize='small'/>
          </CustomIconButton>
        </Stack>
      }
    </>
  )
}
