import {FC} from 'react'
import { IContractCheckbox } from './IContractCheckbox'
import { Checkbox, FormControlLabel } from '@mui/material'

export const ContractCheckbox:FC<IContractCheckbox> = (props) => {
  const {
    initValue,
    onChange,
    disabled,
    text,
    readOnly = false,
    labelPlacement = 'end'
  } = props

  return (
    <FormControlLabel
      slotProps={{typography:{sx:{cursor:readOnly ? 'auto' : 'pointer'}}, }}
      labelPlacement={labelPlacement}
      control={
        <Checkbox
          checked={initValue}
          onChange={!readOnly ? e=>onChange(e?.target?.checked) : undefined}
          inputProps={{style:{cursor:readOnly ? 'auto' : 'pointer'}}}
          disabled={disabled}
        />
      } 
      label={text}
      disabled={disabled}
      sx={{cursor:readOnly ? 'auto' : 'pointer', ml:0}}
    />
  )
}
