import React, {FC, useState, useEffect} from 'react'
import { IFilterSelectChip, IFilterSelectItem } from './IFilterSelectChip'
import { Autocomplete, FormControl, TextField, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

export const FilterSelectChip:FC<IFilterSelectChip> = React.memo((props) => {
  const {
    showOptionLabelValue,
    label, 
    content, 
    searchParamName, 
    placeholder, 
    limitTags
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const queryValue:string[] = searchParams.get(searchParamName)?.split(',') || []

  const queryItems:any[] = queryValue.map(val=>{
    return content.find(item=>item.value === val)
  }).filter(x=>x !== undefined)

  const [items, setItems] = useState<IFilterSelectItem[]>(searchParams.get(searchParamName) ? queryItems : [])
  const [inputValue, setInputValue] = useState<string>('')

  const changeUrlValue = () => {
    if (items.length === 0 ){
      searchParams.delete(searchParamName)
      setSearchParams(searchParams)
    } else {
      searchParams.set(searchParamName, `${items.map(x=>x.value)}`)
      setSearchParams(searchParams)
    }
  }

  useEffect(()=>{
    changeUrlValue()
  },[items])

  return (
    <FormControl size='small'>
      <Typography variant='body2' sx={{height:27, display:'inline-flex'}} alignItems={'center'} justifyContent={'start'}>{label}</Typography>
      <Autocomplete
        value={items}
        onChange={(event: any, newValue: IFilterSelectItem[]) => {
          setItems(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
        }}
        multiple
        limitTags={limitTags}
        options={content}
        getOptionLabel={(option) => showOptionLabelValue ? `${option.value} ${option.name}`:option.name}
        ChipProps={{variant:'outlined'}}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.value === value.value}
        noOptionsText='Ничего не найдено'
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            size='small'
          />
        )}
        size='small'
      />
    </FormControl>
  )
})