import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { FinancesIntegrations } from './ui/FinancesIntegrations/FinancesIntegrations'
import { FinancesPayments } from './ui/FinancesPayments/FinancesPayments'
import { FinancesContrAgent } from './ui/FinancesContrAgent/FinancesContrAgent'
import { FinancesComments } from './ui/FinancesComments/FinancesComments'
import { FinancesOplata } from './ui/FinancesOplata/FinancesOplata'
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { ROUTES } from '../../shared/routes/routes'
import userStore from '../../store/user.store'
import { observer } from 'mobx-react-lite'
import createPaymentStore from '../../store/createPayment.store'
import { AddOplataButton } from './ui/FinancesPayments/ui/FinancesPaymentItem/ui/AddOplataButton/AddOplataButton'
import { FINANCE_CARD_OPEN } from '../../shared/constants/searchParams'
import { IFinancesPaymentItem } from './ui/FinancesPayments/ui/FinancesPaymentItem/IFinancesPaymentItem'

export const FinancesCreatePage = observer(() => {

  const navigate = useNavigate()

  const [isOpen, setOpen] = useState(false)

  const closeModal = () => {
    setOpen(false)

    setTimeout(() => {
      createPaymentStore.clearCreateStore()
      navigate('/' + userStore.currentCompany+ '/' +ROUTES.FINANCES)
    }, 200)
  }

  const addOplataClose = (id:number) => {
    setOpen(false)
    createPaymentStore.clearCreateStore()
    navigate('/' + userStore.currentCompany+ '/' + ROUTES.FINANCES + '?'+FINANCE_CARD_OPEN+`=${id}`)
  }

  const addPayment = (type:string | null) => {
    const pay:IFinancesPaymentItem = {
      id:Number(new Date()),
      direction: type === 'card' ? null : (createPaymentStore.counteragent?.id?.toString() || null),
      paymentType: 'bank',
      plannedPaidAt:null,
      source:'ip-bokova',
      totalSumm:createPaymentStore.pays?.length <= 1 ? createPaymentStore.allSumm : null,
      cardOrPhone:null,
      fio:null,
      paymentLink:null,
      selfEmployedCheck:null
    }

    createPaymentStore.addPays(pay, false)
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Drawer
      anchor={"right"} 
      open={isOpen} 
      onClose={closeModal} 
      sx={{position: "relative"}}
      PaperProps={{sx:{backgroundColor: "#F2F3F4",}}}
      slotProps={{backdrop:{sx:{left:70}}}}
    >
      <Box 
        sx={{
          p:2,
          pt:1,
          width:{xl:"85vw", lg:'calc(100vw - 70px - 100px)', md:'calc(100vw - 70px - 50px)', sm:'calc(100vw - 70px)', xs:'100vw'},
        }}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <IconButton onClick={closeModal} sx={{ml:-1}}>
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
          <Typography
            variant="h5"
            noWrap
          >
            Добавить Оплату
          </Typography>
        </Stack>
        <Box
          sx={{
            mt:2,
            display:'grid',
            gridTemplateColumns:{xs:'100%', md:'70% calc(30% - 16px)', lg:'80% calc(20% - 16px)'},
            columnGap:2
          }}
        >
          <Box>
            <FinancesIntegrations/>
            <Box 
              sx={{
                mt:2,
                display:'grid', 
                gridTemplateColumns:{xs:'100%', lg:'1fr 1fr'}, 
                rowGap:2, 
                columnGap:2
              }}
            >
              <Stack spacing={2}>
                <FinancesOplata
                  isEditMode
                  releaseUserId={createPaymentStore.releaseUserId}
                  setReleaseUserId={createPaymentStore.setReleaseUserId}
                  plannedPaidAt={createPaymentStore.plannedPaidAt}
                  setPlannedPaidAt={createPaymentStore.setPlannedPaidAt}
                  changeInitReleaseUserId={createPaymentStore.setReleaseUserId}
                  disabled={createPaymentStore.integrationIds?.length>0 ? false : true}
                />
                <FinancesContrAgent
                  isEditMode
                  isShowEditIcon={false}
                  counteragent={createPaymentStore.counteragent}
                  integrationContacts={createPaymentStore.integrationContacts}
                  setCounteragent={createPaymentStore.setCounteragent}
                  sxContAgentWidth={{width:{xs:'100%', lg:'60%'}}}
                  disabled={createPaymentStore.integrationIds?.length>0 ? false : true}
                />
              </Stack>
              <Box sx={{height:'fit-content'}}>
                <FinancesPayments
                  isEditMode
                  isShowEditIcon={false}
                  addPayment={addPayment}
                  pays={createPaymentStore.pays}
                  counteragent={createPaymentStore.counteragent || undefined}
                  changeFieldPaysItem={createPaymentStore.changeFieldPaysItem}
                  changePaymentType={createPaymentStore.changePaymentType}
                  onDeletePayment={createPaymentStore.deletePays}
                  showDelete
                  disabledAddBtn={createPaymentStore.integrationIds?.length>0 ? false : true}
                />
                <Box sx={{display:{xs:'none', md:'block'}}}>
                  <AddOplataButton onClose={addOplataClose} disabled={createPaymentStore.integrationIds?.length>0 ? false : true}/>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{mt:{xs:2, md:0}}}>
            <FinancesComments/>
            <Box sx={{display:{xs:'block', md:'none'}}}>
              <AddOplataButton onClose={addOplataClose} disabled={createPaymentStore.integrationIds?.length>0 ? false : true}/>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
})