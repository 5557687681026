import {FC, useState} from 'react'
import { IIntegrationComments } from './IIntegrationComments'
import { IPostedCommentItem } from '../../../../../../components/PostedCommentItem/models/IPostedCommentItem'
import { observer } from 'mobx-react-lite'
import userStore from '../../../../../../store/user.store'
import { ICreateCommentsRequest } from '../../../../../../services/common/ICommonRequest'
import CommonService from '../../../../../../services/common/common.service'
import { AxiosError } from 'axios'
import { AsideCardComments } from '../../../../ui/AsideCardComments/AsideCardComments'
import integrationTableStore from '../../../../../../store/integrationTable.store'
import integrationPageStore from '../../../../../../store/integrationPage.store'

export const IntegrationComments:FC<IIntegrationComments> = observer((props) => {
  const {data, id, readOnly} = props

  const [comments, setComments] = useState<IPostedCommentItem[]>(data?.posted_comments || [])

  const addComment = (value: string) => {
    if (userStore.user && userStore.user.id) {

      const option: ICreateCommentsRequest = {
        toEntity: "integration",
        entity_id: +id!,
        text: value,
      }
      CommonService.createComments(option)
        .then((res) => {

          const item:IPostedCommentItem = {
            channelId: +id!,
            id: res.data.id,
            postedAt: res.data.postedAt,
            postedById: userStore.user?.id!,
            text: value,
            type: "user_comment",
          }

          integrationTableStore.addLastComment(id, res.data)

          // console.log(res, "res comment add data")
          setComments((prev) => [...prev, item])
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, "err add comment")
          }
        })
    }
  }

  return (
    <AsideCardComments
      comments={comments}
      addComment={addComment} 
      readOnly={readOnly} 
      sx={{maxHeight:{xs:'300px', lg:'720px'}}}
      entity='integration'
      onChangeComments={setComments}
      entityId={id}
      onAddLastComment={integrationTableStore.addLastComment}
      onCloseChat={()=>integrationPageStore.setChatOpen(false)}
      onOpenChat={()=>integrationPageStore.setChatOpen(true)}
      sxBtnResize={{display:{xs:'none', lg:'block'}}}
      showResizeBtn
    />
  )
})