import { FC, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { IGoodsSearchAutocomplete } from './IGoodsSearchAutocomplete'
import { IFilterSelectItem } from '../../../../../../../../components/FilterSelectChip/IFilterSelectChip'

export const GoodsSearchAutocomplete:FC<IGoodsSearchAutocomplete> = (props) => {
  const {
    onChangeGood,
    value,
    disabled,
    content,
    placeholder ='Выберите образец товара',
    id,
  } = props

  const [valueState, setValueState] = useState<IFilterSelectItem | null>(value ? {name:value?.name, value:value?.article, filterCode:value?.category?.name} : null)
  const [inputValue, setInputValue] = useState('')

  return (
    <Autocomplete
      value={valueState}
      onChange={(event: any, newValue: IFilterSelectItem | null) => {
        setValueState(newValue)
        onChangeGood(id, 'good', newValue?.value as any)
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      options={content?.sort((a, b) => -b.filterCode?.localeCompare(a?.filterCode!)!)}
      groupBy={(option) => option?.filterCode!}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      fullWidth
      size='small'
      disabled={disabled}
      noOptionsText='Ничего не найдено'
      renderInput={(params) =>
        <TextField
          {...params}
          placeholder={placeholder}
          size='small'
          disabled={disabled}
        />
      }
    />
  )
}
