import { FC, useState } from 'react'
import { IGoodItemComponent } from './IGoodItem'
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'

export const GoodItem:FC<IGoodItemComponent> = (props) => {
  const {
    name,
    isActive,
    article,
    checkbox = false,
    onChange,
    disabled = false,
    category
  } = props

  const [isChecked, setChecked] = useState(isActive)

  const chngeCheckBox = () => {
    setChecked(prev=>!prev)

    if(onChange){
      onChange(article, !isChecked)
    }
  }
  
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
      {!checkbox
        ? <Typography noWrap>{name}</Typography>
        : <FormControlLabel
            control={
              <Checkbox 
                disabled={disabled} 
                checked={isChecked} 
                onChange={chngeCheckBox}
              />
            } 
            label={name}
          />
      }
    </Stack>
  )
}
