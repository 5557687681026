import {FC, useState, Fragment} from 'react'
import {IAsideCardIntegrations} from './IAsideCardIntegrations'
import {Box, Collapse, Divider, IconButton, Stack, TableCell, TableRow, Tooltip} from '@mui/material'
import {IStrategyField} from '../../../../strategy/interface/IStrategyField'
import userStore from '../../../../store/user.store'
import {observer} from 'mobx-react-lite'
import {ALL_RULES} from '../../../../shared/rules/interface/EnumRights'
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {TableDefault} from "../../../../components/Table/TableDefault";
import {AsideCardLayout} from "../../../../components/AsideCardLayout/AsideCardLayout";
import {TableItem} from "../../../../components/Table/ui/TableItem";
import {IntegrationDetailsTable} from "./ui/IntegrationDetailsTable/IntegrationDetailsTable";
import {EmptyText} from "../../../../components/EmptyText/EmptyText";
import { CustomButton } from '../../../../components/Buttons/CustomButton/CustomButton'

export const AsideCardIntegrations:FC<IAsideCardIntegrations> = observer((props) => {
  const {integrationsWithStats, onClick, readOnly, isBanned} = props

  const data:IStrategyField[] = [
    {title:'Товарная категория', code:'goodsCategory',isSimple:false,isCategoryGoodArrayIcon:true, backgroundColor:'#C0C5CB'},
    {title:'Средний чек', code:'averageOrdersSumm',isSimple:true},
    {title:'Кол-во заказов', code:'validOrders', isSimple:true, immutable:false},
    {title:'Сумма заказов', code:'ordersSumm', isSimple:true, immutable:false,isMoneySimple:true},
    {title:'ROI', code:'averageRoi', isSimple:true, immutable:false, isPercent:true},
    {title:'Стоимость заказа', code:'averageOrderPrice', isSimple:true, immutable:false,isMoneySimple:true},
    {title:'Просмотры', code:'views', isSimple:false, isSimpleView:true, immutable:false},
    {title:'Трафик', code:'traffic', isSimple:true, immutable:false},
    {title:'CPV', code:'averageCPV', isSimple:true, immutable:false,isMoneySimple:true, isMoneyShowPredictionRemains:true},
    {title:'CTR', code:'averageCTR', immutable:false, isSimple:false, isCtrls:true, ctrlsIcon:'%',isShowRemains:true},
    {title:'CR', code:'averageCR', immutable:false, isSimple:false, isCtrls:true, ctrlsIcon:'%',isShowRemains:true},
    {title:'Скв.CR', code:'averageCRThrough', immutable:false, isSimple:false, isCtrls:true, ctrlsIcon:'%',isShowRemains:true},
  ]
  const [openRows, setOpenRows] = useState<{[key: number]: boolean}>({});
  const handleRowClick = (index:number) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [index]: !prevOpenRows[index],
    }));
  };

  return (
    <AsideCardLayout headerTitle='Интеграции канала по товарным категориям'>
      <Box sx={{mt:2}} maxWidth='100%'>
        {!integrationsWithStats?.length
          ? <EmptyText>Здесь будет история ваших интеграций</EmptyText>
          : <TableDefault maxWidth='100%' showBorderRadius={false} tableRow={data} >
              {integrationsWithStats?.map((row,index) => {
                const customProps = {
                  ...row.summaryStats,
                  goodsCategory: row.goodsCategory,
                  integrations:row.integrations,
                  openRows,
                  index,
                }
                return (
                  <Fragment key={index}>
                    <TableItem sx={{borderTop:'1px solid #e0e0e0',}} onKeyDown={() => handleRowClick(index)} {...customProps} cells={data}/>
                    <TableRow>
                      <TableCell sx={{paddingBottom: 0, paddingTop: 0,border:'none',backgroundColor:'#f2f3f5'}} colSpan={12}>
                        <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                          <IntegrationDetailsTable details={row.integrations}/>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableDefault>
        }
        <Divider sx={{mt:2}}/>
        {userStore.user && (userStore.user?.rights?.find(x => x.code === ALL_RULES.ADMIN) || userStore.user?.rights?.find(x => x.code === ALL_RULES.create_integration)) &&
          <Stack direction={'row'} alignItems={'center'}>
            <Box sx={{pt: 2, pr: 1}}>
              <CustomButton disabled={isBanned} variant='outlined' onClick={onClick}>Создать интеграцию</CustomButton>
            </Box>
            {isBanned && (
              <Box>
                <Tooltip
                  placement={'bottom-start'}
                  title="Вы не можете добавить интеграцию, поскольку канал находится в черном списке. Если Вы хотите добавить интеграцию, нужно сначала отменить отнесение канала в черный список">
                  <IconButton>
                    <InfoIcon/>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Stack>
        }
      </Box>
    </AsideCardLayout>
  )
})
