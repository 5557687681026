import { useState } from "react"

export const useResizeHook = (elementId:string, localStorageKey:string):any[] => {

  const maxResizeWidth:number = 70

  const [start, setStart] = useState(false)

  const [initialPos, setInitialPos] = useState<null | number>(null)
  const [initialSize, setInitialSize] = useState<null | number>(null)

  const onMouseDown = (e:React.MouseEvent) => {
    e.preventDefault()
    let resizable:HTMLElement | null = document.getElementById(elementId)

    setInitialPos(e?.clientX || null)
    setInitialSize(resizable?.offsetWidth || null)
    setStart(true)
  }
  
  const onMouseMove = (e:React.MouseEvent) => {
    e.preventDefault()

    if(start && initialPos !== null && initialSize !== null && e?.clientX > maxResizeWidth){
      let resizable:HTMLElement | null = document.getElementById(elementId)

      if(resizable){
        resizable.style.width = `${initialSize + parseInt(initialPos - e.clientX as any)}px`
      }
    }
  }

  const onMouseUp = (e:React.MouseEvent) => {
    e.preventDefault()

    let resizableWidth = document.getElementById(elementId)?.offsetWidth

    localStorage.setItem(localStorageKey, `${resizableWidth}`)
    setStart(false)
  }

  return [onMouseDown, onMouseMove, onMouseUp, start]
}