import {FC, useState} from 'react'
import { IStatusField } from './IStatusField'
import { StatusesList } from '../../../../Statuses/StatusesList'
import { IUpdateStatusesRequest } from '../../../../../services/integration/IntegrationsRequest'
import { AxiosError } from 'axios'

export const StatusField:FC<IStatusField> = (props) => {
  const {
    id, 
    type, 
    update_id, 
    statusStore, 
    onChangeStatus, 
    onUpdateStatus, 
    readOnly,
    integrationId,
    allAllowedMoves,
    onChangeType
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [isShowAlert, setShowAlert] = useState(false)
  
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)

  const changeStatus = (value:string) => {
    setLoading(true)
    setError(null)

    const option:IUpdateStatusesRequest = {
      id,
      field:'status',
      new_value:value,
      update_id
    }

    onUpdateStatus(option)
      .then(res=>{
        // console.log(res, 'res change sttuas')
        onChangeStatus(id, res.data.update_id, value)
        
        setLoading(false)
        handleClose()
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err change stuts')
          setLoading(false)
          setError(err?.response?.data?.message)
        }
      })
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeTypeAgreement = (type:string, update_id:number) => {
    if(onChangeType){
      onChangeType(id, update_id, type)
    }
    handleClose()
  }

  return (
    <StatusesList
      type={type}
      anchorEl={anchorEl}
      onOpenList={handleClick}
      onCloseList={handleClose}
      changeStatus={changeStatus}
      statusStore={statusStore}
      loading={isLoading}
      showAlertSuccess={isShowAlert}
      closeAlertSuccess={()=>setShowAlert(false)}
      isShowAlerError={isError}
      closeAlertError={()=>setError(null)}
      readOnly={readOnly}
      integrationId={integrationId}
      allAllowedMoves={allAllowedMoves}
      onChangeType={onChangeType ? changeTypeAgreement : undefined}
    />
  )
}
