import { useState, useEffect, FC } from 'react'
import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { CustomSelect } from '../../../../../../components/CustomSelect/CustomSelect'
import { IReferencesPaymentRequest } from '../../../../../../services/references/IReferencesRequest'
import ReferencesService from '../../../../../../services/references/references.service'
import { AxiosError } from 'axios'
import { IFilterSelectItem } from '../../../../../../components/FilterSelectChip/IFilterSelectChip'
import { IPaymentTypeSelect } from './IPaymentTypeSelect'

export const PaymentTypeSelect:FC<IPaymentTypeSelect> = (props) => {
  const {
    field,
    onChange,
    value,
    titleContent,
    placeholder,
    isCompanyBank = false,
    id,
    onChangeField,
    field_code,
    isEditMode,
    error,
    errorText
  } = props

  const [typeArr, setTypeArr] = useState<null | IFilterSelectItem[]>(null)

  const [isLoadingType, setLoadingType] = useState(false)

  const getSelectType = () => {
    setLoadingType(true)

    const option:IReferencesPaymentRequest = {
      field
    }
    ReferencesService.referencesPayment(option)
      .then(res=>{
        // console.log(res, 'res')
        
        if(field === 'bank' && isCompanyBank === true){

          setTypeArr(res.data?.filter(item=>item?.isCompanyBank === true)?.map(item=>{
            return {name:item.name, value:item.code}
          }))

        } else {

          setTypeArr(res.data?.map(item=>{
            return {name:item.name, value:item.code}
          }))

        }

        setLoadingType(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoadingType(false)
        }
      })
  }

  const changeSelect = (value:string) => {
    onChange(value)

    if(id && onChangeField && field_code){
      onChangeField(id, field_code, value)
    }

  }

  useEffect(() => {
    getSelectType()
  }, [])

  return (
    <Stack spacing={0.5}>
      {titleContent}
      {isEditMode &&
        <>
          {typeArr && !isLoadingType &&
            <CustomSelect
              errorText={errorText}
              error={error}
              value={value || ''}
              onChange={e=>changeSelect(e.target.value)}
              content={typeArr}
              placeholder={placeholder}
            />
          }
          {isLoadingType &&
            <Box>
              <Skeleton variant='rounded' sx={{minHeight:'45px', height:'45px', maxHeight:'45px'}}/>
            </Box>
          }
        </>
      }
      {!isEditMode &&
        <Typography>{typeArr?.find(x=>x?.value === value)?.name}</Typography>
      }
    </Stack>
  )
}