import {FC, useState} from 'react'
import { IRepeatModalField } from './IRepeatModalField'
import { Button } from '@mui/material'
import { ModalsRepeat } from './ui/ModalsRepeat/ModalsRepeat'

export const RepeatModalField:FC<IRepeatModalField> = (props) => {
  const {id} = props

  const [isShowRepeat, setShowRepeat] = useState(false)

  return (
    <>
      <Button variant='contained' color='warning' onClick={()=>setShowRepeat(true)}>
        Повторить
      </Button>
      {isShowRepeat && <ModalsRepeat id={id} open={isShowRepeat} onClose={()=>setShowRepeat(false)}/>}
    </>
  )
}
