import {FC} from 'react'
import { AsideCardLayout } from '../../../../../AsideCardLayout/AsideCardLayout'
import { Stack } from '@mui/material'
import { ContactInputEdit } from '../ContactInputEdit/ContactInputEdit'
import { observer } from 'mobx-react-lite'
import { IUser } from '../../../../../../entities/user/models/IUser'
import userStore from '../../../../../../store/user.store'

export const ContactInfo:FC<IUser> = observer((props) => {
  const {
    firstName,
    lastName,
    id,
    imageUrl,
    email,
    company,
    rights,
    role,
    roleName
  } = props

  return (
    <AsideCardLayout headerTitle='контактная информация'>
      <Stack spacing={1} sx={{pt:1}}>
        <ContactInputEdit
          title='Имя:'
          value={firstName}
          notFoundTitle='не заполнено'
          placeholder='Введите имя'
          onSubmit={userStore.setFirstName}
          readOnly
        />
        <ContactInputEdit
          title='Фамилия:'
          value={lastName}
          notFoundTitle='не заполнено'
          placeholder='Введите фамилию'
          onSubmit={userStore.setLastName}
          readOnly
        />
        <ContactInputEdit
          title='Контактный Email:'
          value={email}
          notFoundTitle='не заполнено'
          placeholder='Введите email'
          onSubmit={userStore.setEmail}
          readOnly
        />
      </Stack>
    </AsideCardLayout>
  )
})