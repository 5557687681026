import {FC} from 'react'
import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { BudgetEditField } from '../../../../../../../../components/Table/ui/Fields/BudgetEditField/BudgetEditField'
import { priceTransormFormat } from '../../../../../../../../shared/functions/functions'
import {IUserBudgetTableItem} from "./IUserBudgetTableItem";

export const UserBudgetTableItem:FC<IUserBudgetTableItem> = observer((props) => {
    const {
        isGeneral,
        month,
        userId,
        year,
        amountNew,
        amountOld,
        wastedNew,
        wastedOld,
        inScheduleOld,
        inScheduleNew,
        noEdit
    } = props

    return (
        <TableRow>
            <TableCell sx={{p:0, border:'none'}}>
                <Stack>
                    <TableCell sx={{p:2, minHeight:'55px', ml:'-1px'}}>
                        <Typography>Старые</Typography>
                    </TableCell>
                    <TableCell sx={{p:2, minHeight:'55px', ml:'-1px'}}>
                        <Typography>Новые</Typography>
                    </TableCell>
                </Stack>
            </TableCell>
            <TableCell sx={{p:0, border:'none'}}>
                <Stack>
                    <TableCell sx={{p:2, minHeight:'55px', ml:'-1px'}}>
                        <Typography>{priceTransormFormat(inScheduleOld)}</Typography>
                    </TableCell>
                    <TableCell sx={{p:2, minHeight:'55px', ml:'-1px'}}>
                        <Typography>{priceTransormFormat(inScheduleNew)}</Typography>
                    </TableCell>
                </Stack>
            </TableCell>
            <TableCell sx={{p:0, border:'none'}}>
                <Stack>
                    <TableCell sx={{p:1, minHeight:'55px', ml:'-1px'}}>
                        {noEdit ?
                            <Stack direction={'row'} alignItems={'center'} spacing={0.5} sx={{minHeight:38}}>
                                <Typography sx={{height: '38px', display: 'flex', alignItems: 'center'}}>
                                    {priceTransormFormat(amountOld)}
                                </Typography>
                            </Stack>
                            :
                            <BudgetEditField
                                initValue={amountOld}
                                month={month}
                                userId={userId}
                                year={year}
                                budgetType='old_channels'
                                field='amountOld'
                                fullWidth
                            />
                        }
                    </TableCell>
                    <TableCell sx={{p:1, minHeight:'55px', ml:'-1px'}}>
                        {noEdit ?
                            <Stack direction={'row'} alignItems={'center'} spacing={0.5} sx={{minHeight:38}}>
                                <Typography sx={{height: '38px', display: 'flex', alignItems: 'center'}}>
                                    {priceTransormFormat(amountNew)}
                                </Typography>
                            </Stack>
                            :
                            <BudgetEditField
                                initValue={amountNew}
                                month={month}
                                userId={userId}
                                year={year}
                                budgetType='new_channels'
                                field='amountNew'
                                fullWidth
                            />
                        }
                    </TableCell>
                </Stack>
            </TableCell>
            <TableCell sx={{p:0, border:'none'}}>
                <Stack>
                    <TableCell sx={{p:1, minHeight:'55px', ml:'-1px'}}>
                        <Typography sx={{height: '38px', display: 'flex', alignItems: 'center'}}>
                            {priceTransormFormat(wastedOld)}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{p:1, minHeight:'55px', ml:'-1px'}}>
                        <Typography sx={{height: '38px', display: 'flex', alignItems: 'center'}}>
                            {priceTransormFormat(wastedNew)}
                        </Typography>
                    </TableCell>
                </Stack>
            </TableCell>
        </TableRow>
    )
})