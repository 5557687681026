import { makeAutoObservable } from "mobx";
import { IIntegrationGoodsCategoryThresholds } from "../services/integration/IIntegrationsResponse";

class RulesStore {

  goodsCategories: null | IIntegrationGoodsCategoryThresholds[] = null

  constructor(){
    makeAutoObservable(this)
  }

  setGoodsCategories = (goodsCategories:null | IIntegrationGoodsCategoryThresholds[]) => {
    this.goodsCategories = goodsCategories
  }

  addGoodsCategories = (goodsCategorieItem:IIntegrationGoodsCategoryThresholds) => {
    if(this.goodsCategories){
      this.goodsCategories = [...this.goodsCategories, goodsCategorieItem]
    }
  }

  changeGoodsCategories = (code:string, value:number) => {
    if(this.goodsCategories){

      this.goodsCategories = this.goodsCategories.map(item=>{

        if(item.goodsCategoryCode === code){
          return {...item, value}
        } else return item

      })

    }
  }

}

export default new RulesStore()