import {FC} from 'react'
import { ICreateSelectField } from './ICreateSelectField'
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

export const CreateSelectField:FC<ICreateSelectField> = observer((props) => {
  const {
    title,
    label,
    content,
    onChange,
    value,
    required = false,
    error,
    errorText
  } = props

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value)
  }

  return (
    <Stack direction={{xs:'column', sm:'row'}} alignItems={{sm:'center'}}>
      <Typography sx={{mb:1, width:{sm:'170px'}}}>
        {title} {required && <Typography component={'span'} color={'error'}>*</Typography>}
      </Typography>
      <Box sx={{width:{xs:'100%', sm:'70%'}}}>
        <FormControl size='small' fullWidth>
          <InputLabel id="select-label" size='small'>{label}</InputLabel>
          <Select
            labelId="select-label"
            value={value.toString()}
            label={label}
            onChange={handleChange}
            size='small'
            required={required}
            error={error}
            fullWidth
          >
            {content.map(item=><MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
          </Select>
        </FormControl>
        {error && <Typography sx={{mt:0.5}} variant='body2' color={'error'}>{errorText}</Typography>}
      </Box>
    </Stack>
  )
})