import { FC, useState, useEffect } from 'react'
import { IContractInput } from './IContractInput'
import { TextField } from '@mui/material'

export const ContractInput:FC<IContractInput> = (props) => {
  const {
    value,
    inputType = 'text',
    placeholder,
    onChange,
    field,
    type,
    sx,
    disabled = false,
    id,
    textCase = 'inherit',
    fullWidth = false,
  } = props

  const [inputValue, setInputValue] = useState<string>(value ? value : '')

  useEffect(() => {
    const delay = setTimeout(()=> onChange(inputType === 'number' ? +inputValue : inputValue, field, type, id), 500)
    return ()=>clearTimeout(delay)
  },[inputValue])

  return (
    <TextField
      value={inputValue}
      onChange={(e)=>setInputValue(e.target.value)}
      placeholder={placeholder}
      size='small'
      type={inputType}
      sx={sx}
      disabled={disabled}
      inputProps={{sx:{textTransform:textCase}}}
      fullWidth={fullWidth}
    />
  )
}
